'use strict';
import { CALL_API } from '../../common/middleware/api'
import { showMessage } from '../../common/notifications/actions';
import * as n from '../../common/notifications/constants';
import { refresh } from '../Dashboard/actions';

export const POST_REQUESTED = 'yesfit/CA/POST_REQUESTED';
export const POST_RESOLVED = 'yesfit/CA/POST_RESOLVED';
export const POST_REJECTED = 'yesfit/CA/POST_REJECTED';
export const FETCH_REQUESTED = 'yesfit/CA/FETCH_REQUESTED';
export const FETCH_RESOLVED = 'yesfit/CA/FETCH_RESOLVED';
export const FETCH_REJECTED = 'yesfit/CA/FETCH_REJECTED';
export const HANDLE_FIELD_CHANGE = 'yesfit/CA/HANDLE_FIELD_CHANGE';
export const HANDLE_OBJECT_CHANGE = 'yesfit/CA/HANDLE_OBJECT_CHANGE';

const API = '/api/v1/challengeathlete';

const post = (endpoint, body) => ({
	[CALL_API]: {
		types: [POST_REQUESTED, POST_RESOLVED, POST_REJECTED],
		endpoint: endpoint,
		method: 'POST',
		body: body,
		onResolved: postChallengeAthleteResolved
	}
})

export const save = () => (dispatch, getState) => {
	var challengeAthlete = getState().challengeAthlete;
	challengeAthlete.item.startRace = false;
	challengeAthlete.item.pauseRace = false;
	return dispatch(post(API, challengeAthlete.item));
}

export const startRace = () => (dispatch, getState) => {
	var challengeAthlete = getState().challengeAthlete;
	challengeAthlete.item.startRace = true;
	return dispatch(post(API, challengeAthlete.item));
}

export const pauseRace = () => (dispatch, getState) => {
	var challengeAthlete = getState().challengeAthlete;
	challengeAthlete.item.pauseRace = true;
	return dispatch(post(API, challengeAthlete.item));
}

const fetch = endpoint => ({
	[CALL_API]: {
		types: [FETCH_REQUESTED, FETCH_RESOLVED, FETCH_REJECTED],
		endpoint: endpoint,
		method: 'GET'
	}
})

export const load = (challengeAthleteId) => dispatch => {
	return dispatch(fetch(API + '/' + challengeAthleteId));
}

export function handleFieldChange(key, value) {
	return {
		type: HANDLE_FIELD_CHANGE,
		key: key,
		value: value
	}
}

export function handleObjectChange(item) {
	return {
		type: HANDLE_OBJECT_CHANGE,
		item: item
	}
}

const postChallengeAthleteResolved = () => dispatch => {
	dispatch(refresh());
	return dispatch(showMessage('Info saved.', n.NOTIFICATIONS_INFO));
}