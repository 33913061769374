import {
    FETCH_REQUESTED,
    FETCH_RESOLVED,
    FETCH_REJECTED,
    DELETE_REQUESTED,
    DELETE_RESOLVED,
    DELETE_REJECTED
} from './actions';
import { POST_RESOLVED as PROFILE_POST_RESOLVED } from '../AthleteProfile/actions';
import assign from 'lodash.assign';

const initialState = {
    data: {
        basicInfo: {},
        workoutTotals: {},
        annualWorkoutTotals: {}
    },
    hasError: false,
    error: null,
    isLoading: false,
    isLoaded: false,
    isSaved: false,
    refresh: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RESOLVED:
            return assign({}, state, {
                data: action.response,
                hasError: false,
                isLoading: false,
                isLoaded: true,
                error: null,
                refresh: false
            });
        case DELETE_RESOLVED:
            return assign({}, state, {
                hasError: false,
                isLoading: false,
                error: null
            });
        case FETCH_REQUESTED:
        case DELETE_REQUESTED:
            return assign({}, state, {
                isLoading: true,
                refresh: false
            });
        case FETCH_REJECTED:
        case DELETE_REJECTED:
            return assign({}, state, {
                isLoading: false,
                hasError: true,
                error: action.error,
                refresh: false
            });
        case PROFILE_POST_RESOLVED:
            return assign({}, state, {
                refresh: true
            })
        default:
            return state;
    }
}