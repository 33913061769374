import { GET_REQUESTED, GET_RESOLVED, GET_REJECTED, UPDATE_STATS, UPLOAD_ERROR } from "./constants";
import { ATHLETE_APPS_FRESH_REFRESH } from '../AthleteApp/actions';
import assign from "lodash.assign";

const initialState = {
	data: {
		validicId: null,
		validicOrgId: null,
		validicAccessToken: null,
		healthApp: null,
		healthApps: [],
		appleHealth: false,
		googleFit: false,
		samsungHealth: false
	},
	stats: {
		syncTime: "Pending...",
		syncCount: 0
	},
	hasError: false,
	error: null,
	isLoading: false,
	isLoaded: false,
	refresh: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_REQUESTED:
			return assign({}, state, {
				isLoading: true,
				refresh: false,
				error: null
			});
		case GET_RESOLVED:
			return assign({}, state, {
				data: action.response,
				isLoading: false,
				isLoaded: true
			});
		case GET_REJECTED:
			return assign({}, state, {
				isLoading: false,
				hasError: true,
				error: action.message
			});
		case UPDATE_STATS:
			return assign({}, state, {
				hasError: false,
				error: null,
				stats: action.stats
			});
		case UPLOAD_ERROR:
			return assign({}, state, {
				hasError: true,
				error: action.error
			});
		case ATHLETE_APPS_FRESH_REFRESH:
			return assign({}, state, {
				refresh: true
			});
		default:
			return state;
	}
};
