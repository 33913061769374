import TagManager from 'react-gtm-module'

//Common Intercom functions that are called as a result of actions

export function registerIdentifiedUser(profile) {
	const tagManagerArgs = {
		dataLayer: {
			userId: profile.userId,
			email: profile.email,
			fbemail: profile.emailForPixel,
			name: `${profile.firstName} ${profile.lastName}`,
			city: profile.cityForPixel,
			state: profile.stateForPixel,
			zip: profile.zipForPixel,
			gender: profile.genderForPixel,
			dob: profile.dobForPixel
		}
	};
	TagManager.dataLayer(tagManagerArgs);
}

export function logout() {
}