'use strict';
import { CALL_API } from '../../common/middleware/api';
export const POST_REQUESTED = 'yesfit/SUB/POST_REQUESTED';
export const POST_RESOLVED = 'yesfit/SUB/POST_RESOLVED';
export const POST_REJECTED = 'yesfit/SUB/POST_REJECTED';
export const DELETE_REQUESTED = 'yesfit/SUB/DELETE_REQUESTED';
export const DELETE_RESOLVED = 'yesfit/SUB/DELETE_RESOLVED';
export const DELETE_REJECTED = 'yesfit/SUB/DELETE_REJECTED';
export const UPDATE_CUSTOMER_POST_REQUESTED = 'yesfit/SUB/UPDATE_CUSTOMER_POST_REQUESTED';
export const UPDATE_CUSTOMER_POST_RESOLVED = 'yesfit/SUB/UPDATE_CUSTOMER_POST_RESOLVED';
export const UPDATE_CUSTOMER_POST_REJECTED = 'yesfit/SUB/UPDATE_CUSTOMER_POST_REJECTED';
export const UPDATE_PLAN_POST_REQUESTED = 'yesfit/SUB/UPDATE_PLAN_POST_REQUESTED';
export const UPDATE_PLAN_POST_RESOLVED = 'yesfit/SUB/UPDATE_PLAN_POST_RESOLVED';
export const UPDATE_PLAN_POST_REJECTED = 'yesfit/SUB/UPDATE_PLAN_POST_REJECTED';
export const FETCH_ATHLETE_SUB_REQUESTED = 'yesfit/SUB/FETCH_ATHLETE_SUB_REQUESTED';
export const FETCH_ATHLETE_SUB_RESOLVED = 'yesfit/SUB/FETCH_ATHLETE_SUB_RESOLVED';
export const FETCH_ATHLETE_SUB_REJECTED = 'yesfit/SUB/FETCH_ATHLETE_SUB_REJECTED';
export const VALIDATE_COUPON_REQUESTED = 'yesfit/SUB/VALIDATE_COUPON_REQUESTED';
export const VALIDATE_COUPON_RESOLVED = 'yesfit/SUB/VALIDATE_COUPON_RESOLVED';
export const VALIDATE_COUPON_REJECTED = 'yesfit/SUB/VALIDATE_COUPON_REJECTED';
export const REMOVE_COUPON = 'yesfit/SUB/REMOVE_COUPON';
export const HANDLE_FIELD_CHANGE = 'yesfit/SUB/HANDLE_FIELD_CHANGE';
export const HANDLE_OBJECT_CHANGE = 'yesfit/SUB/HANDLE_OBJECT_CHANGE';
export const POST_REINSTATE_REQUESTED = 'yesfit/SUB/POST_REINSTATE_REQUESTED';
export const POST_REINSTATE_RESOLVED = 'yesfit/SUB/POST_REINSTATE_RESOLVED';
export const POST_REINSTATE_REJECTED = 'yesfit/SUB/POST_REINSTATE_REJECTED';
export const FETCH_UPCOMING_INVOICE_REQUESTED = 'yesfit/SUB/FETCH_UPCOMING_INVOICE_REQUESTED';
export const FETCH_UPCOMING_INVOICE_RESOLVED = 'yesfit/SUB/FETCH_UPCOMING_INVOICE_RESOLVED';
export const FETCH_UPCOMING_INVOICE_REJECTED = 'yesfit/SUB/FETCH_UPCOMING_INVOICE_REJECTED';
import { push } from '../../common/actions/navigate';
import { error, next } from '../Steppers/actions.js';
import { handleProfileObjectChange } from '../Login/actions';
import { showNotification } from '../../common/notifications/actions';

const SUB_API = '/api/v1/subscription';

export const createSubscription = (body) => ({
    [CALL_API]: {
        types: [POST_REQUESTED, POST_RESOLVED, POST_REJECTED],
        endpoint: SUB_API,
        method: 'POST',
        body: body,
        onResolved: createSubscriptionResolved,
        onRejected: postRejected
    }
})

export const updateCustomer = (body) => ({
    [CALL_API]: {
        types: [UPDATE_CUSTOMER_POST_REQUESTED, UPDATE_CUSTOMER_POST_RESOLVED, UPDATE_CUSTOMER_POST_REJECTED],
        endpoint: SUB_API + '/updateCustomer',
        method: 'POST',
        body: body,
        onRejected: postRejected,
        onResolved: updateCustomerResolved
    }
})

export const reinstateMembership = (body) => ({
    [CALL_API]: {
        types: [POST_REINSTATE_REQUESTED, POST_REINSTATE_RESOLVED, POST_REINSTATE_REJECTED],
        endpoint: SUB_API + '/reinstateSubscription',
        method: 'POST',
        body: body,
        onResolved: reinstateResolved
    }
})

export const deleteSubscription = (subscriptionId) => ({
    [CALL_API]: {
        types: [DELETE_REQUESTED, DELETE_RESOLVED, DELETE_REJECTED],
        endpoint: `${SUB_API}/${subscriptionId}`,
        method: 'DELETE',
        onRejected: postRejected,
        onResolved: deleteResolved
    }
})

const createSubscriptionResolved = (response) => dispatch => {
    return dispatch(next());
}

export const updatePlan = (planId, subscriptionId, couponId) => ({
    [CALL_API]: {
        types: [UPDATE_PLAN_POST_REQUESTED, UPDATE_PLAN_POST_RESOLVED, UPDATE_PLAN_POST_REJECTED],
        endpoint: `${SUB_API}/updatePlan`,
        method: 'POST',
        body: {
            planId: planId,
            subscriptionId: subscriptionId,
            couponId: couponId
        },
        onRejected: postRejected
    }
})

export const upcomingInvoice = (subscriptionId, customerId, planId) => ({
    [CALL_API]: {
        types: [FETCH_UPCOMING_INVOICE_REQUESTED, FETCH_UPCOMING_INVOICE_RESOLVED, FETCH_UPCOMING_INVOICE_REJECTED],
        endpoint: `${SUB_API}/getUpcoming/${subscriptionId}/${customerId}/${planId}`,
        method: 'GET'
    }
})

export const fetchSubscription = (id) => ({
    [CALL_API]: {
        types: [FETCH_ATHLETE_SUB_REQUESTED, FETCH_ATHLETE_SUB_RESOLVED, FETCH_ATHLETE_SUB_REJECTED],
        endpoint: `${SUB_API}/${id}`,
        method: 'GET',
        onResolved: fetchSubscriptionResolved
    }
})

export const validateCoupon = (coupon, planId) => ({
    [CALL_API]: {
        types: [VALIDATE_COUPON_REQUESTED, VALIDATE_COUPON_RESOLVED, VALIDATE_COUPON_REJECTED],
        endpoint: `${SUB_API}/verifyCoupon/${coupon}/${planId}`,
        method: 'GET',
        onRejected: postRejected
    }
})

const postRejected = (message) => dispatch => {
    return dispatch(error(message));
}

const deleteResolved = (response) => dispatch => {
    const message = response.isGroup ?
        'You have successfully cancelled your Yes.Fit Group Membership. After this pay period has ended, your group members will no longer receive related promotions or discounts.' :
        'You have successfully cancelled your VIP Membership. After this pay period has ended, you will no longer receive related promotions or discounts.';
    const route = response.isGroup ?
        '/groups' :
        '/vip';
    dispatch(showNotification(message));
    return dispatch(push(route));
}

const reinstateResolved = (response) => dispatch => {
    const message = response.isGroup ?
        'You have successfully reinstated your group membership.' :
        'You have successfully reinstated your membership.';
    dispatch(showNotification(message));
    const route = response.isGroup ?
        '/groups' :
        '/vip';
    return dispatch(push(route));
}

const fetchSubscriptionResolved = (response) => (dispatch, getState) => {
    const state = getState();
    const planName = response.subscription.plan.metadata ? response.subscription.plan.metadata.Name : null;
    if (!response.isGroups && (state.user.profile.stripeSubscriptionId !== response.subscription.id || state.user.profile.stripePlanName !== planName)) {
        return dispatch(handleProfileObjectChange({
            stripeSubscriptionId: response.subscription.id,
            stripePlanName: planName
        }));
    }
}

const updateCustomerResolved = (response) => (dispatch, getState) => {
    const state = getState();
    if (state.subscriptions.subscription.status !== 'active') {
        return dispatch(reinstateMembership(state.subscriptions.subscription));
    }
}

export function handleFieldChange(key, value) {
    return {
        type: HANDLE_FIELD_CHANGE,
        key: key,
        value: value
    }
}

export function removeCoupon() {
    return {
        type: REMOVE_COUPON
    }
}

export const handleObjectChange = (object) => {
    return {
        type: HANDLE_OBJECT_CHANGE,
        object: object
    };
}