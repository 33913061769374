import { createSelector } from 'reselect'
import moment from 'moment';
import numeral from 'numeral';
import { FACEBOOK_APP_ID } from '../Login/constants';

export const markersSelector = createSelector(
	state => state.challengeAthleteMap.data.markers,
	state => state.dashboard.teamView,
	(markers, teamView) => markers.filter((marker) => marker.isTeam === teamView)
)

export const currentMarkerSelector = createSelector(
	state => state.challengeAthleteMap.key,
	state => state.challengeAthleteMap.data.markers,
	(key, markers) => {
		const m = markers.find((marker) => marker.key === key);
		if (typeof m !== 'undefined') {
			if (m.isWorkout) {
				const coordinates = m.position !== null ? m.position.lat+','+ m.position.lng : null;
				const googleUrl = encodeURIComponent('http://www.google.com/maps/@'+coordinates+',3a,75y,5.17h,87.56t/data=!3m5!1e1!3m3!2e0!7i13312!8i6656');
				const shareText = encodeURIComponent(`I went ${numeral(m.totalDistance).format('0.[0]')} miles on #Yes.Fit on ${moment(m.activityStartLocal).format('MMMM Do, YYYY')}`)
				const activity = m.activityType !== null && m.activityType !== '' ? ' of ' + m.activityType : '';
				return {
					key: m.userCardioActivityID,
					userCardioActivityID: m.userCardioActivityID,
					activityStart: moment(m.activityStartLocal).format('MM-DD-YYYY'),
					title: m.isFromOtherChallenge === true ?
						`${numeral(m.totalDistance).format('0.[0]')} mile(s) from ${m.fromOtherChallenge !== null ? m.fromOtherChallenge : 'unassigned workout'} on ${moment(m.activityStartLocal).format('MM/DD/YYYY')}` :
						`${numeral(m.totalDistance).format('0.[0]')} mile(s) ${activity} on ${moment(m.activityStartLocal).format('MM/DD/YYYY')}`,
					duration: moment(m.durationAsTime).format('HH:mm'),
					totalDistance: numeral(m.totalDistance).format('0.[0]'),
					position: m.position,
					isWorkout: m.isWorkout,
					isTeam: m.isTeam,
					imageUrl: m.imageUrl, //Might be populated because GameMap
					imageContentType: m.imageContentType,
					webViewVRImageUrl: m.webViewVRImageUrl,
					isVR: m.isVR, //GameMap
					isStereo: m.isStereo,
					coordinates: m.coordinates, //GameMap
					linkUrl: null,
					footer: '',
					shareUrl: `https://www.facebook.com/dialog/share?app_id=${FACEBOOK_APP_ID}&display=popup&href=${googleUrl}&quote=${shareText}&hashtag=#YesFit`,
					fromOtherChallenge: m.fromOtherChallenge,
					thumbnailUrl: m.thumbnailUrl
				}
			}
			else {
				return {
					key: m.key,
					activityStart: moment(m.activityStartLocal).format('MM-DD-YYYY'),
					title: m.title,
					imageUrl: m.imageUrl,
					imageContentType: m.imageContentType,
					webViewVRImageUrl: m.webViewVRImageUrl,
					isVR: m.isVR, //GameMap
					isStereo: m.isStereo,
					coordinates: m.coordinates, //GameMap
					linkUrl: m.linkUrl,
					footer: m.footer,
					totalDistance: numeral(m.totalDistance).format('0.[0]'),
					position: m.position,
					isWorkout: m.isWorkout,
					isTeam: m.isTeam,
					shareUrl: null,
					fromOtherChallenge: null,
					thumbnailUrl: m.thumbnailUrl
				}
			}
		}
		else
			return null;
	}
)

export const pathSelector = createSelector(
	state => state.challengeAthleteMap.data.path,
	state => state.challengeAthleteMap.data.mePathIndex,
	state => state.challengeAthleteMap.data.teamPathIndex,
	state => state.dashboard.teamView,
	(path, mePathIndex, teamPathIndex, teamView) => {
		const index = teamView ? teamPathIndex : mePathIndex;
		return path.slice(0, index);
	}
);