import {
    FETCH_REQUESTED,
    FETCH_RESOLVED,
    FETCH_REJECTED,
    HANDLE_FIELD_CHANGE
} from './actions';
import assign from 'lodash.assign';

const initialState = {
    data: {
        groups: []
    },
    summary: {
        groups: []
    },
    hasError: false,
    error: null,
    isLoading: false,
    isLoaded: false,
    refresh: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RESOLVED:
            var new_state = assign({}, state, {
                hasError: false,
                isLoading: false,
                error: null,
                isLoaded: true
            });
            //We put the summary into a different array because when using the back button in Native, the
            //data doesn't reload from join race back to discover
            if (action.response.isSummary === true) {
                new_state.summary = action.response.challengeDiscovery;
            }
            else {
                new_state.data = action.response.challengeDiscovery;
            }
            return new_state;
        case FETCH_REQUESTED:
            return assign({}, state, {
                isLoading: true,
                refresh: false
            })
        case FETCH_REJECTED:
            return assign({}, state, {
                isLoading: false,
                isSaving: false,
                hasError: true,
                error: action.message,
                isLoaded: true
            })
        case HANDLE_FIELD_CHANGE:
            var new_state = assign({}, state);
            new_state[action.key] = action.value;
            return new_state;
        default:
            return state;
    }
}

