'use strict';
import { FETCH_CHALLENGE_REWARDS_API } from './constants';
import { CALL_API } from '../../common/middleware/api';

export const FETCH_CHALLENGE_REWARDS_REQUESTED = 'yesfit/ChallengeAthleteRewards/FETCH_CHALLENGE_REWARDS_RESOLVED_REQUESTED';
export const FETCH_CHALLENGE_REWARDS_RESOLVED = 'yesfit/ChallengeAthleteRewards/FETCH_CHALLENGE_REWARDS_RESOLVED';
export const FETCH_CHALLENGE_REWARDS_REJECTED = 'yesfit/ChallengeAthleteRewards/FETCH_CHALLENGE_REWARDS_REJECTED';

const fetchChallengeRewards = endpoint => ({
    [CALL_API]: {
        types: [FETCH_CHALLENGE_REWARDS_REQUESTED, FETCH_CHALLENGE_REWARDS_RESOLVED, FETCH_CHALLENGE_REWARDS_REJECTED],
        endpoint: endpoint,
        method: 'GET'
    }
})

export const loadChallengeRewards = () => (dispatch, getState) => {
    let endpoint = FETCH_CHALLENGE_REWARDS_API + '/' + getState().dashboard.challengeAthleteId;
    return dispatch(fetchChallengeRewards(endpoint));
}
