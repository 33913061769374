'use strict';
import { CALL_API } from '../../common/middleware/api';
import { MapUtils } from './components/MapUtils';
import { pathSelector } from './selectors';
export const FETCH_REQUESTED = 'yesfit/CAM/FETCH_REQUESTED';
export const FETCH_RESOLVED = 'yesfit/CAM/FETCH_RESOLVED';
export const FETCH_REJECTED = 'yesfit/CAM/FETCH_REJECTED';
export const HANDLE_FIELD_CHANGE = 'yesfit/CAM/HANDLE_FIELD_CHANGE';
export const HANDLE_MARKERS_CHANGE = 'yesfit/CAM/HANDLE_MARKERS_CHANGE';
export const HANDLE_ANIMATION_CHANGE = 'yesfit/CAM/HANDLE_ANIMATION_CHANGE';

export const ANIMATE_NEXT = 'ANIMATE_NEXT';
export const ANIMATE_PREVIOUS = 'ANIMATE_PREVIOUS';
export const ANIMATE_FROM_START = 'ANIMATE_FROM_START';
export const ANIMATE_ALL = 'ANIMATE_ALL';
export const ANIMATE_TO_END = 'ANIMATE_TO_END';

const CAM_API = '/api/v1/ChallengeAthleteMap';

const fetchMap = (endpoint) => ({
    [CALL_API]: {
        types: [FETCH_REQUESTED, FETCH_RESOLVED, FETCH_REJECTED],
        endpoint: endpoint,
        method: 'GET'
    }
})

export const loadMap = (challengeAthleteId, groupId) => (dispatch, getState) => {
    const state = getState();
    if (challengeAthleteId !== state.challengeAthleteMap.challengeAthleteId) {
        return dispatch(fetchMap(`${CAM_API}/${challengeAthleteId}/${groupId}`));
    }
}

export const handleFieldChange = (key, value) => dispatch => {
    return dispatch({
        type: HANDLE_FIELD_CHANGE,
        key: key,
        value: value
    });
}

export const handleMarkersChange = (markers) => dispatch => {
    return dispatch({
        type: HANDLE_MARKERS_CHANGE,
        markers: markers
    });
}

export const handleAnimationChange = (key, value) => dispatch => {
    return dispatch({
        type: HANDLE_ANIMATION_CHANGE,
        key: key,
        value: value
    });
}

export const stopAnimating = () => dispatch => {
    return dispatch(handleAnimationChange('animating', false));
}

export const animateToWorkout = (type) => (dispatch, getState) => {
    const challengeAthleteMap = getState().challengeAthleteMap;
    const { path, markers } = challengeAthleteMap.data;
	const { key } = challengeAthleteMap;
    var marker = markers.find((marker) => marker.key === key);
    const i = markers.indexOf(marker);
    var nextMarker = null;
    if (type === ANIMATE_NEXT) {
        nextMarker = markers[i + 1];
    }
    else if (type == ANIMATE_PREVIOUS) {
        marker = markers[i - 1];
        nextMarker = markers[i];
    }
    else if (type == ANIMATE_FROM_START) {
        marker = markers[0];
        nextMarker = markers[i];
    }
    else if (type == ANIMATE_ALL) {
        marker = markers[0];
        nextMarker = markers[markers.length-1];
    }
    else if (type == ANIMATE_TO_END) {
        marker = markers[i];
        nextMarker = markers[markers.length-1];
    }
    const firstIndex = findPathIndex(path, marker);
    const lastIndex = findPathIndex(path, nextMarker);
    var animatePath = path.slice(firstIndex, lastIndex + 1);
    dispatch(handleAnimationChange('animating', true));
    asyncLoop(animatePath, dispatch, handleAnimationChange, getState, firstIndex, path, function() {
        if (getState().challengeAthleteMap.animation.animating && nextMarker) {
            dispatch(handleFieldChange('key', nextMarker.key));
        }
        dispatch(handleAnimationChange('animating', false));
    });
}

export const processMarkers = (map) => (dispatch, getState) => {
    const mapUtils = new MapUtils();
    const state = getState();
    const path = pathSelector(state);
    const markers = mapUtils.processMarkers(map, path/*state.challengeAthleteMap.data.path*/, state.challengeAthleteMap.data.markers);
    return dispatch(handleMarkersChange(markers));
}

function findPathIndex(path, marker) {
    //Find the lat and lng in the path that's closest to the lat and lng of the marker.
    //We do this because the lat and lng of the marker probably isn't in the path, it's between two points
    var min = -1;
    var index = -1;
    for (var i = 0; i < path.length; i++) {
        const distance = distanceFrom(path[i], marker);
        if (min === -1 || (distance < min)) {
            min = distance;
            index = i;
        }
    }
    return index;
}

//Stolen from v3-epoly.js
function distanceFrom (position, marker) {
    var EarthRadiusMeters = 6378137.0; // meters
    var lat1 = position.lat;
    var lon1 = position.lng;
    var lat2 = marker ? marker.position.lat : lat1;
    var lon2 = marker ? marker.position.lng : lon1;
    var dLat = (lat2 - lat1) * Math.PI / 180;
    var dLon = (lon2 - lon1) * Math.PI / 180;
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = EarthRadiusMeters * c;
    return d;
}

function asyncLoop(arr, dispatch, handleAnimationChange, getState, firstIndex, path, callback) {
    const mapUtils = new MapUtils();
    dispatch(handleAnimationChange('totalSteps', arr.length));
    (function loop(i) {
        const bearing = mapUtils.bearing(path, firstIndex + i, firstIndex + i + 1);
        dispatch(handleAnimationChange('heading', bearing));
        dispatch(handleAnimationChange('position', arr[i]));
        dispatch(handleAnimationChange('currentStep', i+1));
        if (i < arr.length && getState().challengeAthleteMap.animation.animating) {
            setTimeout(function () { loop(++i) }, 2000);
        } else {
            callback();
        }
    }(0));
}
