'use strict';
import { FETCH_ACTIVITY_TYPES_API } from './constants';
import { CALL_API } from '../../common/middleware/api'

export const FETCH_ACTIVITY_TYPES_REQUESTED = 'yesfit/ActivityTypes/FETCH_ACTIVITY_TYPES_REQUESTED';
export const FETCH_ACTIVITY_TYPES_RESOLVED = 'yesfit/ActivityTypes/FETCH_ACTIVITY_TYPES_RESOLVED';
export const FETCH_ACTIVITY_TYPES_REJECTED = 'yesfit/ActivityTypes/FETCH_ACTIVITY_TYPES_REJECTED';

const fetchActivityTypes = endpoint => ({
    [CALL_API]: {
        types: [FETCH_ACTIVITY_TYPES_REQUESTED, FETCH_ACTIVITY_TYPES_RESOLVED, FETCH_ACTIVITY_TYPES_REJECTED],
        endpoint: endpoint,
        method: 'GET'
    }
})

export const loadActivityTypes = (id, type) => dispatch => {
    return dispatch(fetchActivityTypes(FETCH_ACTIVITY_TYPES_API + '/' + id + '/' + type));
}