'use strict';
import { CALL_API } from '../../common/middleware/api';
import { incompleteWithDevicesSelector } from '../ChallengeHistory/selectors';

export const HANDLE_FIELD_CHANGE = 'yesfit/RaceQueue/HANDLE_FIELD_CHANGE';
export const QUEUE_ORDER_REQUESTED = 'yesfit/RaceQueue/QUEUE_ORDER_REQUESTED';
export const QUEUE_ORDER_RESOLVED = 'yesfit/RaceQueue/QUEUE_ORDER_RESOLVED';
export const QUEUE_ORDER_REJECTED = 'yesfit/RaceQueue/QUEUE_ORDER_REJECTED';

const API = '/api/v1/challengeHistory';

const postQueueOrder = (body) => ({
	[CALL_API]: {
		types: [QUEUE_ORDER_REQUESTED, QUEUE_ORDER_RESOLVED, QUEUE_ORDER_REJECTED],
		endpoint: API,
		method: 'POST',
		body: body
	}
})

export const handleFieldChange = (key, value) => {
	return {
		type: HANDLE_FIELD_CHANGE,
		key: key,
		value: value
	}
}

export const saveQueueOrder = (hasIds) => (dispatch, getState) => {
	const state = getState();
	if (state.queueOrder.queueOrder.length > 0) {
		var ids = [];
		if (hasIds) {
			//From Web, queueOrder already contains a list of Ids in the right order
			ids = state.queueOrder.queueOrder;
		}
		else {
			//From Native, due to the library for DnD being used, queueOrder contains ints of a zero-based index of the initial position of each row
			//The endpoint for saving the queue order takes an array of challengeAthleteId, to be sorted in the given order
			const challenges = incompleteWithDevicesSelector(state);
			[...state.queueOrder.queueOrder].map((index) => {
				ids.push(challenges[index].extras.challengeAthleteId);
			});
		}
		return dispatch(postQueueOrder(ids));
	}
}
