'use strict';
import { CALL_API } from '../../../common/middleware/api'

export const FETCH_REQUESTED = 'yesfit/GAT/FETCH_REQUESTED';
export const FETCH_RESOLVED = 'yesfit/GAT/FETCH_RESOLVED';
export const FETCH_REJECTED = 'yesfit/GAT/FETCH_REJECTED';

const GAT_API = '/api/v1/groups/GroupAthleteTypes';

export const loadTypes = (groupId) => ({
    [CALL_API]: {
        types: [FETCH_REQUESTED, FETCH_RESOLVED, FETCH_REJECTED],
        endpoint: `${GAT_API}/${groupId}`,
        method: 'GET'
    }
})
