import {
    FETCH_ATHLETES_REQUESTED,
    FETCH_ATHLETES_RESOLVED,
    FETCH_ATHLETES_REJECTED,
    FETCH_ATHLETES_RESET
} from './actions';
import assign from 'lodash.assign';

const initialState = {
    athletes: [],
    filteredAthletes: [],
    filter: '',
    hasError: false,
    error: null,
    isLoading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ATHLETES_RESOLVED:
            return assign({}, state, {
                athletes: action.response,
                hasError: false,
                isLoading: false,
                error: null
            });
        case FETCH_ATHLETES_REQUESTED:
            return assign({}, state, {
                //isLoading: true
            })
        case FETCH_ATHLETES_REJECTED:
            return assign({}, state, {
                isLoading: false,
                hasError: true,
                error: action.error
            })
        case FETCH_ATHLETES_RESET:
            return assign({}, state, {
                ...initialState
            })
        default:
            return state;
    }
}