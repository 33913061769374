import {
    FETCH_REQUESTED,
    FETCH_RESOLVED,
    FETCH_REJECTED,
    POST_REQUESTED,
    POST_RESOLVED,
    POST_REJECTED,
    HANDLE_FIELD_CHANGE
} from './actions';

import assign from 'lodash.assign';

const initialState = {
    group: {
        groupId: null
    },
    data: {
        groupId: null,
        challengeId: null,
        startDateTime: null
    },
    hasError: false,
    error: null,
    isLoading: false,
    isSaved: false,
    refresh: false,
    isLoaded: false,
    isSaving: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RESOLVED:
            return assign({}, state, {
                group: action.response.group,
                hasError: false,
                isLoading: false,
                isLoaded: true,
                isSaving: false,
                error: null,
                refresh: false
            });
        case POST_RESOLVED:
            return assign({}, state, {
                hasError: false,
                isLoading: false,
                isLoaded: true,
                isSaving: false,
                error: null,
                refresh: false
            });
        case POST_REQUESTED:
            return assign({}, state, {
                hasError: false,
                error: null,
                isSaving: true,
                refresh: false
            });
        case POST_REJECTED:
            return assign({}, state, {
                isLoading: false,
                isLoaded: true,
                hasError: true,
                error: action.message,
                isSaving: false,
                refresh: false
            });
        case HANDLE_FIELD_CHANGE:
            var new_state = assign({}, state);
            new_state.data[action.key] = action.value;
            return new_state;
        default:
            return state;
    }
}