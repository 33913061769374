import {
    POST_REQUESTED,
    POST_RESOLVED,
    POST_REJECTED,
    HANDLE_FIELD_CHANGE,
    HANDLE_CART_FIELD_CHANGE
} from './actions';
import assign from 'lodash.assign';
import { RESET } from '../Steppers/actions';

const initialState = {
    data: {
        toEmail: null,
        fromEmail: null,
        toName: null,
        fromName: null,
        amount: null,
        userId: null,
        sendToRecipient: false,
        message: '',
        code: null,
        cart: {
            total: 0,
            token: null,
            paymentID: null,
            payerID: null,
            paymentMethod: null,
            charged: 0,
            transactionId: null
        }
    },
    filter: '',
    hasError: false,
    error: null,
    isLoading: false,
    isSaved: false,
    isSaving: false,
    isLoaded: false,
    giftAthlete: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case POST_RESOLVED:
            return assign({}, state, {
                ...state,
                data: {
                    ...state.data,
                    code: action.response.code,
                    cart: {
                        ...action.response.cart
                    }
                },
                //data: action.response.additionalInfo,
                hasError: false,
                isSaving: false,
                isSaved: true,
                error: null
            });
        case POST_REQUESTED:
            return assign({}, state, {
                ...state,
                isSaved: false,
                isSaving: true
            });
        case POST_REJECTED:
            return assign({}, state, {
                ...state,
                isLoading: false,
                isSaving: false,
                hasError: true,
                error: action.message
            });
        case HANDLE_FIELD_CHANGE:
            var new_state = assign({}, state);
            new_state.data[action.key] = action.value;
            return new_state;
        case HANDLE_CART_FIELD_CHANGE:
            var new_state = assign({}, state);
            new_state.data.cart[action.key] = action.value;
            return new_state;
        case RESET:
            //return assign({}, initialState);
            return assign({}, {
                ...initialState,
                data: {
                    ...initialState.data,
                    cart: {
                        total: 0,
                        token: null,
                        paymentID: null,
                        payerID: null
                    }
                }
            });
        default:
            return state;
    }
}