import { CALL_API } from '../../common/middleware/api'

export const FETCH_REQUESTED = 'yesfit/Referral/FETCH_REQUESTED';
export const FETCH_RESOLVED = 'yesfit/Referral/FETCH_RESOLVED';
export const FETCH_REJECTED = 'yesfit/Referral/FETCH_REJECTED';
export const COPIED_TO_CLIPBOARD = 'yesfit/Referral/COPIED_TO_CLIPBOARD';
export const SHOW_MODAL = 'yesfit/Referral/SHOW_MODAL';
import { showMessage } from '../../common/notifications/actions';
import * as n from '../../common/notifications/constants';

const API = '/api/v1/voucher';

const get = () => ({
    [CALL_API]: {
        types: [FETCH_REQUESTED, FETCH_RESOLVED, FETCH_REJECTED],
        endpoint: API,
		method: 'GET'
    }
})

export const getReferralCode = () => (dispatch, getState) => {
	if (typeof getState().user.profile.voucherifyReferralCode === 'undefined' || getState().user.profile.voucherifyReferralCode === null) {
		dispatch(get());
	}
}

export const copiedToClipboard = () => dispatch => {
    dispatch(showMessage('An invitation has been copied to your clipboard. Paste it into your favorite email program to invite your friends!', n.NOTIFICATIONS_INFO))
    return dispatch({
        type: COPIED_TO_CLIPBOARD
    });
}

export const onShowModal = (visible) => dispatch => {
    return dispatch({
        type: SHOW_MODAL,
        visible: visible
    });
}