import {
    FETCH_CHALLENGES_REQUESTED,
    FETCH_CHALLENGES_RESOLVED,
    FETCH_CHALLENGES_REJECTED,
    FETCH_CHALLENGES_FIELD_CHANGE,
    FETCH_CHALLENGES_DISTANCE_CHANGE,
    FETCH_CHALLENGES_MEMBERSHIP_CHANGE,
    FETCH_CHALLENGES_RESET_FILTERS,
    FETCH_CHALLENGES_NATIVE_DISTANCE_CHANGE,
    FETCH_CHALLENGES_NATIVE_SORT_CHANGE,
    FETCH_CHALLENGES_BOOLEAN_FILTER,
    HANDLE_FILTER_FIELD_CHANGE
} from './actions';
import { DASHBOARD_REFRESH } from '../Dashboard/actions';
import { CHECKOUT_RESOLVED } from '../JoinRace/actions';
import assign from 'lodash.assign';

const initialState = {
    challenges: [{
        stripePlans: []
    }],
    hasError: false,
    error: null,
    isLoading: false,
    isLoaded: false,
    filter: '',
    refresh: false,
    distanceFilters: [
        {
            min: 0,
            max: 19.99,
            selected: false,
            description: '19 miles and below'
        },
        {
            min: 20,
            max: 39.99,
            selected: false,
            description: '20 to 39 miles'
        },
        {
            min: 40,
            max: 59.99,
            selected: false,
            description: '40 to 59 miles'
        },
        {
            min: 60,
            max: 79.99,
            selected: false,
            description: '60 to 79 miles'
        },
        {
            min: 80,
            max: 99.99,
            selected: false,
            description: '80 to 99 miles'
        },
        {
            min: 100,
            max: 10000,
            selected: false,
            description: '100 and above'
        }
    ],
    nativeDistanceFilters: [
        {
            min: 0,
            max: 49.99,
            selected: false,
            description: 'Less than 50 miles'
        },
        {
            min: 50,
            max: 10000,
            selected: false,
            description: 'Greater than 50 miles'
        }
    ],
    //Handle random boolean columns in the Challenge reducer... such as IsSwag
    booleanFilters: [/*
        {
            name: 'is30Day',
            selected: false,
            description: 'Challenges'
        }
    */],
    //TODO: Get these from the API
    membershipFilters: [
        {
            key: 'CLUBYESFIT',
            description: 'Members Only'
        }
    ],
    //TODO: Get these from the API. Relocate them to a single parent to make the API call easier
    filters: {
        typeFilters: [
            {
                name: 'All',
                value: 0
            },
            {
                name: 'Races',
                value: 1
            },
            {
                name: 'Challenges',
                value: 2
            }
        ],
        typeFilterId: 0
    },
    selectedIndex: 1,
    nativeSortByOptions:
        [
            {
                type: 'totalAthletes',
                description: 'Most Popular',
                order: 'descending'
            },
            {
                type: 'sortWeight',
                description: 'Unsorted',
                order: 'descending'
            }]

};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CHALLENGES_RESOLVED:
            //TODO: Figure out total and subtotal when this is an update, not a create
            return assign({}, state, {
                challenges: action.response,
                hasError: false,
                isLoading: false,
                error: null,
                isLoaded: true
            });
        case FETCH_CHALLENGES_REQUESTED:
            return assign({}, state, {
                isLoading: true,
                refresh: false
            })
        case FETCH_CHALLENGES_REJECTED:
            return assign({}, state, {
                isLoading: false,
                isSaving: false,
                hasError: true,
                error: action.error,
                isLoaded: true
            })
        case FETCH_CHALLENGES_FIELD_CHANGE:
            var new_state = assign({}, state);
            new_state[action.key] = action.value;
            return new_state;
        case FETCH_CHALLENGES_DISTANCE_CHANGE:
            var new_state = assign({}, state);
            new_state.distanceFilters[action.key].selected = action.value;
            return new_state;
        case FETCH_CHALLENGES_MEMBERSHIP_CHANGE:
            var new_state = assign({}, state);
            new_state.membershipFilters[action.key].selected = action.value;
            return new_state;
        case FETCH_CHALLENGES_NATIVE_DISTANCE_CHANGE:
            var new_state = assign({}, state);
            new_state.nativeDistanceFilters[action.key].selected = action.value;
            return new_state;
        case FETCH_CHALLENGES_BOOLEAN_FILTER:
            var new_state = assign({}, state);
            new_state.booleanFilters[action.key].selected = action.value;
            return new_state;
        case HANDLE_FILTER_FIELD_CHANGE:
            var new_state = assign({}, state);
            new_state.filters[action.key] = action.value;
            return new_state;
        case FETCH_CHALLENGES_NATIVE_SORT_CHANGE:
            var new_state = assign({}, state);
            new_state.selectedIndex = action.value;
            return new_state;
        case FETCH_CHALLENGES_RESET_FILTERS:
            var new_state = assign({}, state);
            new_state.filter = '';
            new_state.distanceFilters.map((filter) => filter.selected = false);
            new_state.nativeDistanceFilters.map((filter) => filter.selected = false);
            new_state.membershipFilters.map((filter) => filter.selected = false);
            new_state.booleanFilters.map((filter) => filter.selected = false);
            new_state.selectedIndex = 1;
            new_state.filters.typeFilterId = 0;
            return new_state;
        case CHECKOUT_RESOLVED:
        case DASHBOARD_REFRESH:
            return assign({}, state, {
                ...state,
                refresh: true
            })
        default:
            return state;
    }
}

