import {
    FETCH_REQUESTED,
    FETCH_RESOLVED,
    FETCH_REJECTED,
    POST_REQUESTED,
    POST_RESOLVED,
    POST_REJECTED,
    HANDLE_FIELD_CHANGE
} from './actions';
import assign from 'lodash.assign';

const initialState = {
    data: { groupings: [{ tiles: [] }], nativeDescription: '', tileName: '', groupingName: '' },
    hasError: false,
    error: null,
    isLoading: false,
    refresh: false,
    selectedTileId: null,
    currentTab: 0
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RESOLVED:
        case POST_RESOLVED:
            return assign({}, state, {
                data: action.response.tileChallenge,
                //currentTab: action.response.tileChallenge.currentGroupingIndex - 1,
                currentTab: 0,
                hasError: false,
                isLoading: false,
                error: null
            });
        case FETCH_REQUESTED:
        case POST_REQUESTED:
            return assign({}, state, {
                isLoading: true,
                refresh: false
            })
        case FETCH_REJECTED:
        case POST_REJECTED:
            return assign({}, state, {
                isLoading: false,
                hasError: true,
                error: action.message
            })
        case HANDLE_FIELD_CHANGE:
            var new_state = assign({}, state);
            new_state[action.key] = action.value;
            return new_state;
        default:
            return state;
    }
}