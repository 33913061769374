import {
    FETCH_REQUESTED,
    FETCH_RESOLVED,
    FETCH_REJECTED,
    COPIED_TO_CLIPBOARD,
    SHOW_MODAL
} from './actions';
import { ON_SELECT_CHALLENGE } from '../Dashboard/actions';
import assign from 'lodash.assign';

const initialState = {
    hasError: false,
    error: null,
    isLoading: false,
    isLoaded: false,
    isCopied: false,//For web copy-to-clipboard
    showModal: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RESOLVED:
            return assign({}, state, {
                hasError: false,
                error: null,
                isLoading: false,
                isLoaded: true
            });
        case FETCH_REQUESTED:
            return assign({}, state, {
                isLoading: true
            })
        case FETCH_REJECTED:
            return assign({}, state, {
                hasError: true,
                error: action.error,
                isLoading: false,
                isLoaded: true
            })
        case COPIED_TO_CLIPBOARD:
            return assign({}, state, {
                ...state,
                isCopied: true,
                showModal: false
            })
        case ON_SELECT_CHALLENGE:
            return assign({}, state, {
                ...state,
                isCopied: false
            })
        case SHOW_MODAL:
            return assign({}, state, {
                showModal: action.visible
            })
        default:
            return state;
    }
}