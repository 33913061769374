import {
    FETCH_REQUESTED,
    FETCH_RESOLVED,
    FETCH_REJECTED,
    HANDLE_FIELD_CHANGE,
    HANDLE_MARKERS_CHANGE,
    HANDLE_ANIMATION_CHANGE
} from './actions';
import {
    POST_WORKOUT_RESOLVED,
    DELETE_WORKOUT_RESOLVED
} from '../Workouts/actions';
import assign from 'lodash.assign';

const initialState = {
    data: {
        geography: [],
        path: [],
        markers: [],
        friends: [],
        gameMap: null,
        mePathIndex: null,
        teamPathIndex: null
    },
    animation: {
        animating: false,
        position: {
            lat: 0,
            lng: 0
        },
        heading: 0,
        currentStep: 0,
        totalSteps: 0,
        canForward: false,
        canBack: false
    },
    markers: [],
    hasError: false,
    error: null,
    isLoading: false,
    isSaved: false,
    isMapLoaded: false,
    areMarkersLoaded: false,
    showInfoWindow: false,
    showFriendKeyWindow: false,
    refresh: false,
    isLoaded: false,
    key: null //The currently selected id
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RESOLVED:
            var new_state = assign({}, state, {
                data: action.response,
                hasError: false,
                isLoading: false,
                isLoaded: true,
                //areMarkersLoaded: action.response.gameMap !== null,
                //areMarkersLoaded: false,
                //isMapLoaded: action.response.gameMap !== null,
                markers: action.response.gameMap !== null ? action.response.markers : [],
                error: null,
                refresh: false
            });
            return new_state;
        case FETCH_REQUESTED:
            return assign({}, state, {
                isLoading: true,
                //isLoaded: false,
                refresh: false
            });
        case FETCH_REJECTED:
            return assign({}, state, {
                isLoading: false,
                //isLoaded: true,
                hasError: true,
                error: action.error,
                refresh: false
            });
        case HANDLE_FIELD_CHANGE:
            var new_state = assign({}, state);
            new_state[action.key] = action.value;
            return new_state;
        case HANDLE_MARKERS_CHANGE:
            var new_state = assign({}, state);
            return assign({}, state, {
                markers: action.markers,
                areMarkersLoaded: true,
                refresh: false
            });
        case HANDLE_ANIMATION_CHANGE:
            var new_state = assign({}, state);
            new_state.animation[action.key] = action.value;
            return new_state;
        case POST_WORKOUT_RESOLVED:
        case DELETE_WORKOUT_RESOLVED:
            return assign({}, state, {
                refresh: true
            });
        default:
            return state;
    }
}