import env from './constants/environment';

export function prependAssetHost(url, path) {
	if (url != null && url.startsWith('http'))
		return url;
	return env.ASSET_HOST + path + url;
}

export function prependAssetHostChallenge(url) {
	if (typeof url !== 'undefined' && url !== null && !url.startsWith('http') && url.length > 27)
		//Skip the initial '/s3/asset.makeyeshappen.com' on the path
		url = url.substring(27);
	return prependAssetHost(url, '');
}

export function prependAssetHostItem(url) {
	return prependAssetHost(url, '/challenges/');
}

export function prependExternalScriptHost(url) {
	if (url != null && url.startsWith('http'))
		return url;
	return `${env.EXTERNAL_SCRIPT_HOST}${url}`
}

export function validateEmail(email) {
	if (!email || email === '') {
		return false;
	}
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email.toLowerCase());
}

export function uuidv4() {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
		(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
	);
}

export function paginate(array, page_size, page_number) {
	// human-readable page numbers usually start with 1, so we reduce 1 in the first argument
	return array.slice((page_number - 1) * page_size, page_number * page_size);
}
