'use strict';
import { CALL_API } from '../../common/middleware/api'
import env from '../../common/constants/environment';

export const FETCH_REQUESTED = 'yesfit/Version/FETCH_REQUESTED';
export const FETCH_RESOLVED = 'yesfit/Version/FETCH_RESOLVED';
export const FETCH_REJECTED = 'yesfit/Version/FETCH_REJECTED';
const API = '/api/v1/version';

const fetchVersion = endpoint => ({
    [CALL_API]: {
        types: [FETCH_REQUESTED, FETCH_RESOLVED, FETCH_REJECTED],
        endpoint: endpoint,
        method: 'GET'
    }
})

export const checkVersion = () => dispatch => {
    return dispatch(fetchVersion(API + '/' + env.VERSION));
}
