import 'antd/dist/antd.less';
import '../../styles/Main.scss';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Router, Route, browserHistory, IndexRoute, Redirect } from 'react-router';
//import { syncHistoryWithStore } from 'react-router-redux';
import TagManager from 'react-gtm-module'
import AppSuspense from './components/AppSuspense';
import Sso from '../Login/Sso';
//import Page from './Page';

const LazyLoginContainer = React.lazy(() => import(/* webpackChunkName: "login" */'../Login'));
const LoginContainer = props => (
	<AppSuspense>
		<LazyLoginContainer {...props} />
	</AppSuspense>
);
const LazyJoinRace = React.lazy(() => import(/* webpackChunkName: "join-race" */'../JoinRace'));
const JoinRace = props => (
	<AppSuspense>
		<LazyJoinRace {...props} />
	</AppSuspense>
);
const LazyChooseRace = React.lazy(() => import(/* webpackChunkName: "choose-race" */'../ChooseRace'));
const ChooseRace = props => (
	<AppSuspense>
		<LazyChooseRace {...props} />
	</AppSuspense>
);
const LazyAthleteProfile = React.lazy(() => import(/* webpackChunkName: "athlete-profile" */'../AthleteProfile'));
const AthleteProfile = props => (
	<AppSuspense>
		<LazyAthleteProfile {...props} />
	</AppSuspense>
);
const LazyDashboard = React.lazy(() => import(/* webpackChunkName: "dashboard" */'../Dashboard'));
const Dashboard = props => (
	<AppSuspense>
		<LazyDashboard {...props} />
	</AppSuspense>
);
const LazyAthleteSocial = React.lazy(() => import(/* webpackChunkName: "athlete-social" */'../AthleteSocial'));
const AthleteSocial = props => (
	<AppSuspense>
		<LazyAthleteSocial {...props} />
	</AppSuspense>
);
const LazyHome = React.lazy(() => import(/* webpackChunkName: "home" */'../Home'));
const Home = props => (
	<AppSuspense>
		<LazyHome {...props} />
	</AppSuspense>
);
const LazyCommunity = React.lazy(() => import(/* webpackChunkName: "community" */'./Community'));
const Community = props => (
	<AppSuspense>
		<LazyCommunity {...props} />
	</AppSuspense>
);
const LazyMobileApp = React.lazy(() => import(/* webpackChunkName: "mobile-app" */'./MobileApp'));
const MobileApp = props => (
	<AppSuspense>
		<LazyMobileApp {...props} />
	</AppSuspense>
);
const LazyTerms = React.lazy(() => import(/* webpackChunkName: "terms" */'./Terms'));
const Terms = props => (
	<AppSuspense>
		<LazyTerms {...props} />
	</AppSuspense>
);
const LazyStripeRedirect = React.lazy(() => import(/* webpackChunkName: "stripe-redirect" */'./StripeRedirect'));
const StripeRedirect = props => (
	<AppSuspense>
		<LazyStripeRedirect {...props} />
	</AppSuspense>
);
const LazyChangePassword = React.lazy(() => import(/* webpackChunkName: "change-password" */'../ChangePassword'));
const ChangePassword = props => (
	<AppSuspense>
		<LazyChangePassword {...props} />
	</AppSuspense>
);
const LazyResetPassword = React.lazy(() => import(/* webpackChunkName: "reset-password" */'../ResetPassword'));
const ResetPassword = props => (
	<AppSuspense>
		<LazyResetPassword {...props} />
	</AppSuspense>
);
const LazyDeleteRequest = React.lazy(() => import(/* webpackChunkName: "delete-request" */'../DeleteRequest'));
const DeleteRequest = props => (
	<AppSuspense>
		<LazyDeleteRequest {...props} />
	</AppSuspense>
);
const LazyGift = React.lazy(() => import(/* webpackChunkName: "gift" */'../Gift'));
const Gift = props => (
	<AppSuspense>
		<LazyGift {...props} />
	</AppSuspense>
);
const LazyRedirectToWeb = React.lazy(() => import(/* webpackChunkName: "redirect-to-web" */'../RedirectToWeb'));
const RedirectToWeb = props => (
	<AppSuspense>
		<LazyRedirectToWeb {...props} />
	</AppSuspense>
);
const LazySubscription = React.lazy(() => import(/* webpackChunkName: "subscription" */'../Subscription'));
const Subscription = props => (
	<AppSuspense>
		<LazySubscription {...props} />
	</AppSuspense>
);
const LazyAthleteAchievements = React.lazy(() => import(/* webpackChunkName: "athlete-achievements" */'../AthleteAchievements'));
const AthleteAchievements = props => (
	<AppSuspense>
		<LazyAthleteAchievements {...props} />
	</AppSuspense>
);
const LazyTutorial = React.lazy(() => import(/* webpackChunkName: "tutorial" */'../Tutorial'));
const Tutorial = props => (
	<AppSuspense>
		<LazyTutorial {...props} />
	</AppSuspense>
);
const LazyOrderHistory = React.lazy(() => import(/* webpackChunkName: "order-history" */'../OrderHistory'));
const OrderHistory = props => (
	<AppSuspense>
		<LazyOrderHistory {...props} />
	</AppSuspense>
);
const LazyFitnessForYou = React.lazy(() => import(/* webpackChunkName: "corp-well" */'./FitnessForYou'));
const FitnessForYou = props => (
	<AppSuspense>
		<LazyFitnessForYou {...props} />
	</AppSuspense>
);
const LazyForAnyone = React.lazy(() => import(/* webpackChunkName: "corp-well" */'./ForAnyone'));
const ForAnyone = props => (
	<AppSuspense>
		<LazyForAnyone {...props} />
	</AppSuspense>
);

const LazyDiscover = React.lazy(() => import(/* webpackChunkName: "discover" */'../Discover'));
const Discover = props => (
	<AppSuspense>
		<LazyDiscover {...props} />
	</AppSuspense>
);
const LazyGroupAthletes = React.lazy(() => import(/* webpackChunkName: "group-athletes" */'../Groups/Athletes'));
const GroupAthletes = props => (
	<AppSuspense>
		<LazyGroupAthletes {...props} />
	</AppSuspense>
);
const LazyGroupList = React.lazy(() => import(/* webpackChunkName: "group-admin-list" */'../Groups/AdminList'));
const GroupList = props => (
	<AppSuspense>
		<LazyGroupList {...props} />
	</AppSuspense>
);
const LazyGroupAdminDetails = React.lazy(() => import(/* webpackChunkName: "group-admin-details" */'../Groups/AdminDetails'));
const GroupAdminDetails = props => (
	<AppSuspense>
		<LazyGroupAdminDetails {...props} />
	</AppSuspense>
);
const LazyGroupAdminEvents = React.lazy(() => import(/* webpackChunkName: "group-admin-events" */'../Groups/AdminEvents'));
const GroupAdminEvents = props => (
	<AppSuspense>
		<LazyGroupAdminEvents {...props} />
	</AppSuspense>
);
const LazyGroupProfile = React.lazy(() => import(/* webpackChunkName: "group-group-profile" */'../Groups/GroupProfile'));
const GroupProfile = props => (
	<AppSuspense>
		<LazyGroupProfile {...props} />
	</AppSuspense>
);
const LazyFitbit = React.lazy(() => import(/* webpackChunkName: "fitbit" */'../Devices/Fitbit'));
const Fitbit = props => (
	<AppSuspense>
		<LazyFitbit {...props} />
	</AppSuspense>
);
const LazyGarmin = React.lazy(() => import(/* webpackChunkName: "garmin" */'../Devices/Garmin'));
const Garmin = props => (
	<AppSuspense>
		<LazyGarmin {...props} />
	</AppSuspense>
);
const LazyStrava = React.lazy(() => import(/* webpackChunkName: "strava" */'../Devices/Strava'));
const Strava = props => (
	<AppSuspense>
		<LazyStrava {...props} />
	</AppSuspense>
);
const LazyUnderArmour = React.lazy(() => import(/* webpackChunkName: "underarmour" */'../Devices/UnderArmour'));
const UnderArmour = props => (
	<AppSuspense>
		<LazyUnderArmour {...props} />
	</AppSuspense>
);

class App extends Component {
	constructor(props) {
		document.title = 'Yes.Fit';
		super(props);
	}

	logPageView() {
		const tagManagerArgs = {
			dataLayer: {
				event: 'pageView',
				page: window.location.pathname
			}
		};
		TagManager.dataLayer(tagManagerArgs);
		window.scrollTo(0, 0);
	}

	render() {
		//const history = syncHistoryWithStore(browserHistory, this.context.store);
		return (
			<div>
				<Router history={this.props.history} onUpdate={this.logPageView}>
					<Route path="/" component={Home} name="Home" />
					<Route path="/dashboard(/:userName)(/:challengeUrl)" component={Dashboard} name="Dashboard" />
					<Route path="/social(/:userName)" component={AthleteSocial} name="Dashboard" />
					<Route path="/profile(/:userName)" component={AthleteProfile} name="Profile" />
					<Route path="/change-password" component={ChangePassword} name="Change Password" />
					<Route path="/delete-request" component={DeleteRequest} name="Delete Request" />
					<Route path="/community" component={Community} name="Community" />
					<Route path="/mobile-app" component={MobileApp} name="Mobile App" />
					<Route path="/reset-password" component={ResetPassword} name="Reset Password" />
					<Route path="/races" name="Races">
						<IndexRoute component={Discover} />
						{/* NOTE SLUG -> CHALLENGEURL */}
						<Route path=":slug(/:initialStep)" component={JoinRace} />
					</Route>
					<Route path="/all-races" component={ChooseRace} />
					{/* TODO NEED TO UNDERSTAND WHERE THE SLUG AND INITAL STEP PARAMS COME
						FROM
					*/}
					<Route path="/store" name="Store">
						<IndexRoute component={() => <ChooseRace showSwag={true} />} />
						{/* NOTE SLUG -> CHALLENGEURL */}
						<Route path=":slug(/:initialStep)" component={JoinRace} />
					</Route>
					<Route path="/groups" name="groups">
						<IndexRoute component={GroupList} />
						<Route path="details(/:groupId)" component={GroupAdminDetails} />
						<Route path="events(/:groupId)" component={GroupAdminEvents} />
						<Route path="members/:groupId" component={GroupAthletes} />
						<Route path="report/:groupId" component={GroupProfile} />
						<Route path="membership/:groupId" component={Subscription} />
					</Route>
					<Route path="/login" component={() => <LoginContainer isInfluencerPage={false} />} name="Login" />
					<Route path="/gift" component={Gift} name="Gift" />
					<Route path="/sso" component={Sso} name="Login" />
					<Route path="/terms" component={Terms} name="Terms" />
					<Route path="/stripe-redirect" component={StripeRedirect} name="StripeRedirect" />
					<Route path="/vip" component={Subscription} name="VIP" />
					<Route path="/achievements(/:userName)" component={AthleteAchievements} name="Achievements" />
					<Route path="/web(/:redirectRoute)" name="Redirect" component={RedirectToWeb} />
					<Route path="/tutorial" component={Tutorial} name="Tutorial" />
					<Route path="/my-orders" component={OrderHistory} name="Order History" />
					<Route path="/for-anyone" component={ForAnyone} name="For Anyone" />
					<Route path="/fitness-for-you" component={FitnessForYou} name="Fitness for You" /> 
					<Route path="/devices/fitbit(/:userName)" component={Fitbit} name="Fitbit" />
					<Route path="/devices/garmin(/:userName)" component={Garmin} name="Garmin" />
					<Route path="/devices/strava(/:userName)" component={Strava} name="Strava" />
					<Route path="/devices/ua(/:userName)" component={UnderArmour} name="UnderArmour" />
					<Redirect path="*" to="/" />
				</Router>
			</div>
		);
	}
}

App.contextTypes = {
	store: PropTypes.object.isRequired
};

export default App;
