import {
    FETCH_REQUESTED,
    FETCH_RESOLVED,
    FETCH_REJECTED,
    // DELETE_RESOLVED,
    HANDLE_FIELD_CHANGE
} from './actions';
import { POST_FRIEND_CONNECT_RESOLVED } from '../../AthleteSocial/actions';

import assign from 'lodash.assign';

const dt = new Date();
const day = dt.getDay();

const initialState = {
    data: {
        groupReport: {
            groupId: null,
            tag: '',
            //posts: [],
            members: [{
                userId: null,
                statsByWeek: {},
                statsByMonth: {},
                statsByYear: {},
                workouts: []
            }],
            summary: {
                userId: -1,
                statsByWeek: {},
                statsByMonth: {},
                statsByYear: {},
                workouts: []
            }
        }
    },
    isLoading: false,
    refresh: false,
    isLoaded: false,
    isSaving: false,
    toDate: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate() + (day == 0 ? 0 : 7) - day), //Get the future date for Sunday
    fromDate: new Date(2016, 1, 1),
    athleteReportUserId: null,
    athleteReportType: 0, //0 - week, 1 - month, 2 - year
    filter: null
};

//var day = d.getDay();
//return new Date(d.getFullYear(), d.getMonth(), d.getDate() + (day == 0?0:7)-day );
// TODO not sure if DELETE_RESOLVED IS EVEN USED ANYMORE-AR
export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RESOLVED:
        // case DELETE_RESOLVED:
            return assign({}, state, {
                data: action.response,
                hasError: false,
                isLoading: false,
                isLoaded: true,
                error: null,
                refresh: false
            });
        case FETCH_REQUESTED:
            return assign({}, state, {
                hasError: false,
                error: null,
                isLoading: true,
                isLoaded: false,
                refresh: false
            });
        case FETCH_REJECTED:
            return assign({}, state, {
                isLoading: false,
                isLoaded: true,
                hasError: true,
                error: action.message,
                refresh: false
            });
        case HANDLE_FIELD_CHANGE:
            var new_state = assign({}, state);
            new_state[action.key] = action.value;
            return new_state;
        case POST_FRIEND_CONNECT_RESOLVED:
            return assign({}, state, {
                refresh: true
            });
        default:
            return state;
    }
}
