import { CALL_API } from '../../../common/middleware/api'

export const FETCH_REQUESTED = 'yesfit/GF/FETCH_REQUESTED';
export const FETCH_RESOLVED = 'yesfit/GF/FETCH_RESOLVED';
export const FETCH_REJECTED = 'yesfit/GF/FETCH_REJECTED';

const GF_API = '/api/v1/groups/GroupFilter';

export const fetchGroups = () => ({
    [CALL_API]: {
        types: [FETCH_REQUESTED, FETCH_RESOLVED, FETCH_REJECTED],
        endpoint: GF_API,
        method: 'GET'
    }
})
