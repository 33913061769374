'use strict';
import { FETCH_ATHLETES_API } from './constants';
import { CALL_API } from '../../common/middleware/api'

export const FETCH_ATHLETES_REQUESTED = 'yesfit/Athletes/FETCH_ATHLETES_REQUESTED';
export const FETCH_ATHLETES_RESOLVED = 'yesfit/Atheletes/FETCH_ATHLETES_RESOLVED';
export const FETCH_ATHLETES_REJECTED = 'yesfit/Athletes/FETCH_ATHLETES_REJECTED';
export const FETCH_ATHLETES_RESET = 'yesfit/Athletes/FETCH_ATHLETES_RESET';

const fetchAthletes = endpoint => ({
    [CALL_API]: {
        types: [FETCH_ATHLETES_REQUESTED, FETCH_ATHLETES_RESOLVED, FETCH_ATHLETES_REJECTED],
        endpoint: endpoint,
        method: 'GET'
    }
})

export const loadAthletes = (filter, includeGroups) => (dispatch, getState) => {
    if (!getState().selectAthlete.isLoading) {
        return dispatch(fetchAthletes(FETCH_ATHLETES_API + '/' + filter + '/' + includeGroups));
    }
}

export const reset = () => {
    return {
        type: FETCH_ATHLETES_RESET
    }
}
