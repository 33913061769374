import {
    FETCH_STATES_REQUESTED,
    FETCH_STATES_RESOLVED,
    FETCH_STATES_REJECTED
} from './actions';
import assign from 'lodash.assign';

const initialState = {
    states: [{stateId:0, description:''}],
    hasError: false,
    error: null,
    isLoading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_STATES_RESOLVED:
            return assign({}, state, {
                states: action.response,
                hasError: false,
                isLoading: false,
                error: null
            });
        case FETCH_STATES_REQUESTED:
            return assign({}, state, {
                //isLoading: true
            })
        case FETCH_STATES_REJECTED:
            return assign({}, state, {
                isLoading: false,
                hasError: true,
                error: action.error
            })
        default:
            return state;
    }
}