import { replace, push } from "../../common/actions/navigate";
import * as storageService from "../../common/services/storage.service";
import { KEYS } from "../../common/constants/localStorage";
import { CALL_API } from "../../common/middleware/api";
import { loadChallenge } from "../JoinRace/actions";
import { get as getHealthApp } from '../NativeActivitySync/actions';
import { tagsRegistration } from "../../common/tags";
import { registerIdentifiedUser, logout as logoutOfIntercom } from "../../common/intercom";
import { fbLogout } from "./fbLogout";
import env from "../../common/constants/environment";

export const LOGIN_REQUESTED = "yesfit/Login/REQUESTED";
export const LOGIN_RESOLVED = "yesfit/Login/RESOLVED";
export const LOGIN_REJECTED = "yesfit/Login/REJECTED";
export const PROFILE_NOT_FOUND = "yesfit/Login/PROFILE_NOT_FOUND";
export const PUT_LOGIN_REQUESTED = "yesfit/Login/PUT_REQUESTED";
export const PUT_LOGIN_RESOLVED = "yesfit/Login/PUT_RESOLVED";
export const PUT_LOGIN_REJECTED = "yesfit/Login/PUT_REJECTED";
export const REFERRAL_CODE_REQUESTED = "yesfit/Login/REFERRAL_CODE_REQUESTED";
export const REFERRAL_CODE_RESOLVED = "yesfit/Login/REFERRAL_CODE_RESOLVED";
export const REFERRAL_CODE_REJECTED = "yesfit/Login/REFERRAL_CODE_REJECTED";
export const LOGIN_PROFILE_READY = "yesfit/Login/PROFILE_READY";
export const LOGIN_HANDLE_FIELD_CHANGE = "yesfit/Login/HANDLE_FIELD_CHANGE";
export const PROFILE_HANDLE_FIELD_CHANGE = "yesfit/Login/PROFILE_HANDLE_FIELD_CHANGE";
export const PROFILE_HANDLE_OBJECT_CHANGE = "yesfit/Login/PROFILE_HANDLE_OBJECT_CHANGE";
export const LOGOUT = "yesfit/Login/LOGOUT";

const LOGIN_API = "/api/v1/login";

const fetchLogin = (endpoint) => {
	return ({
		[CALL_API]: {
			types: [LOGIN_REQUESTED, LOGIN_RESOLVED, LOGIN_REJECTED],
			endpoint: endpoint,
			method: "GET",
			onResolved: loginResolved,
		},
	})
};

const postRegister = (endpoint, body) => ({
	[CALL_API]: {
		types: [LOGIN_REQUESTED, LOGIN_RESOLVED, LOGIN_REJECTED],
		endpoint: endpoint,
		method: "POST",
		body: body,
		onResolved: registerResolved,
	},
});

const putLogin = (body) => ({
	[CALL_API]: {
		types: [PUT_LOGIN_REQUESTED, PUT_LOGIN_RESOLVED, PUT_LOGIN_REJECTED],
		endpoint: LOGIN_API,
		method: "PUT",
		body: {
			...body,
		},
	},
});

export const login = (userData) => (dispatch) => {
	//Always go through the registration process with a Facebook or Apple user
	dispatch(setReturnUrl(userData.nextUrl));
	dispatch(handleLoginFieldChange("loadChallenge", loadChallenge));
	if (!userData.isNewUser && userData.facebookUserId == null && userData.appleId == null) {
		return dispatch(
			fetchLogin(
				LOGIN_API +
				"?username=" +
				encodeURIComponent(userData.email) +
				"&password=" +
				encodeURIComponent(userData.password)
			)
		);
	} else {
		if (userData.password != userData.confirmPassword)
			return dispatch({
				type: LOGIN_REJECTED,
				message: "Your password and confirm passwords do not match!",
			});
		else {
			const body = {
				username: userData.email,
				...userData
			};
			return dispatch(postRegister(LOGIN_API, body));
		}
	}
};

export const loginWithAccessToken = (accessToken, nextUrl) => (dispatch) => {
	dispatch(setReturnUrl(decodeURIComponent(nextUrl)));
	dispatch(
		fetchLogin(
			LOGIN_API +
			"?username=" +
			encodeURIComponent(accessToken) +
			"&password="
		)
	);
};

export function loginResolved(response) {
	return (dispatch, getState) => {
		dispatch(saveProfile(response));
		registerIdentifiedUser(response, dispatch, putLogin, getHealthApp);
		const user = getState().user;
		if (user.returnUrl !== null) {
			var returnUrl = user.returnUrl;
			dispatch(setReturnUrl(null));
			if (returnUrl === "@dashboard" && response.hasRaces) {
				dispatch(push("/dashboard"))
			} else if (returnUrl === "@dashboard" && !response.hasRaces) {
				dispatch(push("/races"));
			} else {
				dispatch(push(returnUrl));
			}
		}
		if (storageService.supports_html5_storage()) {
			// 	//Only reload the page if session storage is supported
			// 	//TODO: This isn't ideal, but after logging in or out, Intercom and Facebook Pixel have a fit. Refreshing the page fixes it
			if (
				window &&
				window.location &&
				typeof window.location.reload === "function"
			) {
				//window.location.reload();
			}
		} else if (
			user.loadChallenge != null &&
			typeof user.loadChallenge !== "undefined"
		) {
			dispatch(loadChallenge(user.loadChallenge, response.athleteId));
			dispatch(handleLoginFieldChange("loadChallenge", null));
		}
	};
}

export function registerResolved(response) {
	return (dispatch) => {
		if (response.registered) {
			tagsRegistration(response);
			//if (response.redirectUrl !== null) dispatch(push(response.redirectUrl))
		}
		dispatch(loginResolved(response));
	};
}

export function setReturnUrl(returnUrl) {
	return (dispatch) => {
		dispatch(handleLoginFieldChange("returnUrl", returnUrl));
	};
}

export function logout(nextRoute) {
	return (dispatch) => {
		let logout_profile = {};
		dispatch({ type: LOGOUT });
		dispatch(saveProfile(logout_profile));
		logoutOfIntercom();
		fbLogout();
		if (nextRoute) {
			dispatch(replace(nextRoute));
		} else {
			dispatch(replace(env.LOGOUT_NEXT_ROUTE));
		}
		//TODO: This isn't ideal, but after logging in or out, Intercom and Facebook Pixel have a fit. Refreshing the page fixes it
		if (
			window &&
			window.location &&
			typeof window.location.reload === "function"
		) {
			//window.location.reload();
		}
	};
}

export function saveProfile(profile) {
	return (dispatch) => {
		storageService.save(KEYS.USER_PROFILE, profile);
		dispatch({ type: KEYS.USER_PROFILE });
	};
}

export function loadProfile() {
	return (dispatch) => {
		dispatch({ type: LOGIN_REQUESTED })
		return storageService.load(KEYS.USER_PROFILE).then((response) => {
			if (response && response.isAuthenticated) {
				dispatch({ type: LOGIN_RESOLVED, response: response });
				registerIdentifiedUser(response, dispatch, putLogin, getHealthApp);
			}
			if (!response || (response && !response.isAuthenticated)) {
				dispatch({ type: PROFILE_NOT_FOUND });
			}
			dispatch({ type: LOGIN_PROFILE_READY });
		})
			.catch(e => {
				// console.log(e);
			})
	};
}

export function handleLoginFieldChange(key, value) {
	return {
		type: LOGIN_HANDLE_FIELD_CHANGE,
		key: key,
		value: value,
	};
}

export function handleProfileFieldChange(key, value) {
	return {
		type: PROFILE_HANDLE_FIELD_CHANGE,
		key: key,
		value: value,
	};
}

export const handleProfileObjectChange = (object) => {
	return {
		type: PROFILE_HANDLE_OBJECT_CHANGE,
		object: object,
	};
};
