'use strict';
import { push } from 'react-router-redux';
import { CALL_API } from '../../common/middleware/api'
import { showMessage } from '../../common/notifications/actions';
import * as n from '../../common/notifications/constants';

export const FETCH_REQUESTED = 'yesfit/CAP/FETCH_REQUESTED';
export const FETCH_RESOLVED = 'yesfit/CAP/FETCH_RESOLVED';
export const FETCH_REJECTED = 'yesfit/CAP/FETCH_REJECTED';
export const DELETE_REQUESTED = 'yesfit/CAP/DELETE_REQUESTED';
export const DELETE_RESOLVED = 'yesfit/CAP/DELETE_RESOLVED';
export const DELETE_REJECTED = 'yesfit/CAP/DELETE_REJECTED';

const CAP_API = '/api/v1/challengeAthleteProfile';

const fetchProfile = (endpoint) => ({
    [CALL_API]: {
        types: [FETCH_REQUESTED, FETCH_RESOLVED, FETCH_REJECTED],
        endpoint: endpoint,
        method: 'GET'
    }
})

export const deleteProfile = (userName) => ({
    [CALL_API]: {
        types: [DELETE_REQUESTED, DELETE_RESOLVED, DELETE_REJECTED],
        endpoint: CAP_API + '/' + userName,
        method: 'DELETE',
        onResolved: deleteProfileResolved
    }
})

export const loadProfile = (challengeAthleteId) => (dispatch) => {
    return dispatch(fetchProfile(CAP_API + '/' + challengeAthleteId));
}

const deleteProfileResolved = () => (dispatch) => {
    dispatch(showMessage('You have successfully deleted that user', n.NOTIFICATIONS_INFO));
    return dispatch(push('/races'));
}
