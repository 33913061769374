import React, { Component } from 'react';
import { connect } from 'react-redux';
import SsoComponent from './components/SsoComponent';
import { loginWithAccessToken } from '../Login/actions';
import queryString from 'query-string';

class Sso extends Component {
    componentDidMount() {
        const params = queryString.parse(this.props.location.search);
        this.props.dispatch(loginWithAccessToken(params.accessToken, params.returnUrl || '/'));
    }

    render() {
        return (
            <SsoComponent
                isLoggingIn={this.props.user.isLoggingIn}
                loginRejected={this.props.user.loginRejected}
                loginRejectedMessage={this.props.user.message}/>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    };
}

export default connect(mapStateToProps)(Sso);
