'use strict';
import { showMessage } from '../../common/notifications/actions';
import * as n from '../../common/notifications/constants';

export const NEXT = 'yesfit/Steppers/NEXT';
export const BACK = 'yesfit/Steppers/BACK';
export const ERROR = 'yesfit/Steppers/ERROR';
export const RESET = 'yesfit/Steppers/RESET';
export const CLEAR = 'yesfit/Steppers/CLEAR';
export const GOTO = 'yesfit/Steppers/GOTO';

export function next() {
    return {
        type: NEXT
    };
}

export function back() {
    return {
        type: BACK
    };
}

export const error = (message) => dispatch => {
    //window.scrollTo(0, 0);
    dispatch(showMessage(message, n.NOTIFICATIONS_ERROR));
    return dispatch({
        type: ERROR,
        message: message
    });
}

export function reset() {
    return {
        type: RESET
    };
}

export function clear() {
    return {
        type: CLEAR
    };
}

export function goto(stepIndex) {
    return {
		type: GOTO,
		stepIndex: stepIndex
    };
}
