'use strict';
import { CALL_API } from '../../common/middleware/api';
export const HANDLE_FIELD_CHANGE = 'yesfit/Dashboard/HANDLE_FIELD_CHANGE';
export const FETCH_REQUESTED = 'yesfit/Dashboard/FETCH_REQUESTED';
export const FETCH_RESOLVED = 'yesfit/Dashboard/FETCH_RESOLVED';
export const FETCH_REJECTED = 'yesfit/Dashboard/FETCH_REJECTED';
export const TOGGLE_TEAM_VIEW = 'yesfit/Dashboard/TOGGLE_TEAM_VIEW';
export const ON_SELECT_CHALLENGE = 'yesfit/Dashboard/ON_SELECT_CHALLENGE';
export const DASHBOARD_REFRESH = 'yesfit/Dashboard/DASHBOARD_REFRESH';

const FETCH_API = '/api/v1/dashboard'

export function handleFieldChange(key, value) {
    return {
        type: HANDLE_FIELD_CHANGE,
        key: key,
        value: value
    }
}

const fetchDashboard = endpoint => ({
    [CALL_API]: {
        types: [FETCH_REQUESTED, FETCH_RESOLVED, FETCH_REJECTED],
        endpoint: endpoint,
        method: 'GET'
    }
})

export const loadDashboard = (userName) => (dispatch, getState) => {
    if (userName === null || typeof userName === 'undefined') {
        dispatch(handleFieldChange('allowAccess', true));
    }
    if (!getState().dashboard.isLoaded) {
        dispatch(fetchDashboard(FETCH_API + '/' + userName));
    }
}

export function toggleTeamView(checked) {
    return {
        type: TOGGLE_TEAM_VIEW,
        value: checked
    }
}

export const selectGroup = (group) => (dispatch) => {
    dispatch(handleFieldChange('selectedGroupId', group.groupId));
    const teamView = group.groupId !== null;
    dispatch(handleFieldChange('teamView', teamView));
}

export const onSelectChallenge = (challengeAthleteId) => {
    return {
        type: ON_SELECT_CHALLENGE,
        challengeAthleteId: challengeAthleteId
    }
}

export const refresh = () => {
    return {
        type: DASHBOARD_REFRESH
    }
}
