import {
    FETCH_ATHLETE_ACHIEVEMENTS_REQUESTED,
    FETCH_ATHLETE_ACHIEVEMENTS_RESOLVED,
    FETCH_ATHLETE_ACHIEVEMENTS_REJECTED,
    HANDLE_FIELD_CHANGE
} from './actions';
import { DASHBOARD_REFRESH } from '../Dashboard/actions';
import assign from 'lodash.assign';

const initialState = {
    achievements: {
        earned: [],
        teased: []
    },
    hasError: false,
    error: null,
    isLoading: false,
    isLoaded: false,
    refresh: false,
    isZoomVisible: false,
    zoomItem: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case HANDLE_FIELD_CHANGE:
            var new_state = assign({}, state);
            new_state[action.key] = action.value;
            return new_state;
        case FETCH_ATHLETE_ACHIEVEMENTS_RESOLVED:
            return assign({}, state, {
                achievements: action.response,
                hasError: false,
                isLoading: false,
                isLoaded: true,
                error: null
            });
        case FETCH_ATHLETE_ACHIEVEMENTS_REQUESTED:
            return assign({}, state, {
                isLoading: true,
                isLoaded: false,
                refresh: false
            });
        case FETCH_ATHLETE_ACHIEVEMENTS_REJECTED:
            return assign({}, state, {
                isLoading: false,
                isLoaded: true,
                hasError: true,
                error: action.error
            });
        case DASHBOARD_REFRESH:
            return assign({}, state, {
                refresh: true
            });
        default:
            return state;
    }
}
