'use strict';
import { FETCH_CHALLENGE_STATS_API } from './constants';
import { CALL_API } from '../../common/middleware/api';

export const FETCH_CHALLENGE_STATS_REQUESTED = 'yesfit/ChallengeAthleteStats/FETCH_CHALLENGE_STATS_RESOLVED_REQUESTED';
export const FETCH_CHALLENGE_STATS_RESOLVED = 'yesfit/ChallengeAthleteStats/FETCH_CHALLENGE_STATS_RESOLVED';
export const FETCH_CHALLENGE_STATS_REJECTED = 'yesfit/ChallengeAthleteStats/FETCH_CHALLENGE_STATS_REJECTED';

const fetchChallengeStats = endpoint => ({
    [CALL_API]: {
        types: [FETCH_CHALLENGE_STATS_REQUESTED, FETCH_CHALLENGE_STATS_RESOLVED, FETCH_CHALLENGE_STATS_REJECTED],
        endpoint: endpoint,
        method: 'GET'
    }
})

export const loadChallengeStats = () => (dispatch, getState) => {
    const endpoint = FETCH_CHALLENGE_STATS_API + '/' + getState().dashboard.challengeAthleteId;
    return dispatch(fetchChallengeStats(endpoint));
}
