'use strict';
import { CALL_API } from '../../common/middleware/api';
import { handleFieldChange as handleDashboardFieldChange, onSelectChallenge } from '../Dashboard/actions';
import { incompleteSelector } from './selectors';

export const FETCH_CHALLENGE_HISTORY_REQUESTED = 'yesfit/ChallengeHistory/FETCH_CHALLENGE_HISTORY_REQUESTED';
export const FETCH_CHALLENGE_HISTORY_RESOLVED = 'yesfit/ChallengeHistory/FETCH_CHALLENGE_HISTORY_RESOLVED';
export const FETCH_CHALLENGE_HISTORY_REJECTED = 'yesfit/ChallengeHistory/FETCH_CHALLENGE_HISTORY_REJECTED';
export const HANDLE_FIELD_CHANGE = 'yesfit/ChallengeHistory/FETCH_CHALLENGE_HANDLE_FIELD_CHANGE';
export const HANDLE_REFRESH = 'yesfit/ChallengeHistory/FETCH_CHALLENGE_HANDLE_REFRESH';
export const TOGGLE_SECTION_VERTICAL = 'yesfit/ChallengeHistory/TOGGLE_SECTION_VERTICAL';

const API = '/api/v1/challengeHistory';

const fetchChallengeHistory = endpoint => ({
	[CALL_API]: {
		types: [FETCH_CHALLENGE_HISTORY_REQUESTED, FETCH_CHALLENGE_HISTORY_RESOLVED, FETCH_CHALLENGE_HISTORY_REJECTED],
		endpoint: endpoint,
		method: 'GET',
		onResolved: fetchChallengeHistoryResolved
	}
})

export const loadChallengeHistory = (userName) => dispatch => {
	const param = (userName !== null && typeof userName !== 'undefined') ? '/' + userName : '';
	return dispatch(fetchChallengeHistory(API + param));
}

const fetchChallengeHistoryResolved = (response) => (dispatch, getState) => {
	if (response.filter(item => !item.isSwag).length > 0) {
		dispatch(handleDashboardFieldChange('noChallenges', false));
		const dashboard = getState().dashboard;
		//rememberLastChallenge will only be set when ChallengeHistory.refresh is used. In other words, we want to refresh the
		//data in the component but we also want to make sure the previously selected challenge is selected again.
		var challengeAthleteId = 0;
		if (dashboard.rememberLastChallenge) {
			challengeAthleteId = dashboard.challengeAthleteId;
		}
		else {
			//Find the first challenge that isn't Swag
			const notSwag = response.find(item => !item.isSwag);
			if (notSwag !== null) {
				challengeAthleteId = notSwag.challengeAthleteId;
			}
		}
		if (challengeAthleteId > 0) {
			dispatch(onSelectChallenge(challengeAthleteId))
		}
	}
	else {
		dispatch(handleDashboardFieldChange('noChallenges', true));
	}
	return dispatch(handleDashboardFieldChange('rememberLastChallenge', false));
}

export const handleRefresh = () => {
	return {
		type: HANDLE_REFRESH
	};
}

export const handleFieldChange = (key, value) => {
	return {
		type: HANDLE_FIELD_CHANGE,
		key: key,
		value: value
	}
}

export const toggleSectionVertical = (section) => {
	return {
		type: TOGGLE_SECTION_VERTICAL,
		section: section
	}
}
