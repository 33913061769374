'use strict';
import { push } from '../../common/actions/navigate';
import { CALL_API } from '../../common/middleware/api'
import { showMessage } from '../../common/notifications/actions';
import * as n from '../../common/notifications/constants';

export const POST_REQUESTED = 'yesfit/RP/POST_REQUESTED';
export const POST_RESOLVED = 'yesfit/RP/POST_RESOLVED';
export const POST_REJECTED = 'yesfit/RP/POST_REJECTED';
export const RESET_RESET_PASSWORD = 'yesfit/RP/RESET';

const RP_API = '/api/v1/resetPassword';

export const post = (email, sendEmail) => ({
    [CALL_API]: {
        types: [POST_REQUESTED, POST_RESOLVED, POST_REJECTED],
        endpoint: RP_API,
        method: 'POST',
        body: {
            email: email,
            sendEmail: sendEmail
        },
        onResolved: postResolved
    }
})

const postResolved = (response) => (dispatch) => {
    if (response.sendEmail) {
        dispatch(showMessage('Your new password will be emailed to you', n.NOTIFICATIONS_INFO));
        dispatch(push('/login'));
    }
}

export const resetResetPassword = () => {
    return {
        type: RESET_RESET_PASSWORD
    }
}