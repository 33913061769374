import {
    FETCH_CHALLENGE_REWARDS_REQUESTED,
    FETCH_CHALLENGE_REWARDS_RESOLVED,
    FETCH_CHALLENGE_REWARDS_REJECTED
} from './actions';
import assign from 'lodash.assign';

const initialState = {
    challengeAthleteId: 0,
    challengeAthleteRewards: [],
    hasError: false,
    error: null,
    isLoading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CHALLENGE_REWARDS_RESOLVED:
            return assign({}, state, {
                challengeAthleteRewards: action.response,
                hasError: false,
                isLoading: false,
                error: null
            });
        case FETCH_CHALLENGE_REWARDS_REQUESTED:
            return assign({}, state, {
                isLoading: true
            });
        case FETCH_CHALLENGE_REWARDS_REJECTED:
            return assign({}, state, {
                isLoading: false,
                hasError: true,
                error: action.error
            });
        default:
            return state;
    }
}
