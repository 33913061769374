'use strict';
import { CALL_API } from '../../../common/middleware/api';
import { showMessage } from '../../../common/notifications/actions';
import * as n from '../../../common/notifications/constants';

export const FETCH_REQUESTED = 'yesfit/GroupsAdminEvents/FETCH_REQUESTED';
export const FETCH_RESOLVED = 'yesfit/GroupsAdminEvents/FETCH_RESOLVED';
export const FETCH_REJECTED = 'yesfit/GroupsAdminEvents/FETCH_REJECTED';
export const POST_REQUESTED = 'yesfit/GroupsAdminEvents/POST_REQUESTED';
export const POST_RESOLVED = 'yesfit/GroupsAdminEvents/POST_RESOLVED';
export const POST_REJECTED = 'yesfit/GroupsAdminEvents/POST_REJECTED';
export const HANDLE_FIELD_CHANGE = 'yesfit/GroupsAdminEvents/HANDLE_FIELD_CHANGE';

const API = '/api/v1/groups/GroupEvent';

export const fetchGroup = (groupId) => ({
    [CALL_API]: {
        types: [FETCH_REQUESTED, FETCH_RESOLVED, FETCH_REJECTED],
        endpoint: `${API}/${groupId}`,
        method: 'GET'
    }
})

export const addGroupToEvent = (body) => ({
    [CALL_API]: {
        types: [POST_REQUESTED, POST_RESOLVED, POST_REJECTED],
        endpoint: `${API}`,
        body: body,
        method: 'POST',
        onResolved: onAddGroupToEventResolved
    }
})

const onAddGroupToEventResolved = () => dispatch => {
    dispatch(showMessage('This group has been added to the Event', n.NOTIFICATIONS_INFO));
}

export const handleFieldChange = (key, value) => dispatch => {
    return dispatch({
        type: HANDLE_FIELD_CHANGE,
        key: key,
        value: value
    });
}
