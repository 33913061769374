import {
    FETCH_REQUESTED,
    FETCH_RESOLVED,
    FETCH_REJECTED,
    POST_FRIEND_CONNECT_REQUESTED,
    POST_FRIEND_CONNECT_RESOLVED,
    POST_FRIEND_CONNECT_REJECTED,
    POST_FACEBOOK_CONNECT_REQUESTED,
    POST_FACEBOOK_CONNECT_RESOLVED,
    POST_FACEBOOK_CONNECT_REJECTED,
    SELECTED_ATHLETE_ID,
    SELECTED_GROUP_ID,
    CONNECT_REQUESTED,
    CONNECT_RESOLVED,
    CONNECT_REJECTED,
    INVITE_REQUESTED,
    INVITE_RESOLVED,
    INVITE_REJECTED,
    ISPRIVATE_REQUESTED,
    ISPRIVATE_RESOLVED,
    ISPRIVATE_REJECTED,
    HANDLE_FIELD_CHANGE
} from './actions';
import { POLL_REQUESTED } from '../AsyncPoll/actions';
import { DASHBOARD_REFRESH } from '../Dashboard/actions';
import assign from 'lodash.assign';
import moment from 'moment';

const initialState = {
    athleteFacebookFriends: {
        data: []
    },
    athleteConnections: {
        data: []
    },
    socialProfile: {
        profile: null,
        lastWorkout: null,
        lastAchievement: null,
        workoutSummary: null,
        workoutSummaryYtd: null,
        achievementSummary: null,
        achievementSummaryYtd: null,
        challengeSummary: null,
        challengeSummaryYtd: null,
        completedChallenges: null
    },
    groups: [],
    isMe: false,
    userName: null,
    hasError: false,
    error: null,
    isLoading: false,
    isSaving: false,
    isLoaded: true, //Trust me - using this to force a screen reload when the username changes
    selectedAthleteId: null,
    selectedGroupId: null,
    selectedAthleteName: null,
    selectedAthleteImageUrl: null,
    lastUpdated: moment().format('h:mm A'),
    refresh: false,
    inviteAthlete: false,
    filter: '',
    showModal: false, //For adding friends in Native
    afterRefresh: null //method call-back for after a refresh, after a save friend connect
};

export default (state = initialState, action) => {
    switch (action.type) {
        case HANDLE_FIELD_CHANGE:
            var new_state = assign({}, state);
            new_state[action.key] = action.value;
            return new_state;
        case FETCH_RESOLVED:
            return assign({}, state, {
                athleteFacebookFriends: action.response.facebookResponse,
                athleteConnections: {
                    data: action.response.athleteConnections
                },
                socialProfile: action.response.socialProfile,
                groups: action.response.groups,
                userName: action.response.userName,
                isMe: action.response.isMe,
                hasError: false,
                isLoading: false,
                isLoaded: true,
                error: null,
                lastUpdated: moment().format('h:mm A'),
                refresh: false,
                inviteAthlete: false
            });
        case FETCH_REQUESTED:
            return assign({}, state, {
                isLoading: true,
                refresh: false,
                inviteAthlete: false
            });
        case FETCH_REJECTED:
            return assign({}, state, {
                isLoading: false,
                hasError: true,
                isLoaded: true,
                error: action.message,
                refresh: false,
                inviteAthlete: false
            });
        case INVITE_RESOLVED:
            return assign({}, state, {
                hasError: false,
                isLoading: false,
                error: null,
                lastUpdated: moment().format('h:mm A'),
                refresh: true,
                inviteAthlete: false
            });
        case INVITE_REQUESTED:
            return assign({}, state, {
                isLoading: true,
                refresh: false,
                inviteAthlete: false,
                showModal: false
            });
        case INVITE_REJECTED:
            return assign({}, state, {
                isLoading: false,
                hasError: true,
                error: action.message,
                refresh: false,
                inviteAthlete: false
            });
        case CONNECT_REQUESTED:
        case POST_FRIEND_CONNECT_REQUESTED:
            return assign({}, state, {
                isLoading: true,
                showModal: false
            });
        case CONNECT_RESOLVED:
            return assign({}, state, {
                hasError: false,
                isLoading: false,
                error: null
            });
        case CONNECT_REJECTED:
        case POST_FRIEND_CONNECT_REJECTED:
            return assign({}, state, {
                isLoading: false,
                hasError: true,
                error: action.message
            });
        case POST_FRIEND_CONNECT_RESOLVED:
            var new_state = assign({}, state, {
                isLoading: false,
                isSaving: false,
                error: null,
                refresh: true,
                inviteAthlete: false
            });
            /*
            const athleteConnect = state.athleteConnections.data.find((item) => item.athleteConnectId == action.response.athleteConnect.athleteConnectId)
            if (athleteConnect == null) {
                //Insert into the array
                new_state.athleteConnections.data = [action.response.athleteConnect, ...state.athleteConnections.data];
            }
            else if (athleteConnect.status === 3) {
                //Unfollow - so remove from the array
                new_state.athleteConnections.data = state.athleteConnections.data.filter(
                    (item) => item.athleteConnectId !== action.response.athleteConnect.athleteConnectId
                )
            }
            else {
                //Update the array
                new_state.athleteConnections.data = state.athleteConnections.data.map(
                    (item) => item.athleteConnectId === action.response.athleteConnect.athleteConnectId ? action.response.athleteConnect : item
                )
            }
            */
            return new_state;
        case SELECTED_ATHLETE_ID:
            return assign({}, state, {
                selectedAthleteId: action.athleteId
            });
        case SELECTED_GROUP_ID:
            return assign({}, state, {
                selectedGroupId: action.groupId
            });
        case ISPRIVATE_RESOLVED:
            return assign({}, state, {
                refresh: true
            });
        case ISPRIVATE_REQUESTED:
        case POST_FACEBOOK_CONNECT_REQUESTED:
            return assign({}, state, {
                isLoading: true
            });
        case ISPRIVATE_REJECTED:
        case POST_FACEBOOK_CONNECT_REJECTED:
            return assign({}, state, {
                isLoading: false,
                hasError: true,
                error: action.message
            });
        case POST_FACEBOOK_CONNECT_RESOLVED:
            var new_state = assign({}, state, {
                isLoading: false,
                isSaving: false,
                error: null
            });
            new_state.athleteConnections.data = [...state.athleteConnections.data, action.response.athleteConnect];
            return new_state;
        case DASHBOARD_REFRESH:
        case POLL_REQUESTED:
            return assign({}, state, {
                refresh: true
            });
        default:
            return state;
    }
}
