'use strict';
import { push, replace } from '../../common/actions/navigate';
import { CALL_API } from '../../common/middleware/api'
import { showMessage } from '../../common/notifications/actions';
import * as n from '../../common/notifications/constants';
import { handleProfileObjectChange } from '../Login/actions';

export const FETCH_REQUESTED = 'yesfit/AP/FETCH_REQUESTED';
export const FETCH_RESOLVED = 'yesfit/AP/FETCH_RESOLVED';
export const FETCH_REJECTED = 'yesfit/AP/FETCH_REJECTED';
export const POST_REQUESTED = 'yesfit/AP/POST_REQUESTED';
export const POST_RESOLVED = 'yesfit/AP/POST_RESOLVED';
export const POST_REJECTED = 'yesfit/AP/POST_REJECTED';
export const DELETE_REQUESTED = 'yesfit/AP/DELETE_REQUESTED';
export const DELETE_RESOLVED = 'yesfit/AP/DELETE_RESOLVED';
export const DELETE_REJECTED = 'yesfit/AP/DELETE_REJECTED';
export const CONNECT_REQUESTED = 'yesfit/AP/CONNECT_REQUESTED';
export const CONNECT_RESOLVED = 'yesfit/AP/CONNECT_RESOLVED';
export const CONNECT_REJECTED = 'yesfit/AP/CONNECT_REJECTED';
export const DISCONNECT_REQUESTED = 'yesfit/AP/DISCONNECT_REQUESTED';
export const DISCONNECT_RESOLVED = 'yesfit/AP/DISCONNECT_RESOLVED';
export const DISCONNECT_REJECTED = 'yesfit/AP/DISCONNECT_REJECTED';
export const DELETE_AVATAR_REQUESTED = 'yesfit/AP/DELETE_AVATAR_REQUESTED';
export const DELETE_AVATAR_RESOLVED = 'yesfit/AP/DELETE_AVATAR_RESOLVED';
export const DELETE_AVATAR_REJECTED = 'yesfit/AP/DELETE_AVATAR_REJECTED';
export const HANDLE_AP_FIELD_CHANGE = 'yesfit/AP/HANDLE_AP_FIELD_CHANGE';

const AP_API = '/api/v1/athleteProfile';
const APC_API = '/api/v1/athleteProfileConnect';
export const AP_AVATAR_API = '/api/v1/avatar';

const fetchAthleteProfile = (endpoint) => ({
    [CALL_API]: {
        types: [FETCH_REQUESTED, FETCH_RESOLVED, FETCH_REJECTED],
        endpoint: endpoint,
        method: 'GET'
    }
})

export const deleteAthleteProfile = (userName) => ({
    [CALL_API]: {
        types: [DELETE_REQUESTED, DELETE_RESOLVED, DELETE_REJECTED],
        endpoint: AP_API + '/' + userName,
        method: 'DELETE',
        onResolved: deleteAthleteProfileResolved
    }
})

const postAthleteProfileConnect = (endpoint, body) => ({
    [CALL_API]: {
        types: [CONNECT_REQUESTED, CONNECT_RESOLVED, CONNECT_REJECTED],
        endpoint: endpoint,
        method: 'POST',
        body: body,
        onResolved: connectAthleteProfileResolved
    }
})

const deleteAthleteProfileConnect = (endpoint) => ({
    [CALL_API]: {
        types: [DISCONNECT_REQUESTED, DISCONNECT_RESOLVED, DISCONNECT_REJECTED],
        endpoint: endpoint,
        method: 'DELETE',
        onResolved: disconnectAthleteProfileResolved
    }
})

export const disconnectAthleteProfile = () => (dispatch, getState) => {
    const facebookUserId = getState().athleteProfile.data.user.facebookUserId;
    return dispatch(deleteAthleteProfileConnect(APC_API + '/' + facebookUserId));
}

export const deleteAvatar = (id) => ({
    [CALL_API]: {
        types: [DELETE_AVATAR_REQUESTED, DELETE_AVATAR_RESOLVED, DELETE_AVATAR_REJECTED],
        endpoint: AP_AVATAR_API + '/' + id,
        method: 'DELETE',
        onResolved: deleteAvatarResolved
    }
})

const postAthleteProfile = (endpoint, body) => ({
    [CALL_API]: {
        types: [POST_REQUESTED, POST_RESOLVED, POST_REJECTED],
        endpoint: endpoint,
        method: 'POST',
        body: body,
        onResolved: postAthleteProfileResolved
    }
})

export const saveAthleteProfile = data => dispatch => {
    return dispatch(postAthleteProfile(AP_API, data));
}

export const connectAthleteProfile = (fbid, accessToken, userId) => (dispatch) => {
    var newUser = {
        userId: userId,
        facebookUserId: fbid,
        fbAccessToken: accessToken
    };
    return dispatch(postAthleteProfileConnect(APC_API, newUser));
}

export const loadAthleteProfile = (userName) => (dispatch) => {
    return dispatch(fetchAthleteProfile(AP_API + '/' + userName));
}

const deleteAthleteProfileResolved = () => (dispatch) => {
    dispatch(showMessage('You have successfully deleted your Yes.Fit account. We are sad to see you go :(', n.NOTIFICATIONS_INFO));
    return dispatch(push('/races'));
}

const deleteAvatarResolved = () => (dispatch) => {
    dispatch(showMessage('You have successfully removed your Yes.Fit Avatar', n.NOTIFICATIONS_INFO));
}

const connectAthleteProfileResolved = (response) => (dispatch) => {
    dispatch(handleProfileObjectChange({ facebookUserId: response.facebookUserId, fbAccessToken: response.fbAccessToken, isFacebookUser: true }));
    dispatch(showMessage('You have successfully connected your Yes.Fit profile to Facebook', n.NOTIFICATIONS_INFO));
}

const disconnectAthleteProfileResolved = () => (dispatch) => {
    dispatch(handleProfileObjectChange({ facebookUserId: null, fbAccessToken: null, isFacebookUser: false }));
    dispatch(showMessage('You have successfully disconnected your Yes.Fit profile from Facebook', n.NOTIFICATIONS_INFO));
}

const postAthleteProfileResolved = (response) => (dispatch) => {
    if (response.logout) {
        dispatch(push('/login'));
        dispatch(showMessage(response.message, n.NOTIFICATIONS_INFO));
        //dispatch(logout());
		//TODO: This isn't ideal, but after logging in or out, Intercom and Facebook Pixel have a fit. Refreshing the page fixes it
		//window.location.reload();
    }
    else {
        dispatch(showMessage('Your changes have been saved', n.NOTIFICATIONS_INFO));
    }
}

export const handleAPFieldChange = (key, value) => {
    return {
        type: HANDLE_AP_FIELD_CHANGE,
        key: key,
        value: value
    }
}