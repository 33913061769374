import {
    POST_REQUESTED,
    POST_RESOLVED,
    POST_REJECTED,
    FETCH_REQUESTED,
    FETCH_RESOLVED,
    FETCH_REJECTED,
    HANDLE_FIELD_CHANGE,
    HANDLE_OBJECT_CHANGE
} from './actions';
import { POST_WORKOUT_RESOLVED } from '../Workouts/actions';
import { RESET } from '../Steppers/actions';
import { CHECKOUT_RESOLVED } from '../JoinRace/actions';
import { DASHBOARD_REFRESH } from '../Dashboard/actions';
import assign from 'lodash.assign';

const initialState = {
    item: {
        challengeAthleteId: 0,
        challengeId: 0,
        athleteId: 0,
        userDataAPIId: 0,
        isPaused: false,
        startRace: false,
        validicApp: '',
        activityType: null,
        startDate: null,
        isPending: false,
        activeChallenges: 0,
        activeChallengeName: null
    },
    hasError: false,
    error: null,
    isLoading: false,
    isSaved: false,
    isLoaded: false,
    refresh: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case POST_RESOLVED:
            return assign({}, state, {
                item: action.response,
                hasError: false,
                isLoading: false,
                isSaved: true,
                error: null
            });
        case POST_REQUESTED:
            return assign({}, state, {
                isSaved: false,
                isLoading: true,
                isPending: false
            });
        case POST_REJECTED:
            return assign({}, state, {
                isLoading: false,
                isSaved: false,
                hasError: true,
                error: action.message
            });
        case FETCH_RESOLVED:
            return assign({}, state, {
                item: action.response,
                hasError: false,
                isLoading: false,
                isLoaded: true,
                error: null
            });
        case FETCH_REQUESTED:
            return assign({}, state, {
                isLoading: true,
                refresh: false
            })
        case FETCH_REJECTED:
            return assign({}, state, {
                isLoading: false,
                hasError: true,
                isLoaded: true,
                error: action.error
            })
        case HANDLE_FIELD_CHANGE:
            var new_state = assign({}, state);
            if (action.value !== state.item[action.key]) {
                new_state.item.isPending = true;
            }
            new_state.item[action.key] = action.value;
            return new_state;
        case HANDLE_OBJECT_CHANGE:
            var new_state = assign({}, state);
            var new_item = assign({}, action.item);
            new_state.item = new_item;
            return new_state;
        case DASHBOARD_REFRESH:
        case CHECKOUT_RESOLVED:
        case RESET:
            return assign({}, state, {
                ...state,
                refresh: true
            })
        case POST_WORKOUT_RESOLVED:
            //This action is in response to a user posting a workout. We need to handle the situation
            //where the challenge was completed or the waiver accepted date has changed (which is the
            //case if the user just added their first workout
            var new_state = assign({}, state);
            if (action.response.isChallengeComplete) {
                new_state.item.isChallengeComplete = true;
                new_state.item.challengeCompletedDate = action.response.challengeCompletedDate;
            }
            if (action.response.workout.newStartDate !== null) {
                new_state.item.waiverAccepted = action.response.workout.newStartDate;
            }
            return new_state;
        default:
            return state;
    }
}