import {createSelector} from 'reselect';

//TODO: Move to ChallengeAthlete/selectors?
export const challengeAthleteSelector = createSelector(
    state => state.dashboard.challengeAthleteId,
    state => state.challengeHistory.history,
    (challengeAthleteId, history) => {
        if (challengeAthleteId > 0 && history.length > 0) {
            const i = history.find((item) => item.challengeAthleteId === challengeAthleteId);
            if (i !== null) {
                return i;
            }
        }
        return {challengeAthleteId: 0, title: ''};
    }
)
