import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';

const DelayedFallback = ({ children, delay = 300 }) => {
    const [show, setShow] = useState(false);
    useEffect(() => {
        let timeout = setTimeout(() => setShow(true), delay);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return <>{show && children}</>;
};

export default class AppSuspense extends React.Component {

    _fallback = () => 
        <DelayedFallback>
            <div className='center-page'>
                <Spin spinning={true} size='large'>
                </Spin>
            </div>
        </DelayedFallback>

    render() {
        return (
            <React.Suspense fallback={this._fallback()}>
                {this.props.children}
            </React.Suspense>
        )
    }
}
