import {
    FETCH_REQUESTED,
    FETCH_RESOLVED,
    FETCH_REJECTED,
    FETCH_RESET
} from './actions';
import assign from 'lodash.assign';

const initialState = {
    challenges: [],
    hasError: false,
    error: null,
    isLoading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RESOLVED:
            return assign({}, state, {
                challenges: action.response,
                hasError: false,
                isLoading: false,
                error: null
            });
        case FETCH_REQUESTED:
            return assign({}, state, {
                //isLoading: true
            })
        case FETCH_REJECTED:
            return assign({}, state, {
                isLoading: false,
                hasError: true,
                error: action.error
            })
        case FETCH_RESET:
            return assign({}, state, {
                ...initialState
            })
        default:
            return state;
    }
}