import {
    POST_REQUESTED,
    POST_RESOLVED,
    POST_REJECTED,
    DELETE_REQUESTED,
    DELETE_RESOLVED,
    DELETE_REJECTED,
    UPDATE_PLAN_POST_REQUESTED,
    UPDATE_PLAN_POST_RESOLVED,
    UPDATE_PLAN_POST_REJECTED,
    UPDATE_CUSTOMER_POST_REQUESTED,
    UPDATE_CUSTOMER_POST_RESOLVED,
    UPDATE_CUSTOMER_POST_REJECTED,
    HANDLE_FIELD_CHANGE,
    HANDLE_OBJECT_CHANGE,
    FETCH_ATHLETE_SUB_REQUESTED,
    FETCH_ATHLETE_SUB_RESOLVED,
    FETCH_ATHLETE_SUB_REJECTED,
    VALIDATE_COUPON_REQUESTED,
    VALIDATE_COUPON_RESOLVED,
    VALIDATE_COUPON_REJECTED,
    REMOVE_COUPON,
    POST_REINSTATE_REQUESTED,
    POST_REINSTATE_RESOLVED,
    POST_REINSTATE_REJECTED,
    FETCH_UPCOMING_INVOICE_REQUESTED,
    FETCH_UPCOMING_INVOICE_RESOLVED,
    FETCH_UPCOMING_INVOICE_REJECTED
} from './actions';
import assign from 'lodash.assign';
import { RESET } from '../Steppers/actions';
import { LOGIN_RESOLVED, LOGOUT } from '../Login/actions';

const initialState = {
    data: {
        email: null,
        name: null,
        planId: '',
        token: null,
        userId: null,
        isNew: false,
        refresh: false,
        couponId: null,
        editMode: false,
        referralCode: null
    },
    plan: {
        id: '',
        amount: null,
        currency: null,
        interval: null,
        intervalCount: null,
        liveMode: null,
        trialPeriodDays: null,
        name: null,
        metadata: []
    },
    coupon: {
        amountOff: 0,
        percentOff: 0,
        durationInMonths: 0,
        id: null
    },
    plans: [],
    charges: [],
    summary: {},
    subscription: {
        id: null,
        plan: {
            amount: null,
            interval: null,
            name: null,
            metadata: {
                Image: null,
                Description: null,
                Name: null
            }
        },
        start: null,
        customerId: null,
        status: null
    },
    customer: {
        email: null,
        defaultSourceId: null,
        metadata: {
            Name: null,
            UserId: null
        },
        card: {
            last4: null,
            expirationYear: null,
            brand: null,
            name: null
        }
    },
    invoices: [],
    upcomingInvoice: {
        lines: []
    },
    upcoming: [],
    stripeSubscriptionUrl: null,
    membershipUrl: null, //RevenueCat
    subscriptionType: null, //1 - Stripe, 2 - RevenueCat
    isMember: false, //RevenueCat
    hasError: false,
    error: null,
    isLoading: false,
    isSaved: false,
    isSaving: false,
    isLoaded: false,
    refresh: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case POST_RESOLVED:
            return assign({}, state, {
                ...state,
                data: {
                    ...state.data,
                    isNew: true
                },
                plan: {
                    ...action.response.plan
                },
                hasError: false,
                isSaving: false,
                isSaved: true,
                error: null
            });
        case UPDATE_CUSTOMER_POST_RESOLVED:
            return assign({}, state, {
                ...state,
                hasError: false,
                isSaving: false,
                isSaved: true,
                error: null,
                isLoading: false,
                refresh: true
            });
        case UPDATE_PLAN_POST_RESOLVED:
            return assign({}, state, {
                ...state,
                isSaving: false,
                isSaved: true,
                error: null,
                isLoading: false,
                isLoaded: true
            });
        case VALIDATE_COUPON_RESOLVED:
            return assign({}, state, {
                ...state,
                coupon: {
                    ...action.response.stripeCoupon
                },
                data: {
                    ...state.data,
                    couponId: action.response.stripeCoupon.id,
                    referralCode: action.response.referralCode
                },
                hasError: false,
                isSaving: false,
                isSaved: true,
                error: null,
                isLoading: false,
                isLoaded: true
            });
        case REMOVE_COUPON:
            return assign({}, state, {
                ...state,
                coupon: {
                    ...initialState.coupon
                },
                data: {
                    ...state.data,
                    couponId: null,
                    referralCode: null
                },
                hasError: false,
                isSaving: false,
                isSaved: true,
                error: null,
                isLoading: false,
                isLoaded: true
            });
        case DELETE_RESOLVED:
            return assign({}, state, {
                ...initialState,
                refresh: true
            });
        case POST_REINSTATE_RESOLVED:
            return assign({}, state, {
                ...initialState,
                refresh: true
            });
        case FETCH_ATHLETE_SUB_RESOLVED:
            return assign({}, state, {
                ...state,
                subscription: {
                    ...action.response.subscription
                },
                customer: {
                    ...action.response.customer
                },
                invoices: action.response.invoices,
                plans: action.response.plans,
                charges: action.response.charges,
                summary: action.response.summary,
                upcoming: action.response.upcoming,
                membershipUrl: action.response.membershipUrl,
                subscriptionType: action.response.subscriptionType,
                isMember: action.response.isMember,
                stripeSubscriptionUrl: action.response.stripeSubscriptionUrl,
                data: {
                    ...state.data,
                    planId: action.response.plans.length > 0 ? action.response.plans[0].id : null
                },
                hasError: false,
                isSaving: false,
                isSaved: true,
                error: null,
                isLoaded: true,
                isLoading: false,
                refresh: false
            });
        case FETCH_UPCOMING_INVOICE_RESOLVED:
            return assign({}, state, {
                ...state,
                upcomingInvoice: action.response,
                hasError: false,
                isSaving: false,
                isSaved: true,
                error: null,
                isLoaded: true,
                isLoading: false,
                refresh: false
            });
        case POST_REQUESTED:
            return assign({}, state, {
                ...state,
                isSaved: false,
                isSaving: true
            });
        case POST_REINSTATE_REQUESTED:
            return assign({}, state, {
                ...state,
                isSaved: false,
                isSaving: true
            });
        case VALIDATE_COUPON_REQUESTED:
            return assign({}, state, {
                ...state,
                isSaved: false,
                isSaving: true,
                isLoading: true,
                //isLoaded: false,
                hasError: false,
                error: null
            });
        case DELETE_REQUESTED:
            return assign({}, state, {
                ...state,
                isSaved: false,
                isSaving: true
            });
        case UPDATE_CUSTOMER_POST_REQUESTED:
            return assign({}, state, {
                ...state,
                isSaved: false,
                isSaving: true
            });
        case UPDATE_PLAN_POST_REQUESTED:
            return assign({}, state, {
                ...state,
                isSaved: false,
                isSaving: true
            });
        case FETCH_ATHLETE_SUB_REQUESTED:
            return assign({}, state, {
                ...state,
                isLoading: true,
                isLoaded: false,
                refresh: false
            });
        case FETCH_UPCOMING_INVOICE_REQUESTED:
            return assign({}, state, {
                ...state,
                isLoading: true,
                isLoaded: false,
                refresh: false
            });
        case POST_REJECTED:
            return assign({}, state, {
                ...state,
                isLoading: false,
                isSaving: false,
                hasError: true,
                error: action.message
            });
        case VALIDATE_COUPON_REJECTED:
            return assign({}, state, {
                ...state,
                isLoading: false,
                isLoaded: true,
                isSaving: false,
                hasError: true,
                error: action.message
            });
        case DELETE_REJECTED:
            return assign({}, state, {
                ...state,
                isLoading: false,
                isSaving: false,
                hasError: true,
                error: action.message
            });
        case POST_REINSTATE_REJECTED:
            return assign({}, state, {
                ...state,
                isLoading: false,
                isSaving: false,
                hasError: true,
                error: action.message
            });
        case UPDATE_CUSTOMER_POST_REJECTED:
            return assign({}, state, {
                ...state,
                isLoading: false,
                isSaving: false,
                hasError: true,
                error: action.message
            });
        case UPDATE_PLAN_POST_REJECTED:
            return assign({}, state, {
                ...state,
                isLoading: false,
                isSaving: false,
                hasError: true,
                error: action.message
            });
        case FETCH_ATHLETE_SUB_REJECTED:
            return assign({}, state, {
                ...state,
                isLoading: false,
                isSaving: false,
                hasError: true,
                error: action.message,
                isLoaded: true
            });
        case FETCH_UPCOMING_INVOICE_REJECTED:
            return assign({}, state, {
                ...state,
                isLoading: false,
                isSaving: false,
                hasError: true,
                error: action.message,
                isLoaded: true
            });
        case HANDLE_FIELD_CHANGE:
            var new_state = assign({}, state);
            new_state.data[action.key] = action.value;
            return new_state;
        case HANDLE_OBJECT_CHANGE:
            return assign({}, state, {
                ...state,
                data: {
                    ...state.data,
                    ...action.object
                }
            })
        case RESET:
        case LOGIN_RESOLVED:
        case LOGOUT:
            return assign({}, state, {
                ...state,
                refresh: true
            });
        default:
            return state;
    }
}