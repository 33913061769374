'use strict';
import { CALL_API } from '../../common/middleware/api'

export const FETCH_CAR_REQUESTED = 'yesfit/CAR/FETCH_REQUESTED';
export const FETCH_CAR_RESOLVED = 'yesfit/CAR/FETCH_RESOLVED';
export const FETCH_CAR_REJECTED = 'yesfit/CAR/FETCH_REJECTED';
export const POST_CAR_REQUESTED = 'yesfit/CAR/POST_REQUESTED';
export const POST_CAR_RESOLVED = 'yesfit/CAR/POST_RESOLVED';
export const POST_CAR_REJECTED = 'yesfit/CAR/POST_REJECTED';
export const DELETE_CAR_REQUESTED = 'yesfit/CAR/DELETE_REQUESTED';
export const DELETE_CAR_RESOLVED = 'yesfit/CAR/DELETE_RESOLVED';
export const DELETE_CAR_REJECTED = 'yesfit/CAR/DELETE_REJECTED';
export const HANDLE_FIELD_CHANGE = 'yesfit/CAR/HANDLE_FIELD_CHANGE';

const CAR_API = '/api/v1/challengeRequests';
import { CF_API } from '../ChallengeAthleteSocial/actions';

export const fetchChallengeRequests = (challengeAthleteId) => ({
    [CALL_API]: {
        types: [FETCH_CAR_REQUESTED, FETCH_CAR_RESOLVED, FETCH_CAR_REJECTED],
        endpoint: `${CAR_API}/${challengeAthleteId}`,
        method: 'GET'
    }
})

export const postChallengeRequest = (body) => ({
    [CALL_API]: {
        types: [POST_CAR_REQUESTED, POST_CAR_RESOLVED, POST_CAR_REJECTED],
        endpoint: CAR_API,
        method: 'POST',
        body: body
    }
})

export const deleteChallengeRequest = (athleteConnectChallengeId) => ({
    [CALL_API]: {
        types: [DELETE_CAR_REQUESTED, DELETE_CAR_RESOLVED, DELETE_CAR_REJECTED],
        endpoint: `${CF_API}/${athleteConnectChallengeId}`,
        method: 'DELETE'
    }
})

export function handleFieldChange(key, value) {
    return {
        type: HANDLE_FIELD_CHANGE,
        key: key,
		value: value
    };
}
