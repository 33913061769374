'use strict';
import PropTypes from 'prop-types';
import React from 'react';
import { Alert, Row, Col, Button } from 'antd';

class SsoComponent extends React.Component {

	render() {
		const { isLoggingIn, loginRejected, loginRejectedMessage } = this.props;
		return (
			<Row gutter={24}>
				<Col span={24}>
					{loginRejected &&
						<Alert
							message={loginRejectedMessage}
							type="error"
							showIcon
							/>
					}
				</Col>
				<Col xs={24} sm={24} md={12} lg={12}>
					<Button type="primary" className="login-form-button" loading={isLoggingIn} icon="poweroff">
						Logging you into Yes.Fit
					</Button>
				</Col>
			</Row>
		);
	}
}

SsoComponent.propTypes = {
	isLoggingIn: PropTypes.bool.isRequired,
	loginRejected: PropTypes.bool.isRequired
};

export default SsoComponent;