'use strict';
import { CALL_API } from '../../common/middleware/api'

export const FETCH_REQUESTED = 'yesfit/GoalTypes/FETCH_REQUESTED';
export const FETCH_RESOLVED = 'yesfit/GoalTypes/FETCH_RESOLVED';
export const FETCH_REJECTED = 'yesfit/GoalTypes/FETCH_REJECTED';

export const load = () => ({
    [CALL_API]: {
        types: [FETCH_REQUESTED, FETCH_RESOLVED, FETCH_REJECTED],
        endpoint: '/api/v2/selectGoalType',
        method: 'GET'
    }
})
