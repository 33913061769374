import {
    FETCH_COUNTRIES_REQUESTED,
    FETCH_COUNTRIES_RESOLVED,
    FETCH_COUNTRIES_REJECTED
} from './actions';
import assign from 'lodash.assign';

const initialState = {
    countries: [{code:'US', description:'United States'}],
    hasError: false,
    error: null,
    isLoading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COUNTRIES_RESOLVED:
            return assign({}, state, {
                countries: action.response,
                hasError: false,
                isLoading: false,
                error: null
            });
        case FETCH_COUNTRIES_REQUESTED:
            return assign({}, state, {
                //isLoading: true
            })
        case FETCH_COUNTRIES_REJECTED:
            return assign({}, state, {
                isLoading: false,
                hasError: true,
                error: action.error
            })
        default:
            return state;
    }
}