'use strict';
import { CALL_API } from '../../common/middleware/api'

export const FETCH_REQUESTED = 'yesfit/Goals/FETCH_REQUESTED';
export const FETCH_RESOLVED = 'yesfit/Goals/FETCH_RESOLVED';
export const FETCH_REJECTED = 'yesfit/Goals/FETCH_REJECTED';
export const POST_REQUESTED = 'yesfit/Goals/POST_REQUESTED';
export const POST_RESOLVED = 'yesfit/Goals/POST_RESOLVED';
export const POST_REJECTED = 'yesfit/Goals/POST_REJECTED';
export const DELETE_REQUESTED = 'yesfit/Goals/DELETE_REQUESTED';
export const DELETE_RESOLVED = 'yesfit/Goals/DELETE_RESOLVED';
export const DELETE_REJECTED = 'yesfit/Goals/DELETE_REJECTED';
export const HANDLE_FIELD_CHANGE = 'yesfit/Goals/HANDLE_FIELD_CHANGE';
export const HANDLE_ITEM_FIELD_CHANGE = 'yesfit/Goals/HANDLE_ITEM_FIELD_CHANGE';

const API = '/api/v1/challengeAthleteGoals';

export const fetch = (challengeAthleteId) => ({
    [CALL_API]: {
        types: [FETCH_REQUESTED, FETCH_RESOLVED, FETCH_REJECTED],
        endpoint: `${API}/${challengeAthleteId}`,
        method: 'GET'
    }
})

export const post = (body) => ({
    [CALL_API]: {
        types: [POST_REQUESTED, POST_RESOLVED, POST_REJECTED],
        endpoint: `${API}`,
        body: body,
        method: 'POST'
        //onResolved: onPostAppsResolved
    }
})

export const deleteGoal = (challengeAthleteGoalId) => ({
    [CALL_API]: {
        types: [DELETE_REQUESTED, DELETE_RESOLVED, DELETE_REJECTED],
        endpoint: `${API}/${challengeAthleteGoalId}`,
        method: 'DELETE'
    }
})

export const handleFieldChange = (key, value) => {
    return {
        type: HANDLE_FIELD_CHANGE,
        key: key,
        value: value
    }
}

export const handleItemFieldChange = (key, value) => {
    return {
        type: HANDLE_ITEM_FIELD_CHANGE,
        key: key,
        value: value
    }
}
