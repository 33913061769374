import {
    FETCH_CHALLENGE_STATS_REQUESTED,
    FETCH_CHALLENGE_STATS_RESOLVED,
    FETCH_CHALLENGE_STATS_REJECTED
} from './actions';
import assign from 'lodash.assign';

const initialState = {
    challengeAthleteStats: {},
    hasError: false,
    error: null,
    isLoading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CHALLENGE_STATS_RESOLVED:
            return assign({}, state, {
                challengeAthleteStats: action.response,
                hasError: false,
                isLoading: false,
                error: null
            });
        case FETCH_CHALLENGE_STATS_REQUESTED:
            return assign({}, state, {
                isLoading: true
            });
        case FETCH_CHALLENGE_STATS_REJECTED:
            return assign({}, state, {
                isLoading: false,
                hasError: true,
                error: action.error
            });
        default:
            return state;
    }
}
