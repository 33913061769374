import {
    FETCH_CAS_REQUESTED,
    FETCH_CAS_RESOLVED,
    FETCH_CAS_REJECTED,
    POST_CAS_REQUESTED,
    POST_CAS_RESOLVED,
    POST_CAS_REJECTED,
    POST_CARI_REQUESTED,
    POST_CARI_RESOLVED,
    POST_CARI_REJECTED,
    DELETE_CAS_REQUESTED,
    DELETE_CAS_RESOLVED,
    DELETE_CAS_REJECTED,
    HANDLE_FIELD_CHANGE
} from './actions';
import { POST_WORKOUT_RESOLVED, DELETE_WORKOUT_RESOLVED } from '../Workouts/actions';
import { DASHBOARD_REFRESH } from '../Dashboard/actions';
import { POST_CAR_RESOLVED } from '../ChallengeAthleteRequests/actions';
import assign from 'lodash.assign';

const initialState = {
    friends: [],
    waiverAccepted: null,
    challengeAthleteId: null,
    isChallengeComplete: false,
    startDateTime: null,
    changeMyStartDate: false,
    hasError: false,
    error: null,
    isLoading: false,
    isSaving: false,
    editMode: false,
    hasModalError: false,
    email: '',
    refresh: false,
    currentChallengeAthlete: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CAS_RESOLVED:
            return assign({}, state, {
                ...state,
                friends: action.response.friends,
                hasError: false,
                isLoading: false,
                error: null
            });
        case FETCH_CAS_REQUESTED:
            return assign({}, state, {
                ...state,
                refresh: false,
                isLoading: true
            });
        case FETCH_CAS_REJECTED:
            return assign({}, state, {
                ...state,
                isLoading: false,
                hasError: true,
                error: action.message
            });
        case DELETE_CAS_REQUESTED:
        case POST_CAS_REQUESTED:
            return assign({}, state, {
                ...state,
                hasModalError: false,
                isLoading: true
            });
        case DELETE_CAS_REJECTED:
        case POST_CAS_REJECTED:
            return assign({}, state, {
                ...state,
                isLoading: false,
                hasModalError: true,
                error: action.message
            });
        case POST_CAS_RESOLVED:
            return assign({}, state, {
                ...state,
                isLoading: false,
                isSaving: false,
                error: null,
                hasModalError: false,
				editMode: false,
				refresh: true
            });
        case DELETE_CAS_RESOLVED:
            return assign({}, state, {
                ...state,
                friends: state.friends.filter(item => item.athleteConnectChallengeId !== action.response.id),
                isLoading: false,
                isSaving: false,
                error: null,
                hasModalError: false
            });
        case POST_CARI_REQUESTED:
            return assign({}, state, {
                ...state,
                hasModalError: false,
                isLoading: true
            });
        case POST_CARI_REJECTED:
            return assign({}, state, {
                ...state,
                isLoading: false,
                hasModalError: true,
                error: action.message
            });
        case POST_CARI_RESOLVED:
            return assign({}, state, {
                ...state,
                isLoading: false,
                isSaving: false,
                error: null,
                hasModalError: false,
                editMode: false,
                email: ''
            });
        case HANDLE_FIELD_CHANGE:
            var new_state = assign({}, state);
            new_state[action.key] = action.value;
            return new_state;
		case POST_CAR_RESOLVED:
		case DASHBOARD_REFRESH:
        case POST_WORKOUT_RESOLVED:
        case DELETE_WORKOUT_RESOLVED:
            return assign({}, state, {
                ...state,
                refresh: true
            });
        default:
            return state;
    }
}
