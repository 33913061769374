'use strict';
import { message, notification } from 'antd';
import { notificationRequested, messageRequested } from './';
import {
    NOTIFICATIONS_ERROR,
    NOTIFICATIONS_WARNING,
    NOTIFICATIONS_WARN,
    NOTIFICATIONS_LOADING
} from './constants';

message.config({
  top: 50,
  duration: 5
});

export function showNotification(title, content, level, duration=10) {
    return dispatch => {
        dispatch(notificationRequested(title, content, level, duration));
        const config = {
            message: title,
            description: content,
            duration: duration
        }
        switch (level) {
            case NOTIFICATIONS_ERROR:
                notification.error(config);
                break;
            case NOTIFICATIONS_WARNING:
                notification.warning(config);
                break;
            case NOTIFICATIONS_WARN:
                notification.warn(config);
                break;
            case NOTIFICATIONS_LOADING:
                notification.loading(config);
                break;
            default:
                notification.info(config);
        }
    }
}

export function showMessage(content, level) {
    return dispatch => {
        dispatch(messageRequested(content, level));
        switch (level) {
            case NOTIFICATIONS_ERROR:
                message.error(content);
                break;
            case NOTIFICATIONS_WARNING:
                message.warning(content);
                break;
            case NOTIFICATIONS_WARN:
                message.warn(content);
                break;
            case NOTIFICATIONS_LOADING:
                message.loading(content);
                break;
            default:
                message.info(content);
        }
    }
}