'use strict';
import { CALL_API } from '../../common/middleware/api'

export const FETCH_APPS_REQUESTED = 'yesfit/Apps/FETCH_REQUESTED';
export const FETCH_APPS_RESOLVED = 'yesfit/Apps/FETCH_RESOLVED';
export const FETCH_APPS_REJECTED = 'yesfit/Apps/FETCH_REJECTED';
export const APPS_REFRESH = 'yesfit/Apps/REFRESH';

const FETCH_APPS_API = '/api/v1/selectapps';

export const fetchApps = (id) => ({
    [CALL_API]: {
        types: [FETCH_APPS_REQUESTED, FETCH_APPS_RESOLVED, FETCH_APPS_REJECTED],
        endpoint: `${FETCH_APPS_API}/${id}`,
        method: 'GET'
    }
})

export const refreshApps = () => {
    return {
        type: APPS_REFRESH
    }
}
