'use strict';
import { CALL_API } from '../../common/middleware/api'

export const FETCH_REQUESTED = 'yesfit/SearchChallenges/FETCH_REQUESTED';
export const FETCH_RESOLVED = 'yesfit/SearchChallenges/FETCH_RESOLVED';
export const FETCH_REJECTED = 'yesfit/SearchChallenges/FETCH_REJECTED';
export const FETCH_RESET = 'yesfit/SearchChallenges/FETCH_RESET';

const API = '/api/v1/searchchallenges';

const getFilter = endpoint => ({
    [CALL_API]: {
        types: [FETCH_REQUESTED, FETCH_RESOLVED, FETCH_REJECTED],
        endpoint: endpoint,
        method: 'GET'
    }
})

export const filterChallenges = (filter) => (dispatch, getState) => {
    if (!getState().selectChallenge.isLoading) {
        return dispatch(getFilter(`${API}/Filter?filter=${filter}`));
    }
}

export const reset = () => {
    return {
        type: FETCH_RESET
    }
}
