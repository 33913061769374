var pkg = require('../../../package.json');

var env = {};

const metaKeywords = `couch to 5k,5k in miles,5k near me,half marathon training,running app,walking app,walking for weight loss,running for weight loss,half marathon training plan,half marathon training schedule,5k races near me,best running app,training for a 5k,how to train for a half marathon,running for beginners,running motivation,run tracker app,running for weight loss app,virtual 5k,wellness corporate solutions, walking for weight loss plan,best running apps for beginners,running app for beginners,virtual races with medals,disney virtual races,virtual races ${new Date().getFullYear()},virtual running races,best fitbit apps,fit,best fitness tracker App,fitness tracker app,fitness apps,best fitness apps,exercise,exercise bike,runkeeper,mapmyrun,fitbit,garmin,apple watch,apple health,30 day challenges,challenges,virtual challenges`;

if (window.location.href.includes('localhost')) {
	env = {
		'APP_ENV': 'development',
		'PLATFORM': 'web',
		'NODE_ENV': 'development',
		'API_HOST': 'http://localhost:8080',
		//'API_HOST': 'https://api.yes.fit',
		//'API_HOST': 'https://api-staging.yes.fit',
		'VERSION': pkg.version,
		'NAME': pkg.name,
		'ASSET_HOST': 'https://asset.yes.fit',
		'LEGACY_HOST': 'http://localhost:54819',
		'ADMIN_HOST': 'https://localhost:4000',
		'PIXEL_ID': '777732789059818',
		'GA_ID': 'UA-32261411-x',
		'GA_DEBUG': false,
		'GTAG_ID': 'AW-917010671/bhIMCJfQtHIQ7_GhtQM',
		'GTM_ID': 'GTM-K68XVWX',
		'STRIPE_API_KEY': 'pk_test_1Qi0tEuPNSMKCMVXvyoNZUxv',
		'GOOGLE_MAPS_API_KEY': 'AIzaSyB3k__HMXpt2VkvcjjjZnLG75Adaq5j16I',
		'PAYPAL_ENV': 'sandbox',
		'PAYPAL_CLIENT_SANDBOX': 'ATjN6_Te1IIE3Rjjw0rZCDPwsH9ij8d-DlEnbNhLr_5Pr68QoJ0qOPHpeoK_4HeNQNjxYURd9tWiPOp9',
		'PAYPAL_CLIENT_PRODUCTION': 'AcuKZHBFnxcQwt7fOAocX7jSfZb5Ue9yPoGE9mANbMDWdYr4zbCzI_LPFSeaCCrLmqa4GJUFqhxcO7-E',
		'INTERCOM_APP_ID': 'rnttpjnn',
		'EXTERNAL_SCRIPT_HOST': 'https://asset.yes.fit/scripts/',
		'ASYNC_POLL_INTERVAL': 0,
		'FEATURE_START_DATES': 'true',
		'FEATURE_TRACKER': 'false',
		'FEATURE_GROUPS': 'true',
		'FEATURE_PREFERENCES': 'true',
		'FEATURE_30DAY': 'true',
		'FEATURE_DATA_DOG': 'true',
		'LOGOUT_NEXT_ROUTE': '/',
		'LOGIN_NEXT_ROUTE': '/',
		'FROM_WEB': true,
		'META_KEYWORDS': metaKeywords,
		'WEB_NEXT_URL': 'http://localhost:3001'
	}
}

else if (window.location.href.includes('staging')) {
	//'ASSET_HOST': 'https://d2i0v7s39tajut.cloudfront.net',
	env = {	
		'APP_ENV': 'staging',
		'PLATFORM': 'web',
		'NODE_ENV': 'staging',
		'API_HOST': 'https://api-staging.yes.fit',
		'VERSION': pkg.version,
		'NAME': pkg.name,
		'ASSET_HOST': 'https://asset.yes.fit',
		'LEGACY_HOST': 'http://testmyh.com',
		'ADMIN_HOST': 'https://admin.yes.fit',
		'PIXEL_ID': '777732789059818',
		'GA_ID': 'UA-32261411-x',
		'GA_DEBUG': false,
		'GTAG_ID': 'AW-917010671/bhIMCJfQtHIQ7_GhtQM',
		'GTM_ID': 'GTM-K68XVWX',
		'STRIPE_API_KEY': 'pk_test_1Qi0tEuPNSMKCMVXvyoNZUxv',
		'GOOGLE_MAPS_API_KEY': 'AIzaSyB3k__HMXpt2VkvcjjjZnLG75Adaq5j16I',
		'PAYPAL_ENV': 'sandbox',
		'PAYPAL_CLIENT_SANDBOX': 'ATjN6_Te1IIE3Rjjw0rZCDPwsH9ij8d-DlEnbNhLr_5Pr68QoJ0qOPHpeoK_4HeNQNjxYURd9tWiPOp9',
		'PAYPAL_CLIENT_PRODUCTION': 'AcuKZHBFnxcQwt7fOAocX7jSfZb5Ue9yPoGE9mANbMDWdYr4zbCzI_LPFSeaCCrLmqa4GJUFqhxcO7-E',
		'INTERCOM_APP_ID': 'rnttpjnn',
		'EXTERNAL_SCRIPT_HOST': 'https://asset.yes.fit/scripts/',
		'ASYNC_POLL_INTERVAL': 300,
		'FEATURE_START_DATES': 'true',
		'FEATURE_TRACKER': 'false',
		'FEATURE_GROUPS': 'true',
		'FEATURE_PREFERENCES': 'true',
		'FEATURE_30DAY': 'true',
		'FEATURE_DATA_DOG': 'true',
		'LOGOUT_NEXT_ROUTE': '/home',
		'LOGIN_NEXT_ROUTE': '/home',
		'FROM_WEB': true,
		'META_KEYWORDS': metaKeywords,
		'WEB_NEXT_URL': 'https://staging.yes.fit'
	}
}

else {
	env = {
		'APP_ENV': 'production',
		'PLATFORM': 'web',
		'NODE_ENV': 'production',
		'API_HOST': 'https://api.yes.fit',
		'VERSION': pkg.version,
		'NAME': pkg.name,
		'ASSET_HOST': 'https://asset.yes.fit',
		'LEGACY_HOST': 'http://profile.yes.fit',
		'ADMIN_HOST': 'https://admin.yes.fit',
		'PIXEL_ID': '777732789059818',
		'GA_ID': 'UA-32261411-5',
		'GA_DEBUG': false,
		'GTAG_ID': 'AW-917010671/bhIMCJfQtHIQ7_GhtQM',
		'GTM_ID': 'GTM-K68XVWX',
		'STRIPE_API_KEY': 'pk_live_nw9DPaNY7bYCZ3iL55GEu6Dy',
		'GOOGLE_MAPS_API_KEY': 'AIzaSyB3k__HMXpt2VkvcjjjZnLG75Adaq5j16I',
		'PAYPAL_ENV': 'production',
		'PAYPAL_CLIENT_SANDBOX': 'ATjN6_Te1IIE3Rjjw0rZCDPwsH9ij8d-DlEnbNhLr_5Pr68QoJ0qOPHpeoK_4HeNQNjxYURd9tWiPOp9',
		'PAYPAL_CLIENT_PRODUCTION': 'AcuKZHBFnxcQwt7fOAocX7jSfZb5Ue9yPoGE9mANbMDWdYr4zbCzI_LPFSeaCCrLmqa4GJUFqhxcO7-E',
		'INTERCOM_APP_ID': 'imk2t65f',
		'EXTERNAL_SCRIPT_HOST': 'https://asset.yes.fit/scripts/',
		'ASYNC_POLL_INTERVAL': 300,
		'FEATURE_START_DATES': 'false',
		'FEATURE_TRACKER': 'false',
		'FEATURE_GROUPS': 'true',
		'FEATURE_PREFERENCES': 'true',
		'FEATURE_30DAY': 'true',
		'FEATURE_DATA_DOG': 'true',
		//'FEATURE_DATA_DOG': 'victoria.shepherd@yes.fit,aliciasparrowhawk@yahoo.com,dariel2712@gmail.com,ebitterman@tampabay.rr.com,graham@yes.fit,heatherpeterson000@gmail.com,kevin@yes.fit,kristina@yes.fit,mendoza.jafet@gmail.com,quteepi777@aol.com,run@lairdmalamed.com,ryancteneyck@yahoo.com,scotkell@hotmail.com,scott@yes.fit,thompsonself@aol.com,jim.reynolds@yes.fit,ellie@morris7.com,megan.cary@yes.fit,michael.maio22@gmail.com,ashley.vasquez@yes.fit,ROBJENNINGS34@GMAIL.COM,michael.ricciardi@yes.fit,michael.maio@yes.fit',
		'LOGOUT_NEXT_ROUTE': '/home',
		'LOGIN_NEXT_ROUTE': '/home',
		'FROM_WEB': true,
		'META_KEYWORDS': metaKeywords,
		'WEB_NEXT_URL': 'https://yes.fit'
	}
}

export default env;
