import {
	HANDLE_CART_FIELD_CHANGE,
	HANDLE_CART_OBJECT_CHANGE,
	HANDLE_CART_REWARD_FIELD_CHANGE,
	HANDLE_CART_ADDON_FIELD_CHANGE,
	FETCH_CHALLENGE_REQUESTED,
	FETCH_CHALLENGE_RESOLVED,
	FETCH_CHALLENGE_REJECTED,
	DELETE_CHALLENGE_ATHLETE_REQUESTED,
	DELETE_CHALLENGE_ATHLETE_RESOLVED,
	DELETE_CHALLENGE_ATHLETE_REJECTED,
	CHECKOUT_REQUESTED,
	CHECKOUT_RESOLVED,
	CHECKOUT_REJECTED,
	VALIDATE_VOUCHER_REQUESTED,
	VALIDATE_VOUCHER_RESOLVED,
	VALIDATE_VOUCHER_REJECTED,
	REMOVE_VOUCHER,
	VALIDATE_PROMOTIONS_RESOLVED,
	ADD_TO_BUNDLE,
	REMOVE_FROM_BUNDLE,
	CLEAR_BUNDLE,
	LOAD_BUNDLE,
	HANDLE_FIELD_CHANGE,
	SALESTAX_REQUESTED,
	SALESTAX_RESOLVED,
	SALESTAX_REJECTED
} from './actions';
import { saveBundle } from './actions';
import { RESET } from '../Steppers/actions';
import assign from 'lodash.assign';

const emptyCart = {
	challengeAthleteId: null,
	address: null,
	city: null,
	zip: null,
	stateId: null,
	items: [
	],
	vouchers: [
	],
	predefinedGiftCard: {
	},
	promotion: null,
	total: 0,
	subTotal: 0,
	token: null,
	paymentID: null,
	payerID: null,
	orderID: null,
	isApproved: false, //For PayPal
	athleteId: 0,
	isNew: false,
	authorizedAmount: 0,
	bundle: {
		bundleId: null,
		bundleUrl: null,
		paymentId: null,
		name: null,
		isLocked: false,
		voucherJson: null,
		createTS: null,
		challenges: []
	},
	initialStep: null //This is only used when a predefined bundle is loaded
};

export const initialState = {
	summary: {
		challengeId: 0,
		name: '',
		challengeUrl: '',
		description: '',
		imageUrl: '',
		price: 0.00,
		totalDistance: 0.00,
		googleDirectionsResult: '',
		challengeData: [],
		isOldStyleChallenge: false,
		discount: 0,
		fundraisingMessage: '',
		rightMediaBlock: ''
	},
	geography: {
		points: [],
		encodedPolyline: ''
	},
	rewards: [
		{
			//selected: true, //Trick the wizard into defaulting to having a shipping step
			challengeAddonOptions: [],
			challengeAddonType: {
				isShipped: true,
				isSalesTaxable: true
			},
			challengeAddonAsset: {},
			options: [
				{
				}
			]
		}
	],
	addons: [
		{
			challengeAddonAsset: {},
			options: [
				{
				}
			]
		}
	],
	cart: emptyCart,
	receipt: emptyCart,
	showReceipt: false,
	salesTax: {
		taxSales: 0.0, //Should go on the outside because it's set from a different endpoint than a challenge
		geoCounty: null,
		lastAddress: {
		}
	},
	showExtras: false,
	hasError: false,
	error: null,
	isLoading: false,
	isSaving: false,
	isResolved: false,
	variant: null, //For A/B testing. Should formalize this framework if we do one more experiment
	inNativePay: false,
	resetNextTime: false,
	zoomChallengeAddonId: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case FETCH_CHALLENGE_RESOLVED:
			var useBundleFromServer = true;
			if (state.cart.bundle.challenges.length > 0) {
				if (action.response.cart.bundle.challenges.length > 0) {
					useBundleFromServer = ((action.response.cart.bundle.paymentId !== state.cart.bundle.paymentId) ||
						(action.response.cart.transactionId !== state.cart.transactionId) ||
						(action.response.cart.bundle.bundleId !== state.cart.bundle.bundleId));
				}
				else {
					useBundleFromServer = state.cart.success === true ||
						state.cart.bundle.paymentId !== null ||
						//action.response.cart.challengeAthleteId === state.cart.challengeAthleteId ||
						state.cart.bundle.challenges.some(item => item.challengeAthleteId !== null);
				}
			}
			else {
				useBundleFromServer = true;
			}
			var new_state = assign({}, state, {
				summary: action.response.summary,
				items: action.response.items,
				rewards: action.response.rewards,
				addons: action.response.addons,
				geography: action.response.geography,
				cart: {
					...action.response.cart,
					finished: false,
					authorizedAmount: 0,
					//Only replace the bundle in state if the server returned a bundle (in the case of a prewrapped bundle or a purchase)
					bundle: useBundleFromServer ?
						action.response.cart.bundle :
						state.cart.bundle
				},
				receipt: emptyCart,
				showReceipt: false,
				hasError: false,
				isLoading: false,
				isSaving: false,
				isResolved: true,
				error: null,
				resetNextTime: false,
				initialStep: (action.response.cart.bundle.bundleId === null || action.response.cart.athleteId === null) ? null :
					action.response.cart.address === null ? 'shipping' : 'checkout'
			});
			if (new_state.cart.stateId === null) {
				new_state.cart.stateId = '';
			}
			//Look to see if the current race is part of the bundle and has add-on selections associated with it
			if (new_state.cart.bundle !== null && new_state.cart.bundle.challenges !== null) {
				const bundleChallenge = new_state.cart.bundle.challenges.find(i => i.challengeId === new_state.summary.challengeId);
				if (bundleChallenge && bundleChallenge !== null) {
					bundleChallenge.items.filter(item => item !== null).forEach(bundleItem => {
						const item = new_state.rewards.concat(new_state.addons).find(i => i.challengeAddonId === bundleItem.challengeAddonId);
						if (item && item !== null) {
							item.selected = true;
							item.challengeAddonOptionId = bundleItem.challengeAddonOptionId;
						}
					});
				};
			}
			saveBundle(new_state.cart.bundle);
			return new_state;
		case DELETE_CHALLENGE_ATHLETE_RESOLVED:
			return assign({}, state, {
				cart:
				{
					...state.cart,
					challengeAthleteId: null
				},
				hasError: false,
				isLoading: false,
				isSaving: false,
				isResolved: true,
				error: null
			});
		case SALESTAX_RESOLVED:
			return assign({}, state, {
				salesTax:
				{
					...action.response
				},
				hasError: false,
				isLoading: false,
				isSaving: false,
				isResolved: true,
				error: null
			});
		case VALIDATE_VOUCHER_RESOLVED:
			return assign({}, state, {
				cart:
				{
					...state.cart,
					vouchers: [...state.cart.vouchers, action.response.voucher]
					//vouchers: [{
					//...action.response.discount
					//...action.response.voucher
					//}]
				},
				hasError: false,
				isLoading: false,
				isSaving: false,
				isResolved: true,
				error: null
			});
		case VALIDATE_PROMOTIONS_RESOLVED:
			var new_state = assign({}, state, {
				...state,
				hasError: false,
				isLoading: false,
				isSaving: false,
				isResolved: true,
				error: null
			});
			//Filter out any existing promotion and tack on the new one
			if (action.response.voucher && action.response.voucher.id) {
				new_state.cart.vouchers = state.cart.vouchers.filter((voucher) => !voucher.isPromotion);
				if (action.response.voucher !== null && action.response.voucher.code !== null) {
					new_state.cart.vouchers = new_state.cart.vouchers.concat(action.response.voucher);
				}
			}
			return new_state;
		case REMOVE_VOUCHER:
			return assign({}, state, {
				cart:
				{
					...state.cart,
					vouchers: state.cart.vouchers.filter((voucher) => voucher.code !== action.code)
				}
			});
		case HANDLE_FIELD_CHANGE:
			var new_state = assign({}, state);
			new_state[action.key] = action.value;
			return new_state;
		case HANDLE_CART_FIELD_CHANGE:
			var new_state = assign({}, state);
			new_state.cart[action.key] = action.value;
			return new_state;
		case HANDLE_CART_OBJECT_CHANGE:
			return assign({}, state, {
				...state,
				cart: {
					...state.cart,
					...action.object
				}
			})
		case HANDLE_CART_REWARD_FIELD_CHANGE:
			return handleCartRewardFieldChange(state, action);
		case HANDLE_CART_ADDON_FIELD_CHANGE:
			return handleCartAddonFieldChange(state, action);
		case FETCH_CHALLENGE_REQUESTED:
		case DELETE_CHALLENGE_ATHLETE_REQUESTED:
		case VALIDATE_VOUCHER_REQUESTED:
		case SALESTAX_REQUESTED:
			return assign({}, state, {
				isLoading: true
			})
		case CHECKOUT_REQUESTED:
			return assign({}, state, {
				isSaving: true
			})
		case CHECKOUT_RESOLVED:
			var new_state = assign({}, state, {
				...state,
				isSaving: false,
				hasError: false,
				error: null,
				cart: emptyCart,
				receipt: {
					...state.cart,
					finished: true,
					paid: action.response.paid,
					challengeAthleteId: action.response.challengeAthleteId,
					charged: action.response.charged,
					transactionId: action.response.transactionId,
					isPaused: action.response.isPaused,
					isNew: action.response.isNew,
					challengeId: action.response.challengeId,
					athleteId: action.response.athleteId,
					userDataAPIId: action.response.userDataAPIId,
					isPaused: action.response.isPaused,
					isChallengeComplete: false,
					waiverAccepted: action.response.waiverAccepted,
					validicApp: action.response.validicApp,
					activityType: action.response.activityType,
					bundle: action.response.bundle,
					membershipIncluded: action.response.membershipIncluded,
				},
				showReceipt: true,
				resetNextTime: true //Set to true after a purchase, and it causes the wizard to reset, which we want.
			});
			/*
			if (action.response.bundle && action.response.bundle !== null) {
				new_state.cart.bundle.paymentId = action.response.bundle.paymentId;
				new_state.cart.bundle.challenges = action.response.bundle.challenges.slice();
			}
			*/
			return new_state;
		case FETCH_CHALLENGE_REJECTED:
		case CHECKOUT_REJECTED:
		case DELETE_CHALLENGE_ATHLETE_REJECTED:
		case VALIDATE_VOUCHER_REJECTED:
		case SALESTAX_REJECTED:
			return assign({}, state, {
				isLoading: false,
				isSaving: false,
				hasError: true,
				error: action.message
			})
		case ADD_TO_BUNDLE:
			//Get items without the one being added (in case it's an update)
			var challenges = state.cart.bundle.challenges.filter(item => item.key !== action.key);
			//Set the unique key
			const item = {
				...action.item,
				key: action.key
			};
			//Push the added one in
			challenges.push(item);
			var new_state = assign({}, state, {
				...state,
				cart: {
					...state.cart,
					bundle: {
						...state.cart.bundle,
						challenges: challenges
					}
				}
			});
			saveBundle(new_state.cart.bundle);
			return new_state;
		case REMOVE_FROM_BUNDLE:
			var new_state = assign({}, state, {
				...state,
				cart: {
					...state.cart,
					bundle: {
						...state.cart.bundle,
						challenges: state.cart.bundle.challenges.filter(item => item.key !== action.key)
					}
				}
			});
			saveBundle(new_state.cart.bundle);
			return new_state;
		case CLEAR_BUNDLE:
			var new_state = assign({}, state, {
				...state,
				cart: {
					...state.cart,
					bundle: initialState.cart.bundle
				}
			})
			saveBundle(new_state.cart.bundle);
			return new_state;
		case LOAD_BUNDLE:
			return assign({}, state, {
				...state,
				cart: {
					...state.cart,
					bundle: {
						...action.response
					}
				}
			});
		case RESET:
			return assign({}, state, {
				...initialState,
				cart: {
					...emptyCart,
					bundle: state.cart.bundle
				},
				variant: state.variant,
				showReceipt: false,
				salesTax: {
					//Don't overwrite sales tax
					...state.salesTax
				}
			})
		default:
			return state;
	}
}

function handleCartRewardFieldChange(state, action) {
	var new_state = assign({}, state);
	new_state.rewards[action.index][action.key] = action.value;
	return new_state;
}

function handleCartAddonFieldChange(state, action) {
	var new_state = assign({}, state);
	new_state.addons[action.index][action.key] = action.value;
	return new_state;
}
