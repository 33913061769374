import {
	QUEUE_ORDER_REQUESTED,
	QUEUE_ORDER_RESOLVED,
	QUEUE_ORDER_REJECTED,
	HANDLE_FIELD_CHANGE
} from './actions';
import assign from 'lodash.assign';

const initialState = {
	hasError: false,
	error: null,
	visible: false,
	queueOrder: [],
	isSaving: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case QUEUE_ORDER_REJECTED:
			return assign({}, state, {
				isLoading: false,
				hasError: true,
				error: action.error,
				isSaving: false
			})
		case QUEUE_ORDER_REQUESTED:
			return assign({}, state, {
				isSaving: true
			})
		case QUEUE_ORDER_RESOLVED:
			return assign({}, state, {
				visible: false,
				isSaving: false
			})
		case HANDLE_FIELD_CHANGE:
			var new_state = assign({}, state);
			new_state[action.key] = action.value;
			return new_state;
		default:
			return state;
	}
}
