import {
    HANDLE_FIELD_CHANGE,
    FETCH_REQUESTED,
    FETCH_RESOLVED,
    FETCH_REJECTED,
    TOGGLE_TEAM_VIEW,
    ON_SELECT_CHALLENGE
} from './actions';
import assign from 'lodash.assign';

const initialState = {
    isCollapsed: true,
    challengeAthleteId: 0,
    userName: null,
    noChallenges: false,
    rememberLastChallenge: false,
    myDashboard: true,
    allowAccess: false,
    currentTabIndex: '1',
    hasError: false,
    error: null,
    isLoading: false,
    isSaving: false,
    isLoaded: false,
    teamView: false,
    currentGroupId: null //Used initially by NativeRaceDetails
};

export default (state = initialState, action) => {
    switch (action.type) {
        case HANDLE_FIELD_CHANGE:
            var new_state = assign({}, state);
            new_state[action.key] = action.value;
            return new_state;
        case FETCH_REQUESTED:
            return assign({}, state, {
                isLoading: true,
                isLoaded: false
            })
        case ON_SELECT_CHALLENGE:
            return assign({}, state, {
                ...state,
                challengeAthleteId: action.challengeAthleteId
            })
        case FETCH_RESOLVED:
            return assign({}, state, {
                isLoading: false,
                error: null,
                isLoaded: true,
                allowAccess: action.response
            })
        case FETCH_REJECTED:
            return assign({}, state, {
                isLoading: false,
                hasError: true,
                isLoaded: true,
                error: action.error,
                allowAccess: false
            })
        case TOGGLE_TEAM_VIEW:
            return assign({}, state, {
                teamView: action.value
            })
        default:
            return state;
    }
}
