import TagManager from 'react-gtm-module';

export function tagsSale(response, challenge, shipping, total) {
	const skusForGTM = new Array();

	response.bundle.challenges.map(challenge => {
		const challengesGTM = {};

		challengesGTM.id = challenge.challengeId;
		challengesGTM.name = challenge.name;
		challengesGTM.category =  'Virtual Race';
		challengesGTM.price = challenge.price;
		challengesGTM.quantity =  '1';
		challengesGTM.brand = 'YES.FIT';

		if (challenge.items.length >= 1) {
			for (let i = 0; i < challenge.items.length; i++) {
				let itemGTM = {};
				
				itemGTM.id = challenge.items[i].challengeAddonId;
				itemGTM.name = challenge.items[i].description;
				itemGTM.category = challenge.items[i].challengeAddonType.name;
				itemGTM.price = challenge.items[i].itemPrice;
				itemGTM.quantity = '1';
				itemGTM.brand = 'YES.FIT';

				skusForGTM.push(itemGTM);
			}
		}
		skusForGTM.push(challengesGTM);
	});	

	TagManager.dataLayer({
		dataLayer: {
			event: 'purchase',
			price: total,
			currency: 'USD',
			itemIds: [`${response.challengeId}`],
			ecommerce: {
				purchase: {
					actionField: {
						id: response.transactionId,
						affiliation: 'YES.FIT',
						revenue: response.charged,
					},
					products: skusForGTM
				}
			}
		}
	});
}

export function tagsFetchChallenge(response) {
	TagManager.dataLayer({
		dataLayer: {
			event: 'view_item',
			price: response.summary.price,
			currency: 'USD',
			itemIds: [`${response.summary.challengeId}`],
			ecommerce: {
				detail: {
					products: [{
						id: response.summary.challengeId,
						name: response.summary.name,
						brand: 'YES.FIT',
						category: 'Virtual Race',
						quantity: '1',
						price: response.summary.minimumPrice
					}]
				}
			}
		}
	});
}

export function tagsRegistration(response) {
	TagManager.dataLayer({
		dataLayer: {
			event: 'sign_up',
			method: 'email',
			userId: response.userId
		}
	});
}

export function tagsAddToCart(challenge) {
	TagManager.dataLayer({
		dataLayer: {
			event: 'add_to_cart',
			price: challenge.price,
			currency: 'USD',
			itemIds: [`${challenge.challengeId}`],
			ecommerce: {
				currencyCode: 'USD',
				add: {
					products: [{
						name: challenge.name,
						id: challenge.challengeId,
						price: challenge.minimumPrice,
						brand: 'YES.FIT',
						category: 'Virtual Race',
						quantity: '1'
					}]
				}
			}
		}
	});
}

export function tagsAbandonCart(challenges) {

	const skusForGTM = new Array();

	challenges.map(challenge => {
		const challengesGTM = {};

		challengesGTM.sku = challenge.challengeId;
		challengesGTM.name = challenge.name;
		challengesGTM.category = 'Virtual Race';
		challengesGTM.price = challenge.price;
		challengesGTM.quantity = '1';

		if (challenge.items.length >= 1) {
			for (let i = 0; i < challenge.items.length; i++) {
				let itemGTM = {};

				itemGTM.sku = challenge.items[i].challengeAddonId;
				itemGTM.name = challenge.items[i].description;
				itemGTM.category = challenge.items[i].challengeAddonType.name;
				itemGTM.price = challenge.items[i].itemPrice;
				itemGTM.quantity = '1';

				skusForGTM.push(itemGTM);
			}
		}
		skusForGTM.push(challengesGTM);
	});	

	TagManager.dataLayer({
		dataLayer: {
			event: 'remove_from_cart',
			ecommerce: {
				remove: { 
					products: skusForGTM
				}
			}
		}
	});
}

export function tagsInitiateJoinRace(challenge) {
	TagManager.dataLayer({
    dataLayer: {
			event: 'begin_checkout',
			value: challenge.price,
			itemIds: [`${challenge.challengeId}`],
			currency: 'USD',
			quantity: '1',
			category: 'Virtual Race',
			ecommerce: {
				checkout_option: {
					actionField: {
						step: 'begin_checkout'
					},
					products: [{
						name: challenge.name,
						id: challenge.challengeId,
						price: challenge.price,
						brand: 'YES.FIT',
						category: 'Virtual Race',
						quantity: '1'
					}]
				}
			}
		}
	});
}