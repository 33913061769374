"use strict";
import { CALL_API } from "../../common/middleware/api";

export const FETCH_ATHLETE_APPS_REQUESTED = "yesfit/AthleteApps/FETCH_REQUESTED";
export const FETCH_ATHLETE_APPS_RESOLVED = "yesfit/AthleteApps/FETCH_RESOLVED";
export const FETCH_ATHLETE_APPS_REJECTED = "yesfit/AthleteApps/FETCH_REJECTED";
export const HANDLE_FIELD_CHANGE = "yesfit/AthleteApps/HANDLE_FIELD_CHANGE";
export const ATHLETE_APPS_FRESH_REFRESH = "yesfit/AthleteApps/ATHLETE_APPS_FRESH_REFRESH";
export const NATIVE_APPS_RESOLVED = "yesfit/AthleteApps/NATIVE_APPS_RESOLVED";

const FETCH_ATHLETE_APPS_API = "/api/v1/athleteapps";

export const fetchApps = (id) => ({
	[CALL_API]: {
		types: [FETCH_ATHLETE_APPS_REQUESTED, FETCH_ATHLETE_APPS_RESOLVED, FETCH_ATHLETE_APPS_REJECTED],
		endpoint: `${FETCH_ATHLETE_APPS_API}/${id}`,
		method: "GET"
	},
});

export const postApps = (id) => ({
	[CALL_API]: {
		types: [FETCH_ATHLETE_APPS_REQUESTED, FETCH_ATHLETE_APPS_RESOLVED, FETCH_ATHLETE_APPS_REJECTED],
		endpoint: `${FETCH_ATHLETE_APPS_API}/${id}`,
		method: "POST"
	},
});

export const handleAthleteAppFieldChange = (key, value) => {
	return {
		type: HANDLE_FIELD_CHANGE,
		key: key,
		value: value
	};
};

export const handleNativeApp = (value) => {
	return {
		type: NATIVE_APPS_RESOLVED,
		nativeApp: value
	};
};

export const refreshApps = () => {
	return {
		type: ATHLETE_APPS_FRESH_REFRESH
	};
};

export const launchMarketplace = (url) => (dispatch) => {
	dispatch(handleAthleteAppFieldChange("freshConnections", true));
	window.open(url, "_blank");
};
