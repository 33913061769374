import {
    FETCH_REQUESTED,
    FETCH_RESOLVED,
    FETCH_REJECTED,
    URL_REQUESTED,
    URL_RESOLVED,
    URL_REJECTED,
    POST_REQUESTED,
    POST_RESOLVED,
    POST_REJECTED,
    DELETE_REQUESTED,
    DELETE_RESOLVED,
    DELETE_REJECTED,
    HANDLE_FIELD_CHANGE
} from './actions';
import assign from 'lodash.assign';

const initialState = {
    data: {
        lastQueryTS: null,
        synced: false
    },
    hasError: false,
    error: null,
    isBusy: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case POST_RESOLVED:
        case FETCH_RESOLVED:
            return assign({}, state, {
                data: action.response.stravaCheck,
                hasError: false,
                isBusy: false,
                error: null
            });
        case DELETE_RESOLVED:
            return assign({}, state, {
                ...initialState
            });
        case FETCH_REQUESTED:
        case DELETE_REQUESTED:
        case POST_REQUESTED:
        case URL_REQUESTED:
            return assign({}, state, {
                isBusy: true
            })
        case DELETE_REJECTED:
        case POST_REJECTED:
        case FETCH_REJECTED:
        case URL_REJECTED:
            return assign({}, state, {
                isBusy: false,
                hasError: true,
                error: action.message
            })
        case HANDLE_FIELD_CHANGE:
            var new_state = assign({}, state);
            new_state[action.key] = action.value;
            return new_state;
        case URL_RESOLVED:
            var new_state = assign({}, state);
            return new_state;
        default:
            return state;
    }
}