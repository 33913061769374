'use strict';
import { CALL_API } from '../../common/middleware/api'

export const FETCH_CAS_REQUESTED = 'yesfit/CAS/FETCH_REQUESTED';
export const FETCH_CAS_RESOLVED = 'yesfit/CAS/FETCH_RESOLVED';
export const FETCH_CAS_REJECTED = 'yesfit/CAS/FETCH_REJECTED';
export const POST_CAS_REQUESTED = 'yesfit/CAS/POST_REQUESTED';
export const POST_CAS_RESOLVED = 'yesfit/CAS/POST_RESOLVED';
export const POST_CAS_REJECTED = 'yesfit/CAS/POST_REJECTED';
export const POST_CARI_REQUESTED = 'yesfit/CARI/POST_REQUESTED';
export const POST_CARI_RESOLVED = 'yesfit/CARI/POST_RESOLVED';
export const POST_CARI_REJECTED = 'yesfit/CARI/POST_REJECTED';
export const DELETE_CAS_REQUESTED = 'yesfit/CAS/DELETE_REQUESTED';
export const DELETE_CAS_RESOLVED = 'yesfit/CAS/DELETE_RESOLVED';
export const DELETE_CAS_REJECTED = 'yesfit/CAS/DELETE_REJECTED';
export const HANDLE_FIELD_CHANGE = 'yesfit/CAS/HANDLE_FIELD_CHANGE';

export const CF_API = '/api/v1/challengeFriends';
const CARI_API = '/api/v1/athleteInvite';

export const fetchChallengeFriends = (cid, aid) => ({
    [CALL_API]: {
        types: [FETCH_CAS_REQUESTED, FETCH_CAS_RESOLVED, FETCH_CAS_REJECTED],
        endpoint: `${CF_API}/${cid}/${aid}`,
        method: 'GET'
    }
})

export const postChallengeFriends = (body) => ({
    [CALL_API]: {
        types: [POST_CAS_REQUESTED, POST_CAS_RESOLVED, POST_CAS_REJECTED],
        endpoint: CF_API,
        method: 'POST',
        body: body
    }
})

export const postChallengeRequestInvite = (body) => ({
    [CALL_API]: {
        types: [POST_CARI_REQUESTED, POST_CARI_RESOLVED, POST_CARI_REJECTED],
        endpoint: CARI_API,
        method: 'POST',
        body: body
    }
})

export const deleteChallengeFriend = (athleteConnectChallengeId) => ({
    [CALL_API]: {
        types: [DELETE_CAS_REQUESTED, DELETE_CAS_RESOLVED, DELETE_CAS_REJECTED],
        endpoint: `${CF_API}/${athleteConnectChallengeId}`,
        method: 'DELETE'
    }
})

export function handleFieldChange(key, value) {
    return {
        type: HANDLE_FIELD_CHANGE,
        key: key,
		value: value
    };
}
