import {
	FETCH_CHALLENGE_HISTORY_REQUESTED,
	FETCH_CHALLENGE_HISTORY_RESOLVED,
	FETCH_CHALLENGE_HISTORY_REJECTED,
	HANDLE_REFRESH,
	HANDLE_FIELD_CHANGE,
	TOGGLE_SECTION_VERTICAL
} from './actions';
import { POST_RESOLVED } from '../ChallengeAthlete/actions';
import { POST_WORKOUT_RESOLVED, DELETE_WORKOUT_RESOLVED } from '../Workouts/actions';
import { CHECKOUT_RESOLVED } from '../JoinRace/actions';
import { LOGIN_RESOLVED, LOGOUT } from '../Login/actions';
import { DASHBOARD_REFRESH, ON_SELECT_CHALLENGE } from '../Dashboard/actions';
import { QUEUE_ORDER_RESOLVED } from '../QueueOrder/actions';
import { POST_RESOLVED as POST_TC_RESOLVED } from '../TileChallenge/actions';
import assign from 'lodash.assign';

const initialState = {
	history: [{ challengeAthleteId: 0, title: '' }],
	challengeAthleteId: 0,
	challengeIndex: 0, //The index in the array of the selected challenge, for Native scrolling to it
	hasError: false,
	error: null,
	isLoading: false,
	isResolved: false,
	refresh: false,
	isLoaded: false,
	vertical: false,
	sectionVertical: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case FETCH_CHALLENGE_HISTORY_RESOLVED:
			return assign({}, state, {
				history: action.response,
				queueOrder: [],
				// challengeAthleteId: action.response[0].challengeAthleteId,
				hasError: false,
				isLoading: false,
				isResolved: true,
				error: null,
				refresh: false,
				isLoaded: true,
				challengeHistory: 0
			});
		case FETCH_CHALLENGE_HISTORY_REQUESTED:
			return assign({}, state, {
				isResolved: false,
				isLoading: true,
				refresh: false
			})
		case FETCH_CHALLENGE_HISTORY_REJECTED:
			return assign({}, state, {
				isLoading: false,
				hasError: true,
				error: action.error,
				refresh: false
			})
		case POST_RESOLVED:
			return assign({}, state, {
				history: state.history.map(
					(item) => item.challengeAthleteId === action.response.challengeAthleteId ? {
						...item,
						waiverAccepted: action.response.waiverAccepted,
						isPaused: action.response.isPaused,
						validicApp: action.response.validicApp
					}
						: item
				)
			});
		case POST_WORKOUT_RESOLVED:
		case QUEUE_ORDER_RESOLVED:
			return assign({}, state, {
				refresh: true
			})
		case POST_TC_RESOLVED:
		case DASHBOARD_REFRESH:
		case DELETE_WORKOUT_RESOLVED:
		case LOGIN_RESOLVED:
		case LOGOUT:
		case CHECKOUT_RESOLVED:
		case HANDLE_REFRESH:
		case POST_RESOLVED:
			return assign({}, state, {
				refresh: true
			})
		case HANDLE_FIELD_CHANGE:
			var new_state = assign({}, state);
			new_state[action.key] = action.value;
			return new_state;
		case ON_SELECT_CHALLENGE:
			return assign({}, state, {
				...state,
				challengeIndex: state.history.findIndex(item => item.challengeAthleteId === action.challengeAthleteId)
			})
		case TOGGLE_SECTION_VERTICAL:
			var new_state = assign({}, state);
			new_state.sectionVertical[action.section.id] = typeof state.sectionVertical[action.section.id] === undefined ? true : !state.sectionVertical[action.section.id]
			return new_state;
		default:
			return state;
	}
}
