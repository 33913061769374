'use strict';
import { CALL_API } from '../../common/middleware/api'
import { handleProfileObjectChange } from '../Login/actions';
import { showMessage } from '../../common/notifications/actions';
import * as n from '../../common/notifications/constants';
import { push } from '../../common/actions/navigate';

export const FETCH_REQUESTED = 'yesfit/AS/FETCH_REQUESTED';
export const FETCH_RESOLVED = 'yesfit/AS/FETCH_RESOLVED';
export const FETCH_REJECTED = 'yesfit/AS/FETCH_REJECTED';
export const POST_FRIEND_CONNECT_REQUESTED = 'yesFit/AS/POST_FRIEND_CONNECT_REQUESTED';
export const POST_FRIEND_CONNECT_RESOLVED = 'yesFit/AS/POST_FRIEND_CONNECT_RESOLVED';
export const POST_FRIEND_CONNECT_REJECTED = 'yesFit/AS/POST_FRIEND_CONNECT_REJECTED';
export const POST_FACEBOOK_CONNECT_REQUESTED = 'yesFit/AS/POST_FRIEND_FACEBOOK_REQUESTED';
export const POST_FACEBOOK_CONNECT_RESOLVED = 'yesFit/AS/POST_FRIEND_FACEBOOK_RESOLVED';
export const POST_FACEBOOK_CONNECT_REJECTED = 'yesFit/AS/POST_FRIEND_FACEBOOK_REJECTED';
export const SELECTED_ATHLETE_ID = 'yesFit/AS/SELECTED_ATHLETE_ID';
export const SELECTED_GROUP_ID = 'yesFit/AS/SELECTED_GROUP_ID';
export const HANDLE_FIELD_CHANGE = 'yesFit/AS/HANDLE_FIELD_CHANGE';
export const CONNECT_REQUESTED = 'yesfit/AS/CONNECT_REQUESTED';
export const CONNECT_RESOLVED = 'yesfit/AS/CONNECT_RESOLVED';
export const CONNECT_REJECTED = 'yesfit/AS/CONNECT_REJECTED';
export const INVITE_REQUESTED = 'yesfit/AS/INVITE_REQUESTED';
export const INVITE_RESOLVED = 'yesfit/AS/INVITE_RESOLVED';
export const INVITE_REJECTED = 'yesfit/AS/INVITE_REJECTED';
export const ISPRIVATE_REQUESTED = 'yesfit/AS/ISPRIVATE_REQUESTED';
export const ISPRIVATE_RESOLVED = 'yesfit/AS/ISPRIVATE_RESOLVED';
export const ISPRIVATE_REJECTED = 'yesfit/AS/ISPRIVATE_REJECTED';

import { GA_API } from '../Groups/Athletes/actions';
const FC_API = '/api/v1/athleteSocial';
const APC_API = '/api/v1/athleteProfileConnect';
const AIC_API = '/api/v1/athleteInvite';

const fetchFriends = (endpoint) => ({
    [CALL_API]: {
        types: [FETCH_REQUESTED, FETCH_RESOLVED, FETCH_REJECTED],
        endpoint: endpoint,
        method: 'GET',
        onResolved: fetchFriendsResolved
    }
})

export const loadFriends = (userName, userId, accessToken) => (dispatch) => {
    return dispatch(fetchFriends(`${FC_API}/${userName}/${userId}/${encodeURIComponent(accessToken)}`));
}

//This is somewhat new: sharing this method with two different physical end-points and body structures.
//This is because we want to share all the actions and onResolved/onRejected methods, so why not?
const postFriendConnect = (endpoint, body) => ({
    [CALL_API]: {
        types: [POST_FRIEND_CONNECT_REQUESTED, POST_FRIEND_CONNECT_RESOLVED, POST_FRIEND_CONNECT_REJECTED],
        endpoint: endpoint,
        method: 'POST',
        body: body,
        onResolved: postFriendConnectResolved,
        onRejected: postFriendConnectRejected
    }
})

export const saveFriendConnect = (friendConnect) => (dispatch) => {
    return dispatch(postFriendConnect(FC_API, friendConnect))
}

export const saveGroupConnect = (groupConnect) => (dispatch) => {
    return dispatch(postFriendConnect(GA_API, groupConnect))
}

const postFacebookConnect = (endpoint) => ({
    [CALL_API]: {
        types: [POST_FACEBOOK_CONNECT_REQUESTED, POST_FACEBOOK_CONNECT_RESOLVED, POST_FACEBOOK_CONNECT_REJECTED],
        endpoint: endpoint,
        method: 'POST'
    }
})

const postAthleteProfileConnect = (endpoint, body) => ({
    [CALL_API]: {
        types: [CONNECT_REQUESTED, CONNECT_RESOLVED, CONNECT_REJECTED],
        endpoint: endpoint,
        method: 'POST',
        body: body,
        onResolved: connectAthleteProfileResolved
    }
})

const postAthleteInviteConnect = (endpoint, body) => ({
    [CALL_API]: {
        types: [INVITE_REQUESTED, INVITE_RESOLVED, INVITE_REJECTED],
        endpoint: endpoint,
        method: 'POST',
        body: body,
        onResolved: postAthleteInviteConnectResolved
    }
})

export const setIsPrivate = (userId, isPrivate) => ({
    [CALL_API]: {
        types: [ISPRIVATE_REQUESTED, ISPRIVATE_RESOLVED, ISPRIVATE_REJECTED],
        endpoint: FC_API,
        body: { userId: userId, isPrivate: isPrivate },
        method: 'PUT'
    }
})

const postAthleteInviteConnectResolved = () => (dispatch) => {
    return dispatch(showMessage('Invitation has been sent', n.NOTIFICATIONS_INFO));
}

const fetchFriendsResolved = () => (dispatch, getState) => {
    var state = getState();
    if (state.athleteSocial.afterRefresh && state.athleteSocial.afterRefresh !== null) {
        state.athleteSocial.afterRefresh();
    }
}

const postFriendConnectResolved = () => (dispatch, getState) => {
    var state = getState();
    if (state.athleteSocial.afterRefresh) {
        state.athleteSocial.afterRefresh();
    }
    return dispatch(showMessage('Success', n.NOTIFICATIONS_INFO));
}

const postFriendConnectRejected = (response) => (dispatch) => {
    return dispatch(showMessage(response, n.NOTIFICATIONS_ERROR));
}

export const saveFacebookConnect = (athleteId, toAthleteId) => (dispatch) => {
    return dispatch(postFacebookConnect(`${FC_API}/${athleteId}/${toAthleteId}`));
}

export const saveAthleteInvite = (email) => (dispatch) => {
    return dispatch(postAthleteInviteConnect(`${AIC_API}/${encodeURIComponent(email)}`));
}

export const handleSelectedAthlete = (athleteId) => {
    return {
        type: SELECTED_ATHLETE_ID,
        athleteId: athleteId
    }
}

export const handleSelectedGroup = (groupId) => {
    return {
        type: SELECTED_GROUP_ID,
        groupId: groupId
    }
}

export const handleFieldChange = (key, value) => {
    return {
        type: HANDLE_FIELD_CHANGE,
        key: key,
        value: value
    }
}

export const connectAthleteProfile = (fbid, accessToken, userId) => (dispatch) => {
    var newUser = {
        userId: userId,
        facebookUserId: fbid,
        fbAccessToken: accessToken
    };
    return dispatch(postAthleteProfileConnect(APC_API, newUser));
}

const connectAthleteProfileResolved = (response) => (dispatch) => {
    dispatch(handleProfileObjectChange({ facebookUserId: response.facebookUserId, fbAccessToken: response.fbAccessToken, isFacebookUser: true }));
    dispatch(showMessage('You have successfully connected your Yes.Fit profile to Facebook', n.NOTIFICATIONS_INFO));
}

export const changeUserName = (item) => (dispatch, getState) => {
    if (item === null) {
        return dispatch(handleFieldChange('userName', null));
    }
    else if (item.userName !== null && !item.isGroup) {
        return dispatch(handleFieldChange('userName', item.userName));
    }
    else if (item.isGroup) {
        if (getState().athleteSocial.isMe || !item.isPrivate) {
            return dispatch(push('/groups', { groupId: item.groupId }));
        }
    }
}

export const goToSocialProfile = (userName) => dispatch => {
    //called from outside this component because there's no parameter on the route for a push
    dispatch(push('/social'));
    dispatch(handleFieldChange('userName', userName));
}
