import { createSelector } from 'reselect'
import numeral from 'numeral';

const allItemsSelector = state => state.challenge.rewards.concat(state.challenge.addons);

export const itemSelector = createSelector(
	allItemsSelector,
	(items) => items.filter((item) => item.selected)
);

export const cartSelector = createSelector(
	state => state.challenge.cart,
	state => state.challenge.receipt, //Receipt is an exact copy of cart and is populated right after purchase
	state => state.challenge.showReceipt,
	(cart, receipt, showReceipt) => !showReceipt ? cart : receipt
);

const salesTaxRate = state => state.challenge.salesTax.taxSales;

const paymentAmountSelector = createSelector(
	cartSelector,
	(cart) => !isNaN(cart.paymentAmount) ? cart.paymentAmount : 0
);

/*
Put this back in after a while, when everyone's cart has the correct structure in it, as item.challengeAddonType is undefined
for existing cart items.
const bundleSelector = createSelector(
	cartSelector,
	(cart) => cart.bundle.challenges
);
*/

const bundleSelector = createSelector(
	cartSelector,
	(cart) => {
		//This way of getting the bundle challenges fixes challengeAddonType in cart items that have been saved previously.
		//We just initialize the challengeAddonType with fields that we use in this selector.
		var challenges = [];
		cart.bundle.challenges.forEach(challenge => {
			challenges.push(challenge);
			challenge.items.forEach(item => {
				if (!item.challengeAddonType) {
					var challengeAddonType = {};
					switch (item.itemType) {
						case 1: challengeAddonType = { challengeAddonTypeId: 1, isSalesTaxable: false, isDiscountable: true, isReward: true, isShipped: true }; break;
						case 2: challengeAddonType = { challengeAddonTypeId: 2, isSalesTaxable: false, isDiscountable: true, isReward: true, isShipped: true }; break;
						case 3: challengeAddonType = { challengeAddonTypeId: 3, isSalesTaxable: true, isDiscountable: true, isReward: false, isShipped: true }; break;
						case 4: challengeAddonType = { challengeAddonTypeId: 4, isSalesTaxable: false, isDiscountable: false, isReward: false, isShipped: false }; break;
						case 5: challengeAddonType = { challengeAddonTypeId: 5, isSalesTaxable: true, isDiscountable: true, isReward: false, isShipped: true }; break;
					}
					item.challengeAddonType = challengeAddonType;
				}
			});
		});
		return challenges;
	}
);

//For zooming into an image image
export const zoomItemSelector = createSelector(
	state => state.challenge.zoomChallengeAddonId,
	allItemsSelector,
	(challengeAddonId, items) => items.find(item => item.challengeAddonId === challengeAddonId)
)

//Return all challenges in the bundle that have items selected that require shipping
export const challengesWithItemsSelector = createSelector(
	bundleSelector,
	(bundleChallenges) => bundleChallenges.filter(challenge => challenge.items.filter(item => item.challengeAddonType.isShipped).length > 0)
);

//Return all challenges in the bundle that have items selected that should have sales tax applied
export const challengesSalesTaxableSelector = createSelector(
	bundleSelector,
	(bundleChallenges) => bundleChallenges.filter(challenge => challenge.isSwag || challenge.items.filter(item => item.challengeAddonType.isSalesTaxable).length > 0)
);

//Return the count of challenges in the bundle that have items selected that require shipping
const challengesWithItemsCountSelector = createSelector(
	challengesWithItemsSelector,
	(bundleChallenges) => bundleChallenges.length
);

//Create a bundle that already contains item discounts, to save code
//itemType: 1 - Shirt, 2 - Medal, 3 - Addon, 4 -Donation
const bundleChallengesSelector = createSelector(
	bundleSelector,
	salesTaxRate,
	(bundleChallenges, salesTaxRate) => bundleChallenges.map((summary) => ({
		...summary,
		discount: (summary.items.filter(item => item.challengeAddonType.isReward).length > 1 && summary.addonDiscount > 0) ?
			(summary.items.filter(item => item.challengeAddonType.isReward).reduce((acc, item) => acc + (item !== null ? item.itemPrice : 0), 0)) * (summary.addonDiscount / 100) :
			0,
		//Remove the first item for paid races because it's included in the price. This is here for convenience so that we
		//don't have to continually add it up
		itemTotal: summary.items
			.filter((item, i) => (i > 0 || summary.price === 0) && item.challengeAddonType.isDiscountable)
			.reduce((acc, item) => acc + (item !== null ? item.itemPrice : 0), 0),
		//Donation amounts shouldn't be included in any voucher or promotion
		donationTotal: summary.items
			.filter(item => !item.challengeAddonType.isDiscountable)
			.reduce((acc, item) => acc + (item !== null ? item.itemPrice : 0), 0),
		//Calculate the sales tax for a challenge. Should be applied if this is swag, or if the selected items themselves are marked as such
		salesTaxTotal: summary.items
			.filter(item => (summary.isSwag || item.challengeAddonType.isSalesTaxable))
			.reduce((acc, item) => acc + (item.itemPrice * salesTaxRate), 0),
		salesTaxable: summary.items
			.filter(item => (summary.isSwag || item.challengeAddonType.isSalesTaxable))
			.reduce((acc, item) => acc + item.itemPrice, 0)
	}))
);

//Send this to the server so we know how much was sales taxable
export const salesTaxableSelector = createSelector(
	bundleChallengesSelector,
	(bundleChallenges) => bundleChallenges.reduce((acc, summary) => acc + summary.salesTaxable, 0)
);

const challengePriceSelector = createSelector(
	bundleSelector,
	(bundleChallenges) => bundleChallenges.reduce((acc, summary) => acc + summary.price, 0)
);

//Calculate the total amount of all the items, minus sales tax if applicable.
//This will be used when calculating the payment amount
//Note, summary.itemTotal contains the total of all items in a challenge, minus the first one if the challenge has a price. Therefore, all isSwag challenges
//should have a Price of 0 in order for these selectors to do everything right.
const itemTotalSelector = createSelector(
	bundleChallengesSelector,
	(bundleChallenges) => bundleChallenges.reduce((acc, summary) => acc + summary.itemTotal + summary.salesTaxTotal, 0)
)

//Donations not included in any promotions or vouchers, so the total of all donations is calculated separately
const donationTotalSelector = createSelector(
	bundleChallengesSelector,
	(bundleChallenges) => bundleChallenges.reduce((acc, summary) => acc + summary.donationTotal, 0)
)

//Calculate the amount of sales tax that will be applied. This will be shown in the cart, plus sent back to the API as part of the cart object.
//This will not be used when calculating the payment amount, as it's already handled in itemTotalSelector. This is for saving the amount
//separately on the ChallengeAthlete/Payment tables and for showing in the cart how much is being added.
export const salesTaxTotalSelector = createSelector(
	bundleChallengesSelector,
	(bundleChallenges) => bundleChallenges.reduce((acc, summary) => acc + summary.salesTaxTotal, 0)
)

const internationalSelector = createSelector(
	cartSelector,
	state => state.challenge.cart.stateId, //Force this selector to run
	(cart, _) => (cart.stateId >= 52 && cart.stateId <= 60) || cart.stateId > 66
)

export const discountSelector = createSelector(
	bundleChallengesSelector,
	(bundle) => bundle.reduce((acc, summary) => acc + summary.discount, 0)
)

export const shippingSelector = createSelector(
	internationalSelector,
	cartSelector,
	challengesWithItemsCountSelector,
	(isInternational, cart, count) => isInternational ? (cart.internationalShippingRate * count) : 0
)

export const subTotalSelector = createSelector(
	challengePriceSelector,
	itemTotalSelector,
	(challengePrice, itemTotal) => challengePrice + itemTotal
)

export const vouchersSelector = createSelector(
	cartSelector,
	(cart) => cart.vouchers.filter((voucher) => !voucher.isPromotion || voucher.isPromotion === false)
)

export const promotionSelector = createSelector(
	cartSelector,
	state => state.challenge.cart.vouchers, //Without this here, this selector doesn't fire all the time
	(cart, _) => cart.vouchers.find((voucher) => voucher.isPromotion)
)

export const totalPrePromotionSelector = createSelector(
	subTotalSelector,
	discountSelector,
	paymentAmountSelector,
	(subTotal, discount, alreadyPaid) => +((subTotal - discount - alreadyPaid).toFixed(2))
)

export const totalpreShippingSelector = createSelector(
	totalPrePromotionSelector,
	promotionSelector,
	vouchersSelector,
	(subTotal, promotion, vouchers) => {
		var total = subTotal;
		if (typeof promotion !== 'undefined' && promotion !== null) {
			total -= promotion.discount_amount / 100;
		}
		if (vouchers !== null && vouchers.length > 0) {
			[...vouchers].map((voucher) => {
				if (voucher.discount_amount !== null) {
					//This should always be the case going forward
					total -= voucher.discount_amount / 100;
				}
				else if (voucher.isAmountOff) {
					total -= voucher.amount_off / 100;
				}
				else if (voucher.isPercentOff) {
					total -= ((total * voucher.percent_off) / 100);
				}
				else if (voucher.isUnitOff) {
					total = 0;
				}
			})
		}
		return total;
	}
)

export const totalSelector = createSelector(
	totalpreShippingSelector,
	shippingSelector,
	donationTotalSelector,
	(total, shipping, donations) => +((total + shipping + donations).toFixed(2))
)

//Cart items. Put everything in an array so the cart can be shown really easily

const cartVoucherSelector = createSelector(
	vouchersSelector,
	(vouchers) => vouchers.map((voucher) => {
		const description = voucher.isGift ? ' Gift Voucher' : ' Promo Code'
		if (voucher.discount_amount !== null) {
			return {
				thumbnail: '<span />',
				description: voucher.code + description,
				amount: `(${numeral(voucher.discount_amount / 100).format('$0[.]00')})`,
				icon: 'like-o'
			};
		}
		else if (voucher.isAmountOff) {
			return {
				thumbnail: '<span />',
				description: voucher.code + description,
				amount: `(${numeral(voucher.amount_off / 100).format('$0[.]00')})`,
				icon: 'like-o'
			};
		}
		else if (voucher.isPercentOff) {
			return {
				thumbnail: '<span />',
				description: voucher.code + description,
				amount: numeral(voucher.percent_off).format('0[.]00%'),
				icon: 'like-o'
			};
		}
		else if (voucher.isUnitOff) {
			return {
				thumbnail: '<span />',
				description: voucher.code + description,
				amount: 'FREE!',
				icon: 'like-o'
			};
		}
		else
			return [];
	})
)

const cartPromotionSelector = createSelector(
	promotionSelector,
	(promotion) => {
		if (typeof promotion !== 'undefined' && promotion !== null) {
			return {
				thumbnail: '<span />',
				description: promotion.banner,
				amount: `(${numeral(promotion.discount_amount / 100).format('$0[.]00')})`,
				icon: 'like-o'
			};
		}
		else
			return null;
	}
)

const cartSalesTaxSelector = createSelector(
	salesTaxTotalSelector,
	salesTaxRate,
	(salesTaxTotal, salesTaxRate) => {
		if (salesTaxTotal > 0)
			return [{
				thumbnail: '<span />',
				description: `Sales Tax (${numeral(salesTaxRate).format('0[.]0[0]%')})`,
				amount: numeral(salesTaxTotal).format('$0[.]00'),
				icon: 'money-collect'
			}];
		else
			return [];
	}
)

const cartPaymentAmountSelector = createSelector(
	paymentAmountSelector,
	(paymentAmount) => {
		if (paymentAmount > 0)
			return [{
				thumbnail: '<span />',
				description: 'Already paid',
				amount: '($' + paymentAmount.toFixed(2).replace(/[.,]00$/, '') + ')',
				icon: 'smile-o'
			}];
		else
			return [];
	}
)

const cartShippingSelector = createSelector(
	shippingSelector,
	challengesWithItemsCountSelector,
	(amount, count) => {
		if (count > 0) {
			if (amount > 0)
				return [{
					thumbnail: '<span />',
					description: 'Shipping and Handling',
					amount: '$' + amount,
					icon: 'rocket'
				}];
			else
				return [{
					thumbnail: '<span />',
					description: 'Shipping and Handling',
					amount: 'Inc.',
					icon: 'rocket'
				}];
		}
	}
)

const cartForPersonSelector = createSelector(
	cartSelector,
	(cart) => {
		if (cart.forPerson != null)
			return [{
				thumbnail: '<span />',
				description: 'For: ' + cart.forPerson,
				amount: '',
				icon: 'user'
			}];
		else
			return [];
	}
)

const cartChargedSelector = createSelector(
	cartSelector,
	(cart) => {
		if (cart.charged != null && cart.charged > 0)
			return [{
				thumbnail: '<span />',
				description: 'Charged',
				amount: '$' + cart.charged.toFixed(2).replace(/[.,]00$/, ''),
				icon: 'credit-card'
			}];
		else
			return [];
	}
)

const cartTransactionSelector = createSelector(
	cartSelector,
	(cart) => {
		if (cart.transactionId != null)
			return [{
				thumbnail: '<span />',
				description: 'Transaction Number: ' + cart.transactionId,
				amount: '',
				icon: 'key'
			}];
		else
			return [];
	}
)

const itemIcons = ['skin', 'trophy', 'plus-circle', 'gift'];
//this.props.push('/joinrace', { challengeUrl: challengeUrl });

const cartBundleChallengesSelector = createSelector(
	bundleChallengesSelector,
	(bundle) => {
		var bundleRows = [];
		bundle.forEach((summary) => {
			const skipParent = summary.isSwag && summary.items.length === 1;
			const editLink = { isSwag: summary.isSwag, challengeAthleteId: summary.challengeAthleteId, challengeUrl: summary.challengeUrl };
			if (!skipParent) {
				bundleRows.push({
					thumbnail: '<span />',
					description: summary.name,
					amount: summary.price > 0 ? '$' + summary.price.toFixed(2).replace(/[.,]00$/, '') : '',//(summary.isSwag ? '' : '),
					icon: 'compass',
					challengeUrl: summary.challengeUrl,
					challengeId: summary.challengeId,
					editLink: editLink,//summary.items.length > 0,
					deleteLink: { challengeId: summary.challengeId, description: summary.name },
					isSwag: summary.isSwag,
					challengeAthleteId: summary.challengeAthleteId
				});
			}
			summary.items.filter(item => item !== null).forEach((item, i) => {
				bundleRows.push({
					thumbnail: '<span />', //`<img src=${prependAssetHostItem(item.challengeAddonAsset.imageUri)} />`,
					description: item.description,
					amount: i === 0 && summary.price > 0 ? '' : '$' + item.itemPrice.toFixed(2).replace(/[.,]00$/, ''),
					editLink: skipParent ? editLink : null,
					deleteLink: skipParent ? { challengeId: summary.challengeId, description: item.description } : null,
					icon: itemIcons[item.itemType - 1]
				});
			});
			if (summary.discount > 0 && summary.addonDiscount > 0) {
				bundleRows.push({
					thumbnail: '<span />',
					description: `Multi-reward ${summary.addonDiscount}% Discount`,
					amount: '($' + summary.discount.toFixed(2).replace(/[.,]00$/, '') + ')',
					icon: 'smile-o'
				});
			}
		})
		return bundleRows;
	}
)

export const cartRowSelector = createSelector(
	cartForPersonSelector,
	cartBundleChallengesSelector,
	cartSalesTaxSelector,
	cartPromotionSelector,
	cartVoucherSelector,
	cartShippingSelector,
	cartChargedSelector,
	cartTransactionSelector,
	cartPaymentAmountSelector,
	(forPerson, bundle, salesTax, promotion, voucher, shipping, charged, transactionId, amountPaid) => {
		return forPerson.concat(bundle).concat(salesTax).concat(promotion).concat(voucher).concat(shipping).concat(amountPaid).concat(charged).concat(transactionId)
	}
)
