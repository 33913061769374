import React from 'react';
import { render } from 'react-dom';
//import '@babel/polyfill';
import { Provider } from 'react-redux';
import { store, history } from './stores';
import App from './containers/App';
//import injectTapEventPlugin from 'react-tap-event-plugin';
import initReactFastclick from 'react-fastclick';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import { loadProfile } from './containers/Login/actions';
import { loadBundle } from './containers/JoinRace/actions';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'
import env from './common/constants/environment';
import WebfontLoader from '@dr-kobros/react-webfont-loader';


const webFontConfig = {
	google: {
		families: [
			'Rubik Condensed:300,400,600,700,800',
			'Rubik:300,400,600,700,800',
			'Work Sans:300,400,600,700&display=swap'
		]
	},
	typekit: {
		id: 'ogk5mws',
		api: '//use.typekit.net'
	}
};

// Needed for onTouchTap
// http://stackoverflow.com/a/34015469/988941
//injectTapEventPlugin();
initReactFastclick();

ReactGA.initialize(env.GA_ID, { debug: env.GA_DEBUG });

const tagManagerArgs = {
	gtmId: env.GTM_ID
}
TagManager.initialize(tagManagerArgs);

store.dispatch(loadBundle());
store.dispatch(loadProfile());

render(
	<WebfontLoader config={webFontConfig}>
		<ConfigProvider locale={enUS}>
			<Provider store={store}>
				<App history={history} store={store} />
			</Provider>
		</ConfigProvider>
	</WebfontLoader>,
	document.getElementById('app')
);