import {
    FETCH_REQUESTED,
    FETCH_RESOLVED,
    FETCH_REJECTED,
    POST_REQUESTED,
    POST_RESOLVED,
    POST_REJECTED,
    DELETE_REQUESTED,
    DELETE_RESOLVED,
    DELETE_REJECTED,
    HANDLE_FIELD_CHANGE,
    HANDLE_ITEM_FIELD_CHANGE
} from './actions';
import { DELETE_WORKOUT_RESOLVED, POST_WORKOUT_RESOLVED } from '../Workouts/actions';
import assign from 'lodash.assign';

const initialState = {
    data: [{
        ratingEnum: 0,
        performanceLine: [],
        preferredLine: [],
        projectedLine: []
    }],
    hasError: false,
    error: null,
    isLoading: false,
    isSaving: false,
    isLoaded: false,
    editMode: false,
    hasModalError: false,
    showFeature: false,
    refresh: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RESOLVED:
            return assign({}, state, {
                ...state,
                data: action.response.goals,
                showFeature: action.response.showFeature,
                isLoading: false,
                hasError: false,
                error: null,
                isLoaded: true,
                isSaving: false
            });
        case FETCH_REQUESTED:
            return assign({}, state, {
                ...state,
                hasError: false,
                error: null,
                isLoading: true,
                isLoaded: false,
                refresh: false
            })
        case FETCH_REJECTED:
            return assign({}, state, {
                ...state,
                isLoading: false,
                hasError: true,
                error: action.message,
                isLoaded: true
            })
        case POST_RESOLVED:
            return assign({}, state, {
                ...state,
                data: action.response.goals,
                hasError: false,
                isLoading: false,
                isSaved: true,
                error: null,
                editMode: false
            });
        case POST_REQUESTED:
            return assign({}, state, {
                ...state,
                isSaved: false,
                isLoading: true,
                hasError: false,
                hasModalError: false,
                refresh: false
            });
        case POST_REJECTED:
            return assign({}, state, {
                ...state,
                isLoading: false,
                isSaved: false,
                hasModalError: true,
                error: 'There was an error' //action.message
            });
        case DELETE_RESOLVED:
            return assign({}, state, {
                //data: state.data.filter(item => item.challengeAthleteGoalId !== action.response.id),
                data: [{
                    ...state.data[0],
                    challengeAthleteGoalId: null,
                    challengeAthleteGoalTypeId: null
                }],
                hasError: false,
                isSaving: false,
                error: null,
                editMode: false
            });
        case DELETE_REQUESTED:
            return assign({}, state, {
                hasError: false,
                error: null,
                isSaving: true,
                refresh: false
            })
        case DELETE_REJECTED:
            return assign({}, state, {
                isSaving: false,
                hasError: true,
                error: action.message
            })
        case HANDLE_FIELD_CHANGE:
            var new_state = assign({}, state);
            new_state[action.key] = action.value;
            return new_state;
        case HANDLE_ITEM_FIELD_CHANGE:
            var new_state = assign({}, state);
            new_state.data[0][action.key] = action.value;
            return new_state;
        case DELETE_WORKOUT_RESOLVED:
        case POST_WORKOUT_RESOLVED:
            return assign({}, state, {
                ...state,
                refresh: true
            })
        default:
            return state;
    }
}