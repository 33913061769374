'use strict';
import { replace } from '../../common/actions/navigate';
import { CALL_API } from '../../common/middleware/api'
import { showMessage } from '../../common/notifications/actions';
import * as n from '../../common/notifications/constants';

export const POST_REQUESTED = 'yesfit/CP/POST_REQUESTED';
export const POST_RESOLVED = 'yesfit/CP/POST_RESOLVED';
export const POST_REJECTED = 'yesfit/CP/POST_REJECTED';

const CP_API = '/api/v1/changePassword';

export const post = (existingPassword, newPassword) => ({
    [CALL_API]: {
        types: [POST_REQUESTED, POST_RESOLVED, POST_REJECTED],
        endpoint: CP_API,
        method: 'POST',
        body: {
            existingPassword: existingPassword,
            newPassword: newPassword
        },
        onResolved: postResolved
    }
})

const postResolved = () => (dispatch) => {
    dispatch(showMessage('Your password has been changed', n.NOTIFICATIONS_INFO));
    dispatch(replace('/home'));
}