import env from '../constants/environment';
import { unauthorizedAccess } from '../actions';
import 'isomorphic-fetch';

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
export const callApi = (endpoint, method, token, body, dispatch) => {
	const fullUrl = (endpoint.indexOf(env.API_HOST) === -1) ? env.API_HOST + endpoint : endpoint

	let headers = {
		'Accept': 'application/json; charset=utf-8',
		'Content-Type': 'application/json; charset=utf-8',
		'From-Web': env.FROM_WEB, //TODO: Can deprecate in favor of PLATFORM
		'App-Version': env.VERSION,
		'Platform': env.PLATFORM,
        'X-Device-Id': null,
        'X-Device-Type': env.PLATFORM,
        'X-App-Namespace': env.NAME,
        'X-OS-Name': null,
        'X-OS-Version': null,
	};

	if (token !== null) {
		headers['Authorization'] = 'Bearer ' + token;
	}

	let bodyJson = null;
	if (body !== null) {
		bodyJson = JSON.stringify(body);
	}

	return fetch(fullUrl, {
			method: method,
			headers: headers,
			body: bodyJson
		})
		.then(response => {
			return checkStatus(response, dispatch)
		})
		.then(response => {
			return response.json();
		})
		.then(responseJson => {
			return checkError(responseJson);
		})
		.then(responseJson => {
			return Promise.resolve(responseJson)
		});
}

function checkStatus(response, dispatch) {
	if (response.ok && response.status >= 200 && response.status < 300) {
		return response;
	}
	else if (response.status >= 400 && response.status <= 499) {
		dispatch(unauthorizedAccess());
		throw new Error('Unauthorized access error');
	}
	else {
		return response
			.then(responseObj => {
				var response = JSON.parse(responseObj);
				throw new Error({ message: response.message });
			});
	}
}

function checkError(json) {
	if (!json.hasError) {
		return json;
	}
	else {
		throw new Error(json.message);
	}
}

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = 'Call API';

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default store => next => action => {
	let callAPI = action[CALL_API]
	if (typeof callAPI === 'undefined') {
		return next(action);
	}
	let { endpoint } = callAPI;
	const { method, body, types, onResolved, onRejected } = callAPI;
	const state = store.getState();
	var token = null;
	if (state.user && state.user.profile && state.user.profile.isAuthenticated) {
		token = state.user.profile.token.access_token;
	}
	if (typeof endpoint === 'function') {
		endpoint = endpoint(state)
	}
	if (typeof endpoint !== 'string') {
		throw new Error('Specify a string endpoint URL.')
	}
	if (!method) {
		throw new Error('Specify fetch method.')
	}
	if (!Array.isArray(types) || types.length !== 3) {
		throw new Error('Expected an array of three action types.')
	}
	if (!types.every(type => typeof type === 'string')) {
		throw new Error('Expected action types to be strings.')
	}

	const actionWith = data => {
		const finalAction = Object.assign({}, action, data)
		delete finalAction[CALL_API]
		return finalAction
	}

	const [requestType, successType, failureType] = types
	next(actionWith({ type: requestType }));

	return callApi(endpoint, method, token, body, store.dispatch).then(
		response => {
			if (typeof onResolved === 'function') {
				//onResolved should contain actions additional to the default action resolved.
				//It's for things like showing an alert and going to a different page, things you
				//can't do in a reducer
				store.dispatch(onResolved(response));
			}
			next(
				actionWith({
					response,
					type: successType
				})
			)
		},
		error => {
			if (typeof onRejected === 'function') {
				//onRejected should contain actions additional to the default action rejected.
				//It's for things like showing an alert and going to a different page, things you
				//can't do in a reducer
				store.dispatch(onRejected(error.message));
			}
			next(
				actionWith({
					type: failureType,
					hasError: true,
					message: error.message === null || error.message === 'Failed to fetch' ? 'Oops, we have pulled a muscle. Please try again.' : error.message
				})
			)
		}
	)
}
