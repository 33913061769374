'use strict';
import { FETCH_API } from './constants';
import { CALL_API } from '../../common/middleware/api'

export const FETCH_COUNTRIES_REQUESTED = 'yesfit/Countries/FETCH_REQUESTED';
export const FETCH_COUNTRIES_RESOLVED = 'yesfit/Countries/FETCH_RESOLVED';
export const FETCH_COUNTRIES_REJECTED = 'yesfit/Countries/FETCH_REJECTED';

const fetchCountries = endpoint => ({
    [CALL_API]: {
        types: [FETCH_COUNTRIES_REQUESTED, FETCH_COUNTRIES_RESOLVED, FETCH_COUNTRIES_REJECTED],
        endpoint: endpoint,
        method: 'GET'
    }
})

export const loadCountries = () => dispatch => {
    return dispatch(fetchCountries(FETCH_API));
}