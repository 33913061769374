import { createSelector } from 'reselect'

export const unseenFeedItemSelector = createSelector(
    state => state.athleteFeed.data,
    (items) => items.filter(item => item.seenTS === null && item.trackSeen)
);

export const seenFeedItemSelector = createSelector(
    state => state.athleteFeed.data,
    (items) => items.filter(item => (item.seenTS !== null || !item.trackSeen))
);

export const unseenFeedItemCountSelector = createSelector(
    [unseenFeedItemSelector],
    (items) => items.length
);
