'use strict';
import { CALL_API } from '../../common/middleware/api'

export const FETCH_REQUESTED = 'yesfit/Discover/FETCH_REQUESTED';
export const FETCH_RESOLVED = 'yesfit/Discover/FETCH_RESOLVED';
export const FETCH_REJECTED = 'yesfit/Discover/FETCH_REJECTED';
export const HANDLE_FIELD_CHANGE = 'yesfit/Discover/HANDLE_FIELD_CHANGE';

const FETCH_API = '/api/v1/ChallengeDiscovery';

export const fetch = () => ({
    [CALL_API]: {
        types: [FETCH_REQUESTED, FETCH_RESOLVED, FETCH_REJECTED],
        endpoint: FETCH_API,
        method: 'GET'
    }
})

export const fetchWithParams = (body) => ({
    [CALL_API]: {
        types: [FETCH_REQUESTED, FETCH_RESOLVED, FETCH_REJECTED],
        endpoint: FETCH_API,
		method: 'POST',
		body: body
    }
})

export function handleFieldChange(key, value) {
	return {
		type: HANDLE_FIELD_CHANGE,
		key: key,
		value: value
	}
}
