import {
    FETCH_REQUESTED,
    FETCH_RESOLVED,
    FETCH_REJECTED,
    POST_REQUESTED,
    POST_RESOLVED,
    POST_REJECTED,
    DELETE_RESOLVED,
    DELETE_REJECTED,
    HANDLE_GROUP_FIELD_CHANGE,
    HANDLE_NEW_GROUP
} from './actions';

import assign from 'lodash.assign';

const initialState = {
    data: {
        group: {
            groupId: null
        }
    },
    showInfoWindow: false,
    showAddAthletes: false,
    hasError: false,
    error: null,
    isLoading: false,
    isSaved: false,
    refresh: false,
    isLoaded: false,
    isSaving: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case POST_RESOLVED:
        case FETCH_RESOLVED:
        case DELETE_RESOLVED:
            return assign({}, state, {
                data: action.response,
                hasError: false,
                isLoading: false,
                isLoaded: true,
                isSaving: false,
                error: null,
                refresh: false
            });
        case FETCH_REQUESTED:
            return assign({}, state, {
                hasError: false,
                error: null,
                isLoading: true,
                isLoaded: false,
                refresh: false
            });
        case POST_REQUESTED:
        case DELETE_RESOLVED:
            return assign({}, state, {
                hasError: false,
                error: null,
                isSaving: true,
                refresh: false
            });
        case POST_REJECTED:
        case FETCH_REJECTED:
        case DELETE_REJECTED:
            return assign({}, state, {
                isLoading: false,
                isLoaded: true,
                hasError: true,
                error: action.message,
                isSaving: false,
                refresh: false
            });
        case HANDLE_GROUP_FIELD_CHANGE:
            var new_state = assign({}, state);
            new_state.data.group[action.key] = action.value;
            return new_state;
        case HANDLE_NEW_GROUP:
            return assign({}, state, {
                ...initialState
            })
        default:
            return state;
    }
}