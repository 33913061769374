export const GET_REQUESTED = "yesfit/AS/GET_REQUESTED";
export const GET_RESOLVED = "yesfit/AS/GET_RESOLVED";
export const GET_REJECTED = "yesfit/AS/GET_REJECTED";
export const UPDATE_STATS = "yesfit/AS/UPDATE_STATS";
export const UPLOAD_ERROR = "yesfit/AS/UPLOAD_ERROR";
// const VP_API = "/api/v1/validicProfile";
// export const getResolved = (response) => dispatch => {
// 	/*
// 	saveOptions({
// 		deviceFitness: response.healthApp //1 - Apple, 2 - Google, 3 - Samsung (future)
// 	});
// 	*/
// 	if (!response.hasError && response.healthApps && response.healthApps !== null && response.healthApps.length > 0) {
// 		const user = {
// 			user_id: response.validicId,
// 			org_id: response.validicOrgId,
// 			user_token: response.validicAccessToken
// 		};
// 		response.healthApps.forEach(healthApp => {
// 			const appInfo = getAppInfo(healthApp);
// 			if (appInfo.platform === Platform.OS) {
// 				dispatch(startValidicSession(user, healthApp));
// 			}
// 		});
// 	}
// }
// export const get = (id) => ({
// 	[CALL_API]: {
// 		types: [GET_REQUESTED, GET_RESOLVED, GET_REJECTED],
// 		endpoint: `${VP_API}/${id}`, //We need the id in case the user is just logging in
// 		method: "GET",
// 		onResolved: getResolved
// 	},
// });