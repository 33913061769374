import {
    FETCH_REQUESTED,
    FETCH_RESOLVED,
    FETCH_REJECTED
} from './actions';
import { POLL_REQUESTED } from '../AsyncPoll/actions';
import assign from 'lodash.assign';

const initialState = {
    data: {
        version: '',
        masterVersion: '',
        shouldReload: false
    },
    refresh: false,
    hasError: false,
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RESOLVED:
            return assign({}, state, {
                data: action.response,
                hasError: false,
                error: null,
                refresh: false
            });
        case FETCH_REQUESTED:
            return assign({}, state, {
                ...state,
                refresh: false
            })
        case FETCH_REJECTED:
            return assign({}, state, {
                ...state,
                refresh: false,
                hasError: true,
                error: action.error
            })
        case POLL_REQUESTED:
            return assign({}, state, {
                ...state,
                refresh: true
            })
        default:
            return state;
    }
}