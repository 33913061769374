import {
    FETCH_REQUESTED,
    FETCH_RESOLVED,
    FETCH_REJECTED
} from './actions';
import assign from 'lodash.assign';

const initialState = {
    //Not that the channels in the reducer is a different shape than the one from the selector
    channels: [{ channelId: 0, channelTopics: [{ isSelected: false, topic: { channelTopicId: null, name: null } }] }],
    hasError: false,
    error: null,
    isLoading: false,
    refresh: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RESOLVED:
            return assign({}, state, {
                channels: action.response.channels,
                hasError: false,
                isLoading: false,
                error: null
            });
        case FETCH_REQUESTED:
            return assign({}, state, {
                isLoading: true,
                refresh: false
            })
        case FETCH_REJECTED:
            return assign({}, state, {
                isLoading: false,
                hasError: true,
                error: action.error
            })
        default:
            return state;
    }
}