import {
    FETCH_REQUESTED,
    FETCH_RESOLVED,
    FETCH_REJECTED
} from './actions';
import { LOGIN_RESOLVED } from '../Login/actions';
import assign from 'lodash.assign';

const initialState = {
    flags: [],
    isLoaded: false,
    refresh: false,
    hasError: false,
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RESOLVED:
            return assign({}, state, {
                flags: action.response,
                isLoaded: true,
                hasError: false,
                error: null
            });
        case FETCH_REQUESTED:
            return assign({}, state, {
                ...state,
                refresh: false
            })
        case FETCH_REJECTED:
            return assign({}, state, {
                ...state,
                refresh: false,
                hasError: true,
                error: action.error
            })
        //case POLL_REQUESTED:
        case LOGIN_RESOLVED:
            return assign({}, state, {
                ...state,
                refresh: true
            })
        default:
            return state;
    }
}