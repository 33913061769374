'use strict';
import { CALL_API } from '../../common/middleware/api'

export const FETCH_REQUESTED = 'yesfit/AC/FETCH_REQUESTED';
export const FETCH_RESOLVED = 'yesfit/AC/FETCH_RESOLVED';
export const FETCH_REJECTED = 'yesfit/AC/FETCH_REJECTED';

const AC_API = '/api/v1/searchactivechallenges';

export const get = () => ({
    [CALL_API]: {
        types: [FETCH_REQUESTED, FETCH_RESOLVED, FETCH_REJECTED],
        endpoint: AC_API,
        method: 'GET'
    }
})