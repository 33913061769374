import {
    FETCH_REQUESTED,
    FETCH_RESOLVED,
    FETCH_REJECTED,
    POST_REQUESTED,
    POST_RESOLVED,
    POST_REJECTED,
    LIKE_REQUESTED,
    LIKE_RESOLVED,
    LIKE_REJECTED,
    LIKERS_REQUESTED,
    LIKERS_RESOLVED,
    LIKERS_REJECTED,
    HANDLE_FIELD_CHANGE,
    FEED_POST_REFRESH
} from './actions';
import assign from 'lodash.assign';
import { POLL_REQUESTED } from '../AsyncPoll/actions';
import { DASHBOARD_REFRESH } from '../Dashboard/actions';
import moment from 'moment';

const initialState = {
    data: [],
    likers: [],
    hasError: false,
    error: null,
    isLoading: false,
    isLoaded: false,
    lastUpdated: moment().format('h:mm A'),
    refresh: false,
    postRefresh: false,
    showLikers: false,
    isLoadingLikers: false,
    selectedGroupId: 0
};

export default (state = initialState, action) => {
    switch (action.type) {
        case HANDLE_FIELD_CHANGE:
            var new_state = assign({}, state);
            new_state[action.key] = action.value;
            return new_state;
        case FETCH_RESOLVED:
        case POST_RESOLVED:
            return assign({}, state, {
                ...state,
                data: action.response,
                hasError: false,
                error: null,
                isLoading: false,
                lastUpdated: moment().format('h:mm A'),
                isLoaded: true
            });
        case LIKERS_RESOLVED:
            return assign({}, state, {
                ...state,
                likers: action.response,
                hasError: false,
                error: null,
                isLoadingLikers: false
            });
        case LIKE_RESOLVED:
            return assign({}, state, {
                ...state,
                data: state.data.map(item => item.athleteFeedId === action.response ? {
                    ...item,
                    likes: !item.liked ? item.likes + 1 : item.likes - 1,
                    liked: !item.liked
                } : item),
                hasError: false,
                error: null,
                isLoading: false
            });
        case FETCH_REQUESTED:
            return assign({}, state, {
                isLoading: true,
                refresh: false
            })
        case LIKE_REQUESTED:
        case POST_REQUESTED:
            return assign({}, state, {
                isLoading: true,
                postRefresh: false
            })
        case LIKERS_REQUESTED:
            return assign({}, state, {
                ...state,
                isLoadingLikers: true,
                showLikers: true
            })
        case LIKERS_REJECTED:
        case LIKE_REJECTED:
        case FETCH_REJECTED:
        case POST_REJECTED:
            return assign({}, state, {
                hasError: true,
                error: action.error,
                isLoading: false,
                isLoaded: true,
                isLoadingLikers: false
            })
        case DASHBOARD_REFRESH:
        case POLL_REQUESTED:
            return assign({}, state, {
                refresh: true
            });
        case FEED_POST_REFRESH:
            return assign({}, state, {
                postRefresh: true
            });
        default:
            return state;
    }
}