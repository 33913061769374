/**
 * saves an item to local storage
 */
export function save(key, data) {
	return new Promise(
		(resolve) => {
			try {
				if (supports_html5_storage()) {
					let localStorage = window.localStorage;

					if (typeof data === 'object') {
						data = JSON.stringify(data);
					}

					localStorage.setItem(key, data);
				}
			}
			catch (ex) {
			}
			return resolve(key);
		});
}

/**
 * loads a key from local storage
 */
export function load(key) {
	return new Promise(
		(resolve) => {
			if (supports_html5_storage()) {
				let localStorage = window.localStorage;
				let item = localStorage.getItem(key);

				try {
					let parsed = JSON.parse(item);
					return resolve(parsed);
				}
				catch (ex) {
					return resolve(item);
				}
			}
			return resolve(key);
		});
}

export function supports_html5_storage() {
	var uid = new Date;
	var storage;
	var result;
	try {
		(storage = window.localStorage).setItem(uid, uid);
		result = storage.getItem(uid) == uid;
		storage.removeItem(uid);
		return result && storage;
	} catch (exception) {
		return false;
	}
}
