'use strict';
import { CALL_API } from '../../common/middleware/api';

export const FETCH_ATHLETE_ACHIEVEMENTS_REQUESTED = 'yesfit/AthleteAchievements/FETCH_ATHLETE_ACHIEVEMENTS_RESOLVED_REQUESTED';
export const FETCH_ATHLETE_ACHIEVEMENTS_RESOLVED = 'yesfit/AthleteAchievements/FETCH_ATHLETE_ACHIEVEMENTS_RESOLVED';
export const FETCH_ATHLETE_ACHIEVEMENTS_REJECTED = 'yesfit/AthleteAchievements/FETCH_ATHLETE_ACHIEVEMENTS_REJECTED';
export const HANDLE_FIELD_CHANGE = 'yesfit/AthleteAchievements/HANDLE_FIELD_CHANGE';

const API = '/api/v1/athleteAchievements';

export const loadAthleteAchievements = (userName) => ({
    [CALL_API]: {
        types: [FETCH_ATHLETE_ACHIEVEMENTS_REQUESTED, FETCH_ATHLETE_ACHIEVEMENTS_RESOLVED, FETCH_ATHLETE_ACHIEVEMENTS_REJECTED],
        endpoint: API + (typeof userName !== 'undefined' && userName !== null ? '/' + userName : ''),
        method: 'GET'
    }
})

export const handleFieldChange = (key, value) => {
    return {
        type: HANDLE_FIELD_CHANGE,
        key: key,
        value: value
    }
}
