import {
    FETCH_REQUESTED,
    FETCH_RESOLVED,
    FETCH_REJECTED
} from './actions';
import { FEED_POST_REFRESH } from '../../AthleteNewsFeed/actions';

import assign from 'lodash.assign';

const initialState = {
    groups: [],
    hasError: false,
    error: null,
    isLoading: false,
    refresh: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RESOLVED:
            return assign({}, state, {
                groups: action.response.groups,
                hasError: false,
                isLoading: false,
                isSaving: false,
                error: null
            });
        case FETCH_REQUESTED:
            return assign({}, state, {
                isLoading: true,
                refresh: false
            })
        case FETCH_REJECTED:
            return assign({}, state, {
                isLoading: false,
                isSaving: false,
                hasError: true,
                error: action.message
            })
        case FEED_POST_REFRESH:
            return assign({}, state, {
                refresh: true
            });
        default:
            return state;
    }
}