'use strict';
import { CALL_API } from '../../../common/middleware/api';
import { showMessage } from '../../../common/notifications/actions';
import * as n from '../../../common/notifications/constants';
import { push } from '../../../common/actions/navigate';

export const FETCH_REQUESTED = 'yesfit/GroupsAdminDetails/FETCH_REQUESTED';
export const FETCH_RESOLVED = 'yesfit/GroupsAdminDetails/FETCH_RESOLVED';
export const FETCH_REJECTED = 'yesfit/GroupsAdminDetails/FETCH_REJECTED';
export const POST_REQUESTED = 'yesfit/GroupsAdminDetails/POST_REQUESTED';
export const POST_RESOLVED = 'yesfit/GroupsAdminDetails/POST_RESOLVED';
export const POST_REJECTED = 'yesfit/GroupsAdminDetails/POST_REJECTED';
export const DELETE_REQUESTED = 'yesfit/GroupsAdminDetails/DELETE_REQUESTED';
export const DELETE_RESOLVED = 'yesfit/GroupsAdminDetails/DELETE_RESOLVED';
export const DELETE_REJECTED = 'yesfit/GroupsAdminDetails/DELETE_REJECTED';
export const HANDLE_GROUP_FIELD_CHANGE = 'yesfit/GroupsAdminDetails/HANDLE_GROUP_FIELD_CHANGE';
export const HANDLE_NEW_GROUP = 'yesfit/GroupsAdminDetails/HANDLE_NEW_GROUP';

export const GA_API = '/api/v1/groups/GroupList';

export const fetchGroup = (groupId) => ({
    [CALL_API]: {
        types: [FETCH_REQUESTED, FETCH_RESOLVED, FETCH_REJECTED],
        endpoint: `${GA_API}/${groupId}`,
        method: 'GET'
    }
})

const saveGroup = (body) => ({
    [CALL_API]: {
        types: [POST_REQUESTED, POST_RESOLVED, POST_REJECTED],
        endpoint: `${GA_API}`,
        body: body,
        method: 'PUT', //POST is was stolen by image upload
        onResolved: onSaveGroupResolved
    }
})

export const deleteGroup = (groupId) => ({
    [CALL_API]: {
        types: [DELETE_REQUESTED, DELETE_RESOLVED, DELETE_REJECTED],
        endpoint: `${GA_API}/${groupId}`,
        method: 'DELETE',
        onResolved: onDeleteGroupResolved
    }
})

export const postGroup = (group) => (dispatch, getState) => {
    const body = {
        ...getState().groupAdminDetails.data.group,
        ...group
    };
    return dispatch(saveGroup(body));
}

const onSaveGroupResolved = () => dispatch => {
    dispatch(showMessage('Your changes have been saved', n.NOTIFICATIONS_INFO));
}

const onDeleteGroupResolved = () => dispatch => {
    dispatch(push('/groups'));
    dispatch(showMessage('Group has been deleted', n.NOTIFICATIONS_INFO));
}

export const handleGroupFieldChange = (key, value) => dispatch => {
    return dispatch({
        type: HANDLE_GROUP_FIELD_CHANGE,
        key: key,
        value: value
    });
}

export const newGroup = () => dispatch => {
    return dispatch({
        type: HANDLE_NEW_GROUP
    });
}
