import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import user from '../../containers/Login/reducer';
import challenge from '../../containers/JoinRace/reducer';
import stepper from '../../containers/Steppers/reducer';
import states from '../../containers/SelectState/reducer';
import selectCountry from '../../containers/SelectCountry/reducer';
import athletes from '../../containers/SelectAthlete/reducer';
import selectActivityType from '../../containers/SelectActivityType/reducer';
import challengeAthlete from '../../containers/ChallengeAthlete/reducer';
import chooseChallenge from '../../containers/ChooseRace/reducer';
import challengeHistory from '../../containers/ChallengeHistory/reducer';
import challengeAthleteStats from '../../containers/ChallengeAthleteStats/reducer';
import challengeAthleteRewards from '../../containers/ChallengeAthleteRewards/reducer';
import challengeAthleteProfile from '../../containers/ChallengeAthleteProfile/reducer';
import workouts from '../../containers/Workouts/reducer';
import notifications from '../notifications/reducer';
import dashboard from '../../containers/Dashboard/reducer';
import challengeAthleteMap from '../../containers/ChallengeAthleteMap/reducer';
import version from '../../containers/VersionChecker/reducer';
import athleteSocial from '../../containers/AthleteSocial/reducer';
import challengeAthleteSocial from '../../containers/ChallengeAthleteSocial/reducer';
import challengeAthleteRequests from '../../containers/ChallengeAthleteRequests/reducer';
import athleteProfile from '../../containers/AthleteProfile/reducer';
import changePassword from '../../containers/ChangePassword/reducer';
import deleteRequest from '../../containers/DeleteRequest/reducer';
import resetPassword from '../../containers/ResetPassword/reducer';
import selectApp from '../../containers/SelectApp/reducer';
import athleteApp from '../../containers/AthleteApp/reducer';
import athleteFeed from '../../containers/AthleteNewsFeed/reducer';
import challengeAthleteGoals from '../../containers/ChallengeAthleteGoals/reducer';
import nativeActivitySync from '../../containers/NativeActivitySync/reducer';
import gift from '../../containers/Gift/reducer';
import selectGoalType from '../../containers/SelectGoalType/reducer';
import featureFlags from '../../containers/FeatureFlags/reducer';
import selectActiveChallenge from '../../containers/SelectActiveChallenge/reducer'; //Likely for native only
import subscriptions from '../../containers/Subscription/reducer';
import referrals from '../../containers/ShareRaceButtons/reducer';
import wishlist from '../../containers/Wishlist/reducer';
import athleteAchievements from '../../containers/AthleteAchievements/reducer';
import queueOrder from '../../containers/QueueOrder/reducer';
import discover from '../../containers/Discover/reducer';
import groupProfile from '../../containers/Groups/GroupProfile/reducer';
import groupAthletes from '../../containers/Groups/Athletes/reducer';
import groupAthleteTypes from '../../containers/Groups/GroupAthleteTypes/reducer';
import groupAdminDetails from '../../containers/Groups/AdminDetails/reducer';
import groupAdminEvents from '../../containers/Groups/AdminEvents/reducer';
import groupAdminList from '../../containers/Groups/AdminList/reducer';
import groupFilter from '../../containers/Groups/Filter/reducer';
import selectTopics from '../../containers/SelectTopics/reducer';
import tileChallenge from '../../containers/TileChallenge/reducer';
import selectChallenge from '../../containers/SelectChallenge/reducer';
import fitbit from '../../containers/Devices/Fitbit/reducer';
import garmin from '../../containers/Devices/Garmin/reducer';
import strava from '../../containers/Devices/Strava/reducer';
import ua from '../../containers/Devices/UnderArmour/reducer';
 
const reducers = combineReducers({
	athleteAchievements,
	athleteApp,
	athleteFeed,
	athleteProfile,
	athleteSocial,
	challenge,
	challengeAthlete,
	challengeAthleteGoals,
	challengeAthleteMap,
	challengeAthleteRequests,
	challengeAthleteRewards,
	challengeAthleteProfile,
	challengeAthleteSocial,
	challengeAthleteStats,
	challengeHistory,
	changePassword,
	chooseChallenge,
	dashboard,
	deleteRequest,
	discover,
	featureFlags,
	fitbit,
	garmin,
	gift,
	groupAdminDetails,
	groupAdminEvents,
	groupAdminList,
	groupAthletes,
	groupAthleteTypes,
	groupFilter,
	groupProfile,
	nativeActivitySync,
	notifications,
	queueOrder,
	referrals,
	resetPassword,
	routing: routerReducer,
	selectApp,
	selectActiveChallenge,
	selectActivityType,
	selectAthlete: athletes,
	selectChallenge,
	selectCountry,
	selectGoalType,
	selectState: states,
	selectTopics,
	stepper,
	strava,
	subscriptions,
	tileChallenge,
	ua,
	user,
	version,
	wishlist,
	workouts
})

export default reducers;
