'use strict';
import { CALL_API } from '../../../common/middleware/api';

export const FETCH_REQUESTED = 'yesfit/GroupProfile/FETCH_REQUESTED';
export const FETCH_RESOLVED = 'yesfit/GroupProfile/FETCH_RESOLVED';
export const FETCH_REJECTED = 'yesfit/GroupProfile/FETCH_REJECTED';
export const HANDLE_FIELD_CHANGE = 'yesfit/GroupProfile/HANDLE_FIELD_CHANGE';

const GA_API = '/api/v1/groups/GroupProfile';

const postGroup = body => ({
    [CALL_API]: {
        types: [FETCH_REQUESTED, FETCH_RESOLVED, FETCH_REJECTED],
        endpoint: GA_API,
        method: 'POST',
        body: body
    }
})

export const fetchGroup = groupId => (dispatch, getState) => {
    const groupProfile = getState().groupProfile;
    var body = {
        groupId: groupId,
        fromDate: groupProfile.fromDate,
        toDate: groupProfile.toDate
    };
    return dispatch(postGroup(body));
}

export const handleFieldChange = (key, value) => dispatch => {
    return dispatch({
        type: HANDLE_FIELD_CHANGE,
        key: key,
        value: value
    });
}