'use strict';
import { FETCH_STATES_API } from './constants';
import { CALL_API } from '../../common/middleware/api'

export const FETCH_STATES_REQUESTED = 'yesfit/States/FETCH_STATES_REQUESTED';
export const FETCH_STATES_RESOLVED = 'yesfit/States/FETCH_STATES_RESOLVED';
export const FETCH_STATES_REJECTED = 'yesfit/States/FETCH_STATES_REJECTED';

const fetchStates = endpoint => ({
    [CALL_API]: {
        types: [FETCH_STATES_REQUESTED, FETCH_STATES_RESOLVED, FETCH_STATES_REJECTED],
        endpoint: endpoint,
        method: 'GET'
    }
})

export const loadStates = () => dispatch => {
    return dispatch(fetchStates(FETCH_STATES_API));
}