'use strict';
import { CALL_API } from '../../../common/middleware/api'
import { showMessage } from '../../../common/notifications/actions';
import * as n from '../../../common/notifications/constants';
import { push } from 'react-router-redux';

export const FETCH_REQUESTED = 'yesfit/FITBIT/FETCH_REQUESTED';
export const FETCH_RESOLVED = 'yesfit/FITBIT/FETCH_RESOLVED';
export const FETCH_REJECTED = 'yesfit/FITBIT/FETCH_REJECTED';
export const URL_REQUESTED = 'yesfit/FITBIT/URL_REQUESTED';
export const URL_RESOLVED = 'yesfit/FITBIT/URL_RESOLVED';
export const URL_REJECTED = 'yesfit/FITBIT/URL_REJECTED';
export const POST_REQUESTED = 'yesfit/FITBIT/POST_REQUESTED';
export const POST_RESOLVED = 'yesfit/FITBIT/POST_RESOLVED';
export const POST_REJECTED = 'yesfit/FITBIT/POST_REJECTED';
export const DELETE_REQUESTED = 'yesfit/FITBIT/DELETE_REQUESTED';
export const DELETE_RESOLVED = 'yesfit/FITBIT/DELETE_RESOLVED';
export const DELETE_REJECTED = 'yesfit/FITBIT/DELETE_REJECTED';
export const HANDLE_FIELD_CHANGE = 'yesfit/FITBIT/HANDLE_FIELD_CHANGE';

const API = '/api/v1/fitbit/Authorize';

export const fetch = (userName) => ({
    [CALL_API]: {
        types: [FETCH_REQUESTED, FETCH_RESOLVED, FETCH_REJECTED],
        endpoint: userName ? `${API}/${userName}` : API,
        method: 'GET'
    }
})

export const getUrl = () => ({
    [CALL_API]: {
        types: [URL_REQUESTED, URL_RESOLVED, URL_REJECTED],
        endpoint: `${API}/GetUrl`,
        method: 'GET',
        onResolved: getUrlResolved
    }
})

export const disconnect = () => ({
    [CALL_API]: {
        types: [DELETE_REQUESTED, DELETE_RESOLVED, DELETE_REJECTED],
        endpoint: API,
        method: 'DELETE',
        onResolved: disconnectResolved
    }
})

export const authorize = (code) => ({
    [CALL_API]: {
        types: [POST_REQUESTED, POST_RESOLVED, POST_REJECTED],
        endpoint: API,
        method: 'POST',
        body: { code: code },
        onResolved: authorizeResolved
    }
})

const getUrlResolved = (response) => (dispatch) => {
    //This will cause the window to open to the Fitbit URL. After the user has authorized, Fitbit will redirect 
    //back to here with a code query parameter
	window.open(response.authorizationUrl, "_self");
}

const authorizeResolved = (response) => (dispatch) => {
    dispatch(showMessage('Congratulations, your Fitbit has been connected to Yes.Fit', n.NOTIFICATIONS_INFO));
    //Clear the code parameter from the URL
    dispatch(push('/devices/fitbit'));
}

const disconnectResolved = (response) => (dispatch) => {
    dispatch(showMessage('Your Fitbit has been disconnected from Yes.Fit. You may reconnect it at any time.', n.NOTIFICATIONS_INFO));
}

export const handleFieldChange = (key, value) => dispatch => {
    return dispatch({
        type: HANDLE_FIELD_CHANGE,
        key: key,
        value: value
    });
}
