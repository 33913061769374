import {
    FETCH_REQUESTED,
    FETCH_RESOLVED,
    FETCH_REJECTED,
    POST_REQUESTED,
    POST_RESOLVED,
    POST_REJECTED,
    UNSUB_REQUESTED,
    UNSUB_RESOLVED,
    UNSUB_REJECTED,
    HANDLE_FIELD_CHANGE,
    HANDLE_ATHLETE_NEW,
    HANDLE_ATHLETE_CHANGE
} from './actions';

import assign from 'lodash.assign';

const initialState = {
    data: {
        group: {
            athletes: []
        }
    },
    showInfoWindow: false,
    showAddAthletes: false,
    hasError: false,
    error: null,
    isLoading: false,
    isSaved: false,
    refresh: false,
    isLoaded: false,
    isSaving: false,
    filter: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case POST_RESOLVED:
        case FETCH_RESOLVED:
            return assign({}, state, {
                data: action.response,
                hasError: false,
                isLoading: false,
                isLoaded: true,
                isSaving: false,
                error: null,
                refresh: false
            });
        case UNSUB_RESOLVED:
            return assign({}, state, {
                isLoading: false,
                isLoaded: true
            });
        case UNSUB_REQUESTED:
        case FETCH_REQUESTED:
            return assign({}, state, {
                hasError: false,
                error: null,
                isLoading: true,
                isLoaded: false,
                refresh: false,
                filter: null
            });
        case POST_REQUESTED:
            return assign({}, state, {
                hasError: false,
                error: null,
                isSaving: true,
                refresh: false,
                filter: null
            });
        case UNSUB_REJECTED:
        case POST_REJECTED:
        case FETCH_REJECTED:
            return assign({}, state, {
                isLoading: false,
                isLoaded: true,
                hasError: true,
                error: action.message,
                isSaving: false,
                refresh: false
            });
        case HANDLE_FIELD_CHANGE:
            var new_state = assign({}, state);
            new_state[action.key] = action.value;
            return new_state;
        case HANDLE_ATHLETE_CHANGE:
            var new_state = assign({}, state);
            if (action.athlete.key === null) {
                //Insert the new one at the front of the array
                new_state.data.group.athletes = [action.athlete, ...state.data.group.athletes];
            }
            else {
                //Update the existing array item
                new_state.data.group.athletes = state.data.group.athletes.map(
                    (item) => item.key === action.athlete.key ? action.athlete : item
                )
            }
            return new_state;
        case HANDLE_ATHLETE_NEW:
            var new_state = assign({}, state, {
                key: action.athlete.key,
                showInfoWindow: true,
                filter: null
            });
            new_state.data.group.athletes.unshift(action.athlete);
            return new_state;
        default:
            return state;
    }
}