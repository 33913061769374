import { CALL_API } from '../../../common/middleware/api'

export const ALLGROUPS_FETCH_REQUESTED = 'yesfit/GA/ALLGROUPS_FETCH_REQUESTED';
export const ALLGROUPS_FETCH_RESOLVED = 'yesfit/GA/ALLGROUPS_FETCH_RESOLVED';
export const ALLGROUPS_FETCH_REJECTED = 'yesfit/GA/ALLGROUPS_FETCH_REJECTED';
export const GROUP_FETCH_REQUESTED = 'yesfit/GA/GROUP_FETCH_REQUESTED';
export const GROUP_FETCH_RESOLVED = 'yesfit/GA/GROUP_FETCH_RESOLVED';
export const GROUP_FETCH_REJECTED = 'yesfit/GA/GROUP_FETCH_REJECTED';
export const HANDLE_FIELD_CHANGE = 'yesfit/GA/HANDLE_FIELD_CHANGE';
export const HANDLE_OBJECT_CHANGE = 'yesfit/GA/HANDLE_OBJECT_CHANGE';

const GL_API = '/api/v1/groups/GroupList';

export const fetchGroups = () => ({
    [CALL_API]: {
        types: [ALLGROUPS_FETCH_REQUESTED, ALLGROUPS_FETCH_RESOLVED, ALLGROUPS_FETCH_REJECTED],
        endpoint: GL_API,
        method: 'GET'
    }
})

export const handleFieldChange = (field, value) => dispatch => {
    return dispatch({
        type: HANDLE_FIELD_CHANGE,
        field: field,
        value: value
    });
}
