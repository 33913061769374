'use strict';
import { CALL_API } from '../../common/middleware/api'

export const POST_REQUESTED = 'yesfit/Wishlist/POST_REQUESTED';
export const POST_RESOLVED = 'yesfit/Wishlist/POST_RESOLVED';
export const POST_REJECTED = 'yesfit/Wishlist/POST_REJECTED';

const API = '/api/v1/wishlist';

export const post = (title, route) => ({
    [CALL_API]: {
        types: [POST_REQUESTED, POST_RESOLVED, POST_REJECTED],
        endpoint: API,
        method: 'POST',
        body: {
            title: title,
            route: route
        }
    }
})
