'use strict';
import assign from 'lodash.assign';
import { push } from '../../common/actions/navigate';
import { next, error, clear, reset, goto } from '../Steppers/actions';
import { showMessage, showNotification } from '../../common/notifications/actions';
import { onSelectChallenge } from '../Dashboard/actions';
import { CALL_API } from '../../common/middleware/api'
import * as n from '../../common/notifications/constants';
import { totalSelector, subTotalSelector, shippingSelector, vouchersSelector, totalPrePromotionSelector, totalpreShippingSelector, salesTaxTotalSelector, salesTaxableSelector } from './selectors';
import { tagsSale, tagsFetchChallenge, tagsAddToCart } from '../../common/tags';
import * as storageService from '../../common/services/storage.service';
import { KEYS } from '../../common/constants/localStorage';

export const FETCH_CHALLENGE = 'yesfit/JoinRace/FETCH_CHALLENGE';
export const HANDLE_FIELD_CHANGE = 'yesfit/JoinRace/FIELD_CHANGE';
export const HANDLE_CART_FIELD_CHANGE = 'yesfit/JoinRace/CART_FIELD_CHANGE';
export const HANDLE_CART_OBJECT_CHANGE = 'yesfit/JoinRace/CART_OBJECT_CHANGE';
export const HANDLE_CART_REWARD_FIELD_CHANGE = 'yesfit/JoinRace/CART_REWARD_FIELD_CHANGE';
export const HANDLE_CART_ADDON_FIELD_CHANGE = 'yesfit/JoinRace/CART_ADDON_FIELD_CHANGE';
export const FETCH_CHALLENGE_REQUESTED = 'yesfit/JoinRace/FETCH_CHALLENGE_REQUESTED';
export const FETCH_CHALLENGE_RESOLVED = 'yesfit/JoinRace/FETCH_CHALLENGE_RESOLVED';
export const FETCH_CHALLENGE_REJECTED = 'yesfit/JoinRace/FETCH_CHALLENGE_REJECTED';
export const CHECKOUT = '/yesfit/JoinRace/CHECKOUT';
export const CHECKOUT_REQUESTED = 'yesfit/JoinRace/CHECKOUT_REQUESTED';
export const CHECKOUT_RESOLVED = 'yesfit/JoinRace/CHECKOUT_RESOLVED';
export const CHECKOUT_REJECTED = 'yesfit/JoinRace/CHECKOUT_REJECTED';
export const DELETE_CHALLENGE_ATHLETE_REQUESTED = 'yesfit/JoinRace/DELETE_CHALLENGE_ATHLETE_REQUESTED';
export const DELETE_CHALLENGE_ATHLETE_RESOLVED = 'yesfit/JoinRace/DELETE_CHALLENGE_ATHLETE_RESOLVED';
export const DELETE_CHALLENGE_ATHLETE_REJECTED = 'yesfit/JoinRace/DELETE_CHALLENGE_ATHLETE_REJECTED';
export const VALIDATE_VOUCHER_REQUESTED = 'yesfit/JoinRace/VALIDATE_VOUCHER_REQUESTED';
export const VALIDATE_VOUCHER_RESOLVED = 'yesfit/JoinRace/VALIDATE_VOUCHER_RESOLVED';
export const VALIDATE_VOUCHER_REJECTED = 'yesfit/JoinRace/VALIDATE_VOUCHER_REJECTED';
export const VALIDATE_PROMOTIONS_REQUESTED = 'yesfit/JoinRace/VALIDATE_PROMOTIONS_REQUESTED';
export const VALIDATE_PROMOTIONS_RESOLVED = 'yesfit/JoinRace/VALIDATE_PROMOTIONS_RESOLVED';
export const VALIDATE_PROMOTIONS_REJECTED = 'yesfit/JoinRace/VALIDATE_PROMOTIONS_REJECTED';
export const REMOVE_VOUCHER = 'yesfit/JoinRace/REMOVE_VOUCHER';
export const SALESTAX_REQUESTED = 'yesfit/JoinRace/SALESTAX_REQUESTED';
export const SALESTAX_RESOLVED = 'yesfit/JoinRace/SALESTAX_RESOLVED';
export const SALESTAX_REJECTED = 'yesfit/JoinRace/SALESTAX_REJECTED';

export const ADD_TO_BUNDLE = 'yesfit/JoinRace/ADD_TO_BUNDLE';
export const REMOVE_FROM_BUNDLE = 'yesfit/JoinRace/REMOVE_FROM_BUNDLE';
export const CLEAR_BUNDLE = 'yesfit/JoinRace/CLEAR_BUNDLE';
export const LOAD_BUNDLE = 'yesfit/JoinRace/LOAD_BUNDLE';

export const FETCH_CHALLENGE_API = '/api/v1/JoinChallenge/';
export const FETCH_EVENT_DATA_API = '/api/v1/EventData/'; //Just get the metadata for a race, will be heavily cached
const VOUCHER_API = '/api/v1/voucher';
const PROMOTIONS_API = '/api/v1/promotions';

export const fetchChallenge = endpoint => ({
	[CALL_API]: {
		types: [FETCH_CHALLENGE_REQUESTED, FETCH_CHALLENGE_RESOLVED, FETCH_CHALLENGE_REJECTED],
		endpoint: endpoint,
		method: 'GET',
		onResolved: fetchChallengeAthleteResolved,
		onRejected: fetchChallengeAthleteRejected
	}
})

const deleteChallengeAthlete = endpoint => ({
	[CALL_API]: {
		types: [DELETE_CHALLENGE_ATHLETE_REQUESTED, DELETE_CHALLENGE_ATHLETE_RESOLVED, DELETE_CHALLENGE_ATHLETE_REJECTED],
		endpoint: endpoint,
		method: 'DELETE',
		onResolved: deleteChallengeAthleteResolved
	}
})

const postChallengeAthlete = (endpoint, body) => ({
	[CALL_API]: {
		types: [CHECKOUT_REQUESTED, CHECKOUT_RESOLVED, CHECKOUT_REJECTED],
		endpoint: endpoint,
		method: 'POST',
		body: body,
		onResolved: postChallengeAthleteResolved,
		onRejected: postRejected
	}
})

const postValidateVoucher = (body) => ({
	[CALL_API]: {
		types: [VALIDATE_VOUCHER_REQUESTED, VALIDATE_VOUCHER_RESOLVED, VALIDATE_VOUCHER_REJECTED],
		endpoint: VOUCHER_API,
		method: 'POST',
		body: body,
		onResolved: postValidateVoucherResolved,
		onRejected: postRejected
	}
})

const postValidatePromotions = (body) => ({
	[CALL_API]: {
		types: [VALIDATE_PROMOTIONS_REQUESTED, VALIDATE_PROMOTIONS_RESOLVED, VALIDATE_PROMOTIONS_REJECTED],
		endpoint: PROMOTIONS_API,
		method: 'POST',
		body: body
	}
})

const postGetSalesTax = (body) => ({
	[CALL_API]: {
		types: [SALESTAX_REQUESTED, SALESTAX_RESOLVED, SALESTAX_REJECTED],
		endpoint: `${FETCH_CHALLENGE_API}getSalesTax`,
		method: 'POST',
		body: body,
		onResolved: postGetSalesTaxResolved,
		onRejected: postGetSalesTaxRejected
	}
})

export const pushToChallenge = (item, isWeb, withReset) => (dispatch, getState) => {
	if (isWeb) {
		const path = item.isSwag ? 'store' : 'races';
		const route = `/${path}/${item.challengeUrl}`;
		if (withReset) {
			const state = getState();
			const athleteId = state.user.profile && state.user.profile.athleteId ? state.user.profile.athleteId : null;
			dispatch(reset());
			dispatch(loadChallenge(item.challengeUrl, athleteId));
		}
		dispatch(push(route));
	}
	else {
		dispatch(push('/joinrace', { challengeUrl: item.challengeUrl }));
	}
}

export const getSalesTax = () => (dispatch, getState) => {
	const state = getState();
	const cart = state.challenge.cart;
	const lastAddress = state.challenge.salesTax.lastAddress;
	//Only call the API if at least one address property has changed since the last call
	if (cart.zip !== null && (lastAddress.line1 !== cart.address || lastAddress.city !== cart.city || lastAddress.stateId !== cart.stateId || lastAddress.zip !== cart.zip)) {
		const body = {
			line1: cart.address,
			city: cart.city,
			stateId: cart.stateId,
			zip: cart.zip
		};
		return dispatch(postGetSalesTax(body));
	}
	else {
		return dispatch(next());
	}
}

const postGetSalesTaxResolved = () => (dispatch) => {
	return dispatch(next());
}

const postGetSalesTaxRejected = (message) => dispatch => {
	return dispatch(error(message));
}

const postValidateVoucherResolved = () => dispatch => {
	//Recalculate Promotions
	return dispatch(showMessage('The Code was added to your cart and the total has been updated', n.NOTIFICATIONS_INFO));
}

export const validateVoucher = (code) => (dispatch, getState) => {
	const state = getState();
	if (state.challenge.cart.bundle.challenges && state.challenge.cart.bundle.challenges !== null) {
		const challengeIds = state.challenge.cart.bundle.challenges.map(item => item.challengeId);
		const body = {
			code: code,
			amount: totalpreShippingSelector(state),
			challengeIds: challengeIds,
			existingVouchers: vouchersSelector(state)
		};
		dispatch(clear());
		return dispatch(postValidateVoucher(body));
	}
}

export const validatePromotions = (amount) => (dispatch, getState) => {
	const state = getState();
	if (state.challenge.cart.bundle.challenges && state.challenge.cart.bundle.challenges !== null) {
		const challengeIds = state.challenge.cart.bundle.challenges.map(item => item.challengeId);
		const body = {
			amount: amount,
			challengeIds: challengeIds,
			userId: state.challenge.cart.userId
		};
		return dispatch(postValidatePromotions(body));
	}
}

export const removeVoucher = (code) => dispatch => {
	dispatch(showMessage('The Code was removed from your cart and the total has been updated', n.NOTIFICATIONS_INFO));
	return dispatch({
		type: REMOVE_VOUCHER,
		code: code
	});
}

export const loadChallenge = (slug, athleteId) => dispatch => {
	let endpoint = FETCH_CHALLENGE_API + slug + '/' + athleteId;
	return dispatch(fetchChallenge(endpoint));
}

function fetchChallengeAthleteResolved(response) {
	return (dispatch, getState) => {
		const state = getState();
		const isAdmin = state.user.profile && state.user.profile.isAdmin;
		const isMe = state.user.profile && state.user.profile.athleteId === response.cart.athleteId;
		tagsFetchChallenge(response);
		const noun = response.summary.isSwag ? 'swag' : 'race'
		if (response.cart.challengeAthleteId === null) {
			if (isAdmin && !isMe) {
				dispatch(showNotification(`New ${noun} for athlete`,
					`You are an Admin, and you can assign this ${noun} to ${response.cart.forPerson} without having to go through the Payment step. This ${noun} is not currently assigned to ${response.cart.forPerson}.`,
					n.NOTIFICATIONS_INFO));
			}
			else if (response.cart.bundle.bundleId !== null) {
				dispatch(showNotification(`${response.cart.bundle.name} added to your cart`,
					`We have added ${response.cart.bundle.challenges.length} items to your cart from the ${response.cart.bundle.name} bundle. Scroll down to see your cart.`,
					n.NOTIFICATIONS_INFO));
				//dispatch(goto(3)); //Shipping
			}
			/*
			const inBundle = state.challenge.cart.bundle.challenges.some(item => item.key === response.summary.challengeId);
			if (inBundle) {
				//Advancing here messes up when you go from the checkout page back to the rewards page for a specific race
				dispatch(next());
			}*/
		}
		else {
			if (isAdmin) {
				if (!isMe) {
					dispatch(showNotification(`Update existing ${noun}`,
						`You are an Admin, and you can change the selected Reward for ${response.cart.forPerson}. This ${noun} is already assigned to ${response.cart.forPerson}.`,
						n.NOTIFICATIONS_INFO));
				}
			}
			else if (!response.cart.orderSent) {
				dispatch(showNotification(`${noun} already purchased`,
					`You are currently updating ${noun} that you have already purchased. You will be able to change your items and shipping address, as long as the total amount doesn't decrease.`,
					n.NOTIFICATIONS_INFO));
			}
			else {
				dispatch(showMessage(`Your items have been shipped for ${response.summary.name}. Were you trying to get to your Dashboard?`, n.NOTIFICATIONS_INFO));
				dispatch(push('/races'));
			}
			dispatch(next()); //Go to the next step if editing an existing race
		}
		if (isAdmin && !isMe) {
			const body = {
				amount: totalPrePromotionSelector(state),
				challengeId: response.summary.challengeId,
				userId: response.cart.userId
			};
			return dispatch(postValidatePromotions(body));
		}
	}
}

export function fetchChallengeAthleteRejected(error) {
	return (dispatch) => {
		dispatch(showMessage(error, n.NOTIFICATIONS_ERROR));
		dispatch(push('/races'));
	}
}

function postChallengeAthleteResolved(response) {
	return (dispatch, getState) => {
		const state = getState();
		const challenge = state.challenge;
		const total = totalSelector(state);
		if ((response.isNew || (!response.isNew && total > 0)) && !state.user.profile.isAdmin) {
			tagsSale(response, challenge, shippingSelector(state), total);
			if (response.isPaused) {
				dispatch(showNotification('We have paused your race',
					'We notice you have another active race, so this one has been put on pause for now. You can easily un-pause it from your Dashboard when you are ready to go.',
					n.NOTIFICATIONS_INFO));
			}
		}
		dispatch(next());
		/*
		dispatch(handleObjectChange({
			challengeAthleteId: response.challengeAthleteId,
			challengeId: response.challengeId,
			athleteId: response.athleteId,
			userDataAPIId: response.userDataAPIId,
			isPaused: response.isPaused,
			isChallengeComplete: false,
			waiverAccepted: response.waiverAccepted,
			validicApp: response.validicApp,
			activityType: response.activityType,
			//Just added these
			finished: true,
			paid: response.paid,
			charged: charged,
			transactionId: transactionId,
			isPaused: response.isPaused,
			isNew: response.isNew
		}));
		*/
		dispatch(onSelectChallenge(response.challengeAthleteId));
		dispatch(showMessage(response.message, n.NOTIFICATIONS_SUCCESS));
		dispatch(clearBundle());
		//dispatch(push('/races/' + challenge.summary.challengeUrl + '/thank-you'));
	}
}

const postRejected = (message) => dispatch => {
	return dispatch(error(message));
}

export const removeAthleteFromChallenge = challengeAthleteId => dispatch => {
	return dispatch(deleteChallengeAthlete(FETCH_CHALLENGE_API + challengeAthleteId));
}

function prepareCartObject(state) {
	var challenge = state.challenge;
	var cart = assign({}, challenge.cart);
	var total = totalSelector(state);
	//TODO: Might not need to set this, once we're pulling the cart in properly
	cart.challengeId = challenge.summary.challengeId;
	cart.total = total;
	cart.subTotal = subTotalSelector(state);
	cart.totalPrePromotion = totalPrePromotionSelector(state);
	cart.salesTax = salesTaxTotalSelector(state); //This will contain just the amount that was added in sales tax
	cart.salesTaxable = salesTaxableSelector(state);
	cart.geoCounty = challenge.salesTax.geoCounty;
	cart.salesTaxRate = challenge.salesTax.taxSales; //This might be useful to know on the server-side
	cart.internationalShipping = shippingSelector(state); //This might be useful to know on the server-side
	cart.items = [];
	cart.predefinedGiftCard = {};
	[...challenge.rewards.concat(challenge.addons)].filter((item) => item.selected).map((item) => {
		var description = item.itemName; //item.itemDescription ? item.itemDescription : item.itemName;
		if (item.challengeAddonOptionName !== null && item.challengeAddonOptionName !== '' && typeof item.challengeAddonOptionName !== undefined)
			description += ' (' + item.challengeAddonOptionName + ')';
		cart.items.push({
			challengeAthleteAddonSelectionId: item.challengeAthleteAddonSelectionId,
			challengeAddonId: item.challengeAddonId,
			challengeAddonOptionId: item.challengeAddonOptionId != null ? item.challengeAddonOptionId : '0',
			description: description,
			itemName: item.itemName,
			itemType: item.itemType,
			itemPrice: item.itemPrice,
			itemSent: item.itemSent,
			status: item.status,
			sentTS: item.sentTS,
			challengeAddonType: item.challengeAddonType
		});
	});
	return cart;
}

export const checkout = () => (dispatch, getState) => {
	const state = getState();
	var cart = prepareCartObject(state);
	return dispatch(postChallengeAthlete(FETCH_CHALLENGE_API, cart));
}

export function deleteChallengeAthleteResolved() {
	return dispatch => {
		dispatch(showMessage('Athlete successfully removed from Race', n.NOTIFICATIONS_SUCCESS));
		dispatch(push('/races'));
	}
}

export function selectReward(index, selected) {
	return (dispatch, getState) => {
		var challenge = getState().challenge;
		var totalSelected = [...challenge.rewards].filter((item) => item.selected).length;
		if (selected)
			totalSelected++;
		else
			totalSelected--;
		if (totalSelected == 0 && !challenge.summary.isAddonOptional) {
			//Don't let them unselect the last one if this is a paid challenge
			dispatch(handleCartRewardFieldChange('selected', index, true));
		}
		else {
			dispatch(handleCartRewardFieldChange('selected', index, selected));
		}
	}
}

export function selectAddon(index, selected) {
	return dispatch => {
		dispatch(handleCartAddonFieldChange('selected', index, selected));
	}
}

export function handleFieldChange(key, value) {
	return {
		type: HANDLE_FIELD_CHANGE,
		key: key,
		value: value
	}
}

export function handleCartFieldChange(key, value) {
	return {
		type: HANDLE_CART_FIELD_CHANGE,
		key: key,
		value: value
	}
}

export function handleCartObjectChange(object) {
	return {
		type: HANDLE_CART_OBJECT_CHANGE,
		object: object
	}
}

export function handleCartRewardFieldChange(key, index, value) {
	return {
		type: HANDLE_CART_REWARD_FIELD_CHANGE,
		key: key,
		index: index,
		value: value
	}
}

export function handleCartAddonFieldChange(key, index, value) {
	return {
		type: HANDLE_CART_ADDON_FIELD_CHANGE,
		key: key,
		index: index,
		value: value
	}
}

export const addToBundle = (addAnother) => (dispatch, getState) => {
	const state = getState();
	var cart = prepareCartObject(state);
	var item = {
		type: 'challenge',
		name: state.challenge.summary.name,
		challengeUrl: state.challenge.summary.challengeUrl,
		challengeId: cart.challengeId,
		challengeAthleteId: cart.challengeAthleteId, //Might not need this
		items: cart.items,
		imageUrl: state.challenge.summary.imageUrl,
		price: state.challenge.summary.price,
		addonDiscount: state.challenge.summary.addonDiscount,
		waiverAccepted: state.challenge.summary.waiverAccepted,
		discount: 0, //Put the actual discount in here if multiple items are selected
		itemTotal: 0, //Same for this
		//These next 2 properties are for sending to Branch on purchase
		logo: state.challenge.summary.logo,
		fundraisingMessage: state.challenge.summary.fundraisingMessage,
		isSwag: state.challenge.summary.isSwag
	};
	if (addAnother) {
		//dispatch(push('/races'));
	}
	tagsAddToCart(state.challenge.summary);
	return dispatch({
		type: ADD_TO_BUNDLE,
		key: cart.challengeId,
		item: item
	});
}

export const removeFromBundle = (key) => (dispatch) => {
	return dispatch({
		type: REMOVE_FROM_BUNDLE,
		key: key
	});
}

export const clearBundle = () => (dispatch) => {
	return dispatch({
		type: CLEAR_BUNDLE
	});
}

export function saveBundle(bundle) {
	storageService.save(KEYS.USER_BUNDLE, bundle);
}

export const loadBundle = () => dispatch => {
	storageService.load(KEYS.USER_BUNDLE)
		.then(response => {
			if (response) {
				dispatch({ type: LOAD_BUNDLE, response: response });
			}
		});
}
