import {
    POST_REQUESTED,
    POST_RESOLVED,
    POST_REJECTED
} from './actions';
import assign from 'lodash.assign';

const initialState = {
    isSaving: false,
    isSaved: false,
    hasError: false,
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case POST_RESOLVED:
            return assign({}, state, {
                data: action.response,
                hasError: false,
                error: null,
                isSaving: false,
                isSaved: true
            });
        case POST_REQUESTED:
            return assign({}, state, {
                ...state,
                isSaving: true,
                isSaved: false,
                hasError: false,
                error: null
            })
        case POST_REJECTED:
            return assign({}, state, {
                ...state,
                isSaving: false,
                isSaved: false,
                hasError: true
                //error: action.response.message
            })
        default:
            return state;
    }
}