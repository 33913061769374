import {
    ALLGROUPS_FETCH_REQUESTED,
    ALLGROUPS_FETCH_RESOLVED,
    ALLGROUPS_FETCH_REJECTED,
    GROUP_FETCH_REQUESTED,
    GROUP_FETCH_RESOLVED,
    GROUP_FETCH_REJECTED,
    HANDLE_OBJECT_CHANGE,
    HANDLE_FIELD_CHANGE
} from './actions';
import assign from 'lodash.assign';

const initialState = {
    groups: [],
    groupId: 0,
    selectedGroupId: 0,
    selectedGroup: {},
    hasError: false,
    error: null,
    isLoading: false,
    isSaving: false,
    refresh: false,
    filter: ''
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ALLGROUPS_FETCH_RESOLVED:
            return assign({}, state, {
                groups: action.response.groups,
                hasError: false,
                isLoading: false,
                isSaving: false,
                error: null
            });
        case GROUP_FETCH_RESOLVED:
            return assign({}, state, {
                selectedGroup: action.response.group,
                hasError: false,
                isLoading: false,
                isSaving: false,
                error: null
            });
        case GROUP_FETCH_REQUESTED:
        case ALLGROUPS_FETCH_REQUESTED:
            return assign({}, state, {
                isLoading: true
            })
            
        /* case POST_REQUESTED:
            return assign({}, state, {
                isSaving: true
            })
        case POST_REJECTED: */
        case GROUP_FETCH_REJECTED:
        case ALLGROUPS_FETCH_REJECTED:
            return assign({}, state, {
                isLoading: false,
                isSaving: false,
                hasError: true,
                error: action.message
            })
            case HANDLE_FIELD_CHANGE:
                var new_state = assign({}, state);
                new_state[action.field] = action.value;
                return new_state;
        default:
            return state;
    }
}