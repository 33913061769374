import {
    NOTIFICATIONS_NOTIFY,
    NOTIFICATIONS_MESSAGE
} from './constants';

export function notificationRequested(title, content, level) {
    return {
        type: NOTIFICATIONS_NOTIFY,
        level: level,
        title: title,
        message: content
    };
}

export function messageRequested(content, level) {
    return {
        type: NOTIFICATIONS_MESSAGE,
        level: level,
        message: content
    };
}