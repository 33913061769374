'use strict';
import { CALL_API } from '../../common/middleware/api'
import { push } from '../../common/actions/navigate';

export const FETCH_CHALLENGES_REQUESTED = 'yesfit/ChooseRace/FETCH_CHALLENGES_REQUESTED';
export const FETCH_CHALLENGES_RESOLVED = 'yesfit/ChooseRace/FETCH_CHALLENGES_RESOLVED';
export const FETCH_CHALLENGES_REJECTED = 'yesfit/ChooseRace/FETCH_CHALLENGES_REJECTED';
export const FETCH_CHALLENGES_FIELD_CHANGE = 'yesfit/ChooseRace/FETCH_CHALLENGES_FIELD_CHANGE';
export const FETCH_CHALLENGES_DISTANCE_CHANGE = 'yesfit/ChooseRace/FETCH_CHALLENGES_DISTANCE_CHANGE';
export const FETCH_CHALLENGES_MEMBERSHIP_CHANGE = 'yesfit/ChooseRace/FETCH_CHALLENGES_MEMBERSHIP_CHANGE';
export const FETCH_CHALLENGES_RESET_FILTERS = 'yesfit/ChooseRace/FETCH_CHALLENGES_RESET_FILTERS';
export const FETCH_CHALLENGES_API = '/api/v1/SearchChallenges';
export const FETCH_CHALLENGES_NATIVE_DISTANCE_CHANGE = 'yesfit/ChooseRace/FETCH_CHALLENGES_NATIVE_DISTANCE_CHANGE';
export const FETCH_CHALLENGES_NATIVE_SORT_CHANGE = 'yesfit/ChooseRace/FETCH_CHALLENGES_NATIVE_SORT_CHANGE';
export const FETCH_CHALLENGES_BOOLEAN_FILTER = 'yesfit/ChooseRace/FETCH_CHALLENGES_BOOLEAN_FILTER';
export const HANDLE_FILTER_FIELD_CHANGE = 'yesfit/ChooseRace/HANDLE_FILTER_FIELD_CHANGE';

const fetchChallenges = endpoint => ({
    [CALL_API]: {
        types: [FETCH_CHALLENGES_REQUESTED, FETCH_CHALLENGES_RESOLVED, FETCH_CHALLENGES_REJECTED],
        endpoint: endpoint,
        method: 'GET'
    }
})

export const loadChallenges = () => dispatch => {
    return dispatch(fetchChallenges(FETCH_CHALLENGES_API));
}

export function handleChallengeFieldChange(key, value) {
    return {
        type: FETCH_CHALLENGES_FIELD_CHANGE,
		key: key,
		value: value
    };
}

export function handleSelectDistance(key, value) {
    return {
        type: FETCH_CHALLENGES_DISTANCE_CHANGE,
		key: key,
		value: value
    };
}

export function handleSelectMembership(key, value) {
    return {
        type: FETCH_CHALLENGES_MEMBERSHIP_CHANGE,
		key: key,
		value: value
    };
}

export function handleSelectNativeDistance(key, value) {
    return {
        type: FETCH_CHALLENGES_NATIVE_DISTANCE_CHANGE,
		key: key,
		value: value
    };
}

export function handleSelectBooleanFilter(key, value) {
    return {
        type: FETCH_CHALLENGES_BOOLEAN_FILTER,
		key: key,
		value: value
    };
}

export function handleNativeSelectSort(value) {
    return {
        type: FETCH_CHALLENGES_NATIVE_SORT_CHANGE,
		value: value
    };
}

export const handleFilterReset = () => {
    return {
        type: FETCH_CHALLENGES_RESET_FILTERS
    };
}

export const selectEventTypeFilter = (value) => {
    return {
        type: HANDLE_FILTER_FIELD_CHANGE,
        key: 'typeFilterId',
		value: value
    };
}

export const goToEvents = (typeFilterId) => dispatch => {
    dispatch(push('/all-races'));
    return dispatch(selectEventTypeFilter(typeFilterId));
}
