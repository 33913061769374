import {
	NEXT,
	BACK,
	ERROR,
	RESET,
	CLEAR,
	GOTO
} from './actions';
import assign from 'lodash.assign';

const initialState = {
	stepIndex: 0,
	hasError: false,
	message: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case NEXT:
			return assign({}, state, {
				stepIndex: state.stepIndex + 1,
				hasError: false,
				message: null
			});
		case BACK:
			return assign({}, state, {
				stepIndex: state.stepIndex - 1,
				hasError: false,
				message: null
			});
		case RESET:
			return assign({}, state, {
				stepIndex: 0,
				hasError: false,
				message: null
			});
		case ERROR:
			return assign({}, state, {
				hasError: true,
				message: action.message
			});
		case CLEAR:
			return assign({}, state, {
				hasError: false,
				message: null
			});
		case GOTO:
			return assign({}, state, {
				stepIndex: action.stepIndex
			});
		default:
			return state;
	}
}
