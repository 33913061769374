import {
    FETCH_ACTIVITY_TYPES_REQUESTED,
    FETCH_ACTIVITY_TYPES_RESOLVED,
    FETCH_ACTIVITY_TYPES_REJECTED
} from './actions';
import assign from 'lodash.assign';

const initialState = {
    activityTypes: [{key: 0, value: 'Select Activity Type'}],
    hasError: false,
    error: null,
    isLoading: false,
    isReady: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ACTIVITY_TYPES_RESOLVED:
            return assign({}, state, {
                activityTypes: action.response,
                hasError: false,
                isLoading: false,
                isReady: true,
                error: null
            });
        case FETCH_ACTIVITY_TYPES_REQUESTED:
            return assign({}, state, {
                isLoading: true,
                isReady: false
            })
        case FETCH_ACTIVITY_TYPES_REJECTED:
            return assign({}, state, {
                isLoading: false,
                hasError: true,
                isReady: false,
                error: action.error
            })
        default:
            return state;
    }
}