import { createSelector } from 'reselect'

export const currentAthleteSelector = createSelector(
	state => state.groupAthletes.key,
	state => state.groupAthletes.data.group.athletes,
	(key, athletes) => athletes.find((athlete) => athlete.key === key)
)

export const dirtyAthletesSelector = createSelector(
	state => state.groupAthletes.data.group,
	state => state.groupAthletes, //This selector doesn't fire without this in here
	(group, _) => ({
		...group,
		followGroupNotifications: true, //This can be removed when it's removed from the API
		athletes: [
			...group.athletes.filter(item => item.isDirty === true || item.isDelete === true)
		]
	})
)

export const filteredAthletesSelector = createSelector(
	state => state.groupAthletes.data.group.athletes,
	state => state.groupAthletes.filter,
	(athletes, filterString) => {
		if (!filterString) {
			return athletes;
		}
		return athletes.filter((athlete) => !!athlete && !!athlete.name && athlete.name.toLowerCase().includes(filterString?.toLowerCase()) ||
			(athlete.inviteEmail !== null && athlete.inviteEmail?.toLowerCase().includes(filterString?.toLowerCase())) ||
			(athlete.email !== null && athlete.email?.toLowerCase().includes(filterString?.toLowerCase())));
	}
)