export class MapUtils {
    bearing(path, v1, v2) {
		var poly = new google.maps.Polyline({
			path: path
		});
        return poly.Bearing(v1, v2);
    }

	processMarkers(map, path, markersIn) {
		var poly = new google.maps.Polyline({
			path: path
		});
		var markers = markersIn.slice(0);
		//Google deprecated https://chart.apis.google.com, so we have to create our pins differently (using SVG mark-up... actually looks pretty good
		/*
		markers.map((marker) => {
			var pinImage = new google.maps.MarkerImage(`https://chart.apis.google.com/chart?chst=d_map_pin_letter_withshadow&chld=%E2%80%A2|${marker.color}`,
				new google.maps.Size(35, 35),
				new google.maps.Point(0, 0),
				new google.maps.Point(10, 34));
			marker.icon = pinImage;
		});
		*/
		markers.map((marker) => {
			marker.icon = {
				path: 'M31 62c0-17.1 16.3-25.2 17.8-39.7A18 18 0 1 0 13 20a17.7 17.7 0 0 0 .2 2.2C14.7 36.8 31 44.9 31 62z',
				fillColor: '#' + marker.color,
				fillOpacity: 1.0,
				strokeColor: '#000000',
				strokeWeight: 1,
				scale: 0.5,
				anchor: new google.maps.Point(30, 64)
			}
		});
        this.zoomToObject(map, poly);
        return markers;
	}

	zoomToObject(map, poly) {
		var bounds = new google.maps.LatLngBounds();
		var points = poly.getPath().getArray();
		for (var n = 0; n < points.length; n++) {
			bounds.extend(points[n]);
		}
		map.fitBounds(bounds);
	}
}
