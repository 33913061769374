import {
	FETCH_CAR_REQUESTED,
	FETCH_CAR_RESOLVED,
	FETCH_CAR_REJECTED,
	POST_CAR_REQUESTED,
	POST_CAR_RESOLVED,
	POST_CAR_REJECTED,
	DELETE_CAR_REQUESTED,
	DELETE_CAR_RESOLVED,
	DELETE_CAR_REJECTED,
	HANDLE_FIELD_CHANGE
} from './actions';
import { DASHBOARD_REFRESH } from '../Dashboard/actions';
import assign from 'lodash.assign';

const initialState = {
	requests: [],
	hasError: false,
	error: null,
	isLoading: false,
	isSaving: false,
	editMode: false,
	hasModalError: false,
	refresh: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case FETCH_CAR_RESOLVED:
			return assign({}, state, {
				...state,
				requests: action.response.requests,
				hasError: false,
				isLoading: false,
				error: null
			});
		case FETCH_CAR_REQUESTED:
			return assign({}, state, {
				...state,
				refresh: false,
				isLoading: true
			});
		case FETCH_CAR_REJECTED:
			return assign({}, state, {
				...state,
				isLoading: false,
				hasError: true,
				error: action.message
			});
		case DELETE_CAR_REQUESTED:
		case POST_CAR_REQUESTED:
			return assign({}, state, {
				...state,
				hasModalError: false,
				isLoading: true
			});
		case DELETE_CAR_REJECTED:
		case POST_CAR_REJECTED:
			return assign({}, state, {
				...state,
				isLoading: false,
				hasModalError: true,
				error: action.message
			});
		case POST_CAR_RESOLVED:
			return assign({}, state, {
				...state,
				isLoading: false,
				isSaving: false,
				error: null,
				hasModalError: false,
				editMode: false,
				refresh: true
			});
		case DELETE_CAR_RESOLVED:
			return assign({}, state, {
				...state,
				requests: state.requests.filter(item => item.athleteConnectChallengeId !== action.response.id),
				isLoading: false,
				isSaving: false,
				error: null,
				hasModalError: false,
				refresh: true
				//editMode: false
			});
		case HANDLE_FIELD_CHANGE:
			var new_state = assign({}, state);
			new_state[action.key] = action.value;
			return new_state;
		case DASHBOARD_REFRESH:
			return assign({}, state, {
				...state,
				refresh: true
			});
		default:
			return state;
	}
}
