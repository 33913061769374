'use strict';
import { CALL_API } from '../../common/middleware/api'
import { push } from '../../common/actions/navigate';

export const FETCH_REQUESTED = 'yesfit/TC/FETCH_REQUESTED';
export const FETCH_RESOLVED = 'yesfit/TC/FETCH_RESOLVED';
export const FETCH_REJECTED = 'yesfit/TC/FETCH_REJECTED';
export const POST_REQUESTED = 'yesfit/TC/POST_REQUESTED';
export const POST_RESOLVED = 'yesfit/TC/POST_RESOLVED';
export const POST_REJECTED = 'yesfit/TC/POST_REJECTED';
export const HANDLE_FIELD_CHANGE = 'yesfit/TC/HANDLE_FIELD_CHANGE';

const API = '/api/v1/TileChallenge';

export const fetch = (challengeId, challengeAthleteId) => ({
    [CALL_API]: {
        types: [FETCH_REQUESTED, FETCH_RESOLVED, FETCH_REJECTED],
        endpoint: `${API}/${challengeId}/${challengeAthleteId}`,
        method: 'GET'
    }
})

export const post = (body) => ({
    [CALL_API]: {
        types: [POST_REQUESTED, POST_RESOLVED, POST_REJECTED],
        endpoint: API,
        method: 'POST',
        body: body,
        onResolved: postResolved
    }
})

const postResolved = (response) => dispatch => {
    if (response.tileChallenge.isCompleted) {
        //Go back to the previou screen if this completed the challenge, as completions aren't done from the main screen
        // dispatch(pop()); 
    }
}

export const completeTile = (tileId) => (dispatch, getState) => {
    const state = getState();
    const body = {
        challengeId: state.tileChallenge.data.challengeId,
        challengeAthleteId: state.dashboard.challengeAthleteId,
        tileId: tileId
    };
    return dispatch(post(body));
}

export function handleFieldChange(key, value) {
    return {
        type: HANDLE_FIELD_CHANGE,
        key: key,
        value: value
    }
}

export const gotoDetails = (tileId) => dispatch => {
    dispatch(handleFieldChange('selectedTileId', tileId));
    return dispatch(push('/challenge-details'));
}