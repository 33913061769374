import {
    FETCH_APPS_REQUESTED,
    FETCH_APPS_RESOLVED,
    FETCH_APPS_REJECTED,
    APPS_REFRESH
} from './actions';
import { ATHLETE_APPS_FRESH_REFRESH } from '../AthleteApp/actions';
import assign from 'lodash.assign';

const initialState = {
    apps: [],
    hasError: false,
    error: null,
    isLoading: false,
    isReady: false,
    isLoaded: false,
    refresh: false,
    appsAdded: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_APPS_RESOLVED:
            return assign({}, state, {
                ...state,
                apps: action.response.apps,
                hasError: false,
                isLoading: false,
                isReady: true,
                error: null,
                isLoaded: true,
                refresh: false
            });
        case FETCH_APPS_REQUESTED:
            return assign({}, state, {
                ...state,
                isLoading: true,
                isReady: false,
                isLoaded: true,
                refresh: false
            })
        case FETCH_APPS_REJECTED:
            return assign({}, state, {
                ...state,
                isLoading: false,
                hasError: true,
                isReady: false,
                error: action.error,
                isLoaded: true,
                refresh: false
            })
        case APPS_REFRESH:
        case ATHLETE_APPS_FRESH_REFRESH:
            return assign({}, state, {
                ...state,
                refresh: true,
                appsAdded: true
            })
        default:
            return state;
    }
}