import { createSelector } from 'reselect'
import { prependAssetHostChallenge } from '../../common/utils.js';
import moment from 'moment';

export const challengeHistorySelector = state => state.challengeHistory.history;

export const timelineSelector = createSelector(
	challengeHistorySelector,
	(history) => history.map((item, i) => {
		var text = '';
		if (item.isSwag)
			text = item.orderSent ? 'MAILED' : 'PACKING';
		else if (item.isChallengeComplete)
			text = item.orderSent ? 'MAILED' : 'COMPLETED';
		else if (item.isPaused)
			text = 'PAUSED';
		else if (item.waiverAccepted === null)
			text = 'NOT STARTED';
		else if (moment().isBefore(item.startDateLocal))
			text = 'STARTING ' + moment(item.startDateLocal).calendar();
		else
			text = 'IN PROGRESS';
		return {
			...item,
			order: i,
			key: item.challengeAthleteId,
			id: item.challengeAthleteId,
			date: moment(item.startDateLocal).toDate(),
			title: item.name,
			text: item?.status?.toUpperCase(),
			imageUrl: item.logo !== null ? prependAssetHostChallenge(item.logo) : '',
			extras: { challengeAthleteId: item.challengeAthleteId },
			status: item?.status?.toUpperCase(),
			challengeType: item.isSwag ? 'SWAG' : (item.is30Day ? 'CHALLENGE' : 'VIRTUAL RACE')
		}
	})
);

export const incompleteSelector = createSelector(
	timelineSelector,
	(history) => history.filter((item) => !item.isChallengeComplete && !item.isSwag)
);

export const incompleteWithDevicesSelector = createSelector(
	incompleteSelector,
	(history) => history.filter((item) => !item.hideDevices)
);

export const completeSelector = createSelector(
	timelineSelector,
	(history) => history.filter((item) => item.isChallengeComplete && !item.isSwag)
);

export const sectionSelector = createSelector(
	timelineSelector,
	(history) => {
		let result = [
			{
				id: 'races',
				title: 'My Races',
				items: history.filter(item => !item.is30Day && !item.isSwag).sort((a, b) => a.order > b.order) //Without this, the list order is random
			},
			{
				id: 'challenges',
				title: 'My Challenges',
				items: history.filter(item => item.is30Day).sort((a, b) => a.order > b.order) //Without this, the list order is random
			}
			
		]
		return result
	}
);

export const orderHistorySelector = createSelector(
	timelineSelector,
	(history) => history.sort((a, b) => new Date(b.purchaseDate).getTime() - new Date(a.purchaseDate).getTime())
);

/*
export const notStartedSelector = createSelector(
	incompleteSelector,
	(history) => history.filter((item) => item.waiverAccepted === null || item.isPaused)
);
*/