import {
	FETCH_ATHLETE_APPS_REQUESTED,
	FETCH_ATHLETE_APPS_RESOLVED,
	FETCH_ATHLETE_APPS_REJECTED,
	HANDLE_FIELD_CHANGE,
	ATHLETE_APPS_FRESH_REFRESH,
	NATIVE_APPS_RESOLVED
} from "./actions";
import assign from "lodash.assign";

const initialState = {
	data: {
		apps: [],
		yfApps: [],
		marketplaceUrl: null
	},
	hasError: false,
	error: null,
	isLoading: false,
	isReady: false,
	isLoaded: false,
	visible: false,
	freshConnections: false,
	refresh: false,
	showInstructions: false,
	webViewUrl: null, //For native
	fitnessApp: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case FETCH_ATHLETE_APPS_RESOLVED:
			return assign({}, state, {
				...state,
				data: action.response.connectApps,
				hasError: false,
				isLoading: false,
				isReady: true,
				error: null,
				isLoaded: true,
				refresh: false
			});
		case FETCH_ATHLETE_APPS_REQUESTED:
			return assign({}, state, {
				...state,
				isLoading: true,
				isReady: false,
				isLoaded: false,
				refresh: false,
				freshConnections: false
			});
		case FETCH_ATHLETE_APPS_REJECTED:
			return assign({}, state, {
				...state,
				isLoading: false,
				hasError: true,
				isReady: false,
				error: action.message,
				isLoaded: true,
				refresh: false,
				freshConnections: false
			});
		case ATHLETE_APPS_FRESH_REFRESH:
			return assign({}, state, {
				...state,
				freshConnections: false,
				refresh: true
			});
		case HANDLE_FIELD_CHANGE:
			var new_state = assign({}, state);
			new_state[action.key] = action.value;
			return new_state;
		case NATIVE_APPS_RESOLVED: {
			return assign({}, state, {
				fitnessApp: action.nativeApp
			});
		}
		default:
			return state;
	}
};
