import {
    FETCH_REQUESTED,
    FETCH_RESOLVED,
    FETCH_REJECTED
} from './actions';
import assign from 'lodash.assign';

const initialState = {
    goalTypes: [{key: 0, value: 'Select Goal'}],
    hasError: false,
    error: null,
    isLoading: false,
    isReady: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RESOLVED:
            return assign({}, state, {
                goalTypes: action.response,
                hasError: false,
                isLoading: false,
                isReady: true,
                error: null
            });
        case FETCH_REQUESTED:
            return assign({}, state, {
                isLoading: true,
                isReady: false
            })
        case FETCH_REJECTED:
            return assign({}, state, {
                isLoading: false,
                hasError: true,
                isReady: false,
                error: action.error
            })
        default:
            return state;
    }
}