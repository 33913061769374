'use strict';
import {
    NOTIFICATIONS_NOTIFY,
    NOTIFICATIONS_MESSAGE
} from './constants';
import assign from 'lodash.assign';

const initialState = {
    title: null,
    message: null,
    level: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case NOTIFICATIONS_NOTIFY:
            return assign({}, state, {
                title: state.title,
                message: state.message,
                level: state.level
            });
        case NOTIFICATIONS_MESSAGE:
            return assign({}, state, {
                message: state.message,
                level: state.level
            });
        default:
            return state;
    }
}