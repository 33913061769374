import assign from 'lodash.assign';
import {
	LOGIN_RESOLVED,
	LOGIN_REQUESTED,
	LOGIN_REJECTED,
	LOGOUT,
	LOGIN_PROFILE_READY,
	LOGIN_HANDLE_FIELD_CHANGE,
	PROFILE_HANDLE_FIELD_CHANGE,
	PROFILE_HANDLE_OBJECT_CHANGE,
	PROFILE_NOT_FOUND
} from './actions';
import { POST_RESOLVED as POST_SUB_RESOLVED } from '../Subscription/actions';
import { FETCH_RESOLVED as SHARE_FETCH_RESOLVED } from '../ShareRaceButtons/actions';
import { KEYS } from '../../common/constants/localStorage';

const initial_state = {
	profile: {
		isAuthenticated: false,
		isAdmin: false,
		accessToken: null, //for single sign-on
		email: '',
		firstNameForPixel: '',
		lastNameForPixel: '',
		cityForPixel: '',
		stateForPixel: '',
		zipForPixel: '',
		dobForPixel: '',
		genderForPixel: '',
		welcomedToJoinRace: false,
		fbAccessToken: '',
		stripeSubscriptionId: null,
		stripePlanName: null,
		voucherifyReferralCode: null
	},
	fbExistingAccessToken: null,
	isLoggingIn: false,
	loadingProfile: true,
	profileNotFound: false,
	loginRejected: false,
	loginRejectedMessage: null,
	message: null,
	isNewUser: false,
	returnUrl: null,
	loadChallenge: null, //Used during wizard login
	isProfileReady: false,
	saveProfile: false,
	//For React Native
	register: {
		username: '',
		firstname: '',
		lastname: '',
		timezone: 'Eastern Standard Time',
		password: '',
		confirmPassword: '',
		isNewUser: false
	}
};

export default (state = initial_state, action) => {
	switch (action.type) {
		case LOGIN_RESOLVED:
			return loginResolved(state, action);
		case LOGIN_REQUESTED:
			return loginRequested(state);
		case LOGIN_REJECTED:
			return loginRejected(state, action);
		case LOGIN_PROFILE_READY:
			return loginProfileReady(state);
		case LOGIN_HANDLE_FIELD_CHANGE:
			return handleFieldChange(state, action);
		case PROFILE_NOT_FOUND:
			return handleProfileNotFound(state, action);
		case PROFILE_HANDLE_FIELD_CHANGE:
			return handleProfileFieldChange(state, action);
		case PROFILE_HANDLE_OBJECT_CHANGE:
			return assign({}, state, {
				...state,
				profile: {
					...state.profile,
					...action.object
				},
				saveProfile: true
			});
		case LOGOUT:
			return logout(state);
		case KEYS.USER_PROFILE:
			var new_state = assign({}, state);
			new_state.saveProfile = false;
			return new_state;
		case POST_SUB_RESOLVED: //After a subscription is created
			if (action.response.isGroup) {
				return assign({}, state);
			}
			return assign({}, state, {
				...state,
				profile: {
					...state.profile,
					stripeSubscriptionId: action.response.plan.subscriptionId,
					stripePlanName: action.response.plan.metadata.Name
				},
				saveProfile: true
			})
		case SHARE_FETCH_RESOLVED:
			return assign({}, state, {
				...state,
				profile: {
					...state.profile,
					voucherifyReferralCode: action.response.voucherifyReferralCode
				},
				saveProfile: true
			});
		default:
			return state;
	}
}

function handleFieldChange(state, action) {
	var new_state = assign({}, state);
	new_state[action.key] = action.value;
	return new_state;
}

function handleProfileFieldChange(state, action) {
	var new_state = assign({}, state);
	new_state.profile[action.key] = action.value;
	new_state.saveProfile = true;
	return new_state;
}
function handleProfileNotFound(state, action) {
	return Object.assign({}, state, {
		profileNotFound: true,
		isLoggingIn: false,
	})
}
function loginResolved(state, action) {
	var new_state = Object.assign({}, state, {
		message: null,
		profile: action.response,
		loadingProfile: false,
		loginRejected: false,
		isLoggingIn: false,
		isProfileLoaded: true
	});
	return new_state;
}

function loginRejected(state, action) {
	return Object.assign({}, state, {
		message: action.message,
		profile: { isAuthenticated: false, isAdmin: false },
		isLoggingIn: false,
		loadingProfile: false,
		loginRejected: true
	});
}

function loginRequested(state) {
	return Object.assign({}, state, {
		isLoggingIn: true,
		loadingProfile: true,
		loginRejected: false,
		message: null
	});
}

function loginProfileReady(state) {
	return Object.assign({}, state, {
		isProfileReady: true,
		loadingProfile: false
	});
}

function logout(state) {
	return Object.assign({}, state, {
		...initial_state
	});
}
