'use strict';
import { CALL_API } from '../../common/middleware/api';
export const POST_REQUESTED = 'yesfit/VG/POST_REQUESTED';
export const POST_RESOLVED = 'yesfit/VG/POST_RESOLVED';
export const POST_REJECTED = 'yesfit/VG/POST_REJECTED';
export const HANDLE_FIELD_CHANGE = 'yesfit/VG/HANDLE_FIELD_CHANGE'
export const HANDLE_CART_FIELD_CHANGE = 'yesfit/VG/HANDLE_CART_FIELD_CHANGE'
import { next, error } from '../Steppers/actions.js';
import ReactGA from 'react-ga';

const VG_API = '/api/v1/giftvoucher';

export const createGift = (body) => ({
    [CALL_API]: {
        types: [POST_REQUESTED, POST_RESOLVED, POST_REJECTED],
        endpoint: VG_API,
        method: 'POST',
        body: body,
        onResolved: createGiftResolved,
        onRejected: postRejected
    }
})

const createGiftResolved = (response) => dispatch => {
    ReactGA.plugin.require('ecommerce');
    ReactGA.plugin.execute('ecommerce', 'addTransaction', {
        id: `${response.code}`,
        affiliation: 'YES.FIT',
        revenue: `${response.cart.charged}`,
        shipping: '0.00',
        tax: '0.00'
    });
    ReactGA.plugin.execute('ecommerce', 'addItem', {
        id: `${response.code}`,
        name: `$${response.cart.charged} Gift Voucher`,
        category: 'Gift Voucher',
        price: `${response.cart.charged}`,
        quantity: '1'
    });
    ReactGA.plugin.execute('ecommerce', 'send');
    dispatch(next());
}

const postRejected = (message) => dispatch => {
    return dispatch(error(message));
}

export function handleFieldChange(key, value) {
    return {
        type: HANDLE_FIELD_CHANGE,
        key: key,
        value: value
    }
}

export function handleCartFieldChange(key, value) {
    return {
        type: HANDLE_CART_FIELD_CHANGE,
        key: key,
        value: value
    }
}