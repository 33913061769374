'use strict';
import { CALL_API } from '../../common/middleware/api'
import { unseenFeedItemSelector } from './selectors';

export const FETCH_REQUESTED = 'yesfit/NF/FETCH_REQUESTED';
export const FETCH_RESOLVED = 'yesfit/NF/FETCH_RESOLVED';
export const FETCH_REJECTED = 'yesfit/NF/FETCH_REJECTED';
export const POST_REQUESTED = 'yesfit/NF/POST_REQUESTED';
export const POST_RESOLVED = 'yesfit/NF/POST_RESOLVED';
export const POST_REJECTED = 'yesfit/NF/POST_REJECTED';
export const LIKE_REQUESTED = 'yesfit/NF/LIKE_REQUESTED';
export const LIKE_RESOLVED = 'yesfit/NF/LIKE_RESOLVED';
export const LIKE_REJECTED = 'yesfit/NF/LIKE_REJECTED';
export const LIKERS_REQUESTED = 'yesfit/NF/LIKERS_REQUESTED';
export const LIKERS_RESOLVED = 'yesfit/NF/LIKERS_RESOLVED';
export const LIKERS_REJECTED = 'yesfit/NF/LIKERS_REJECTED';
export const HANDLE_FIELD_CHANGE = 'yesFit/NF/HANDLE_FIELD_CHANGE';
export const FEED_POST_REFRESH = 'yesFit/NF/FEED_POST_REFRESH';

const API = '/api/v1/AthleteFeed';

const getFeed = (endpoint) => ({
    [CALL_API]: {
        types: [FETCH_REQUESTED, FETCH_RESOLVED, FETCH_REJECTED],
        endpoint: endpoint,
        method: 'GET'
    }
})

export const loadFeed = (groupId) => dispatch => {
    if (groupId === 0) {
        dispatch(getFeed(API));
    }
    else {
        dispatch(getFeed(`${API}/Feed/${groupId}`));
    }
}

const postFeed = body => ({
    [CALL_API]: {
        types: [POST_REQUESTED, POST_RESOLVED, POST_REJECTED],
        endpoint: API,
        method: 'POST',
        body: body
    }
})

export const likers = (item) => ({
    [CALL_API]: {
        types: [LIKERS_REQUESTED, LIKERS_RESOLVED, LIKERS_REJECTED],
        endpoint: `${API}/${item.athleteFeedId}`,
        method: 'GET'
    }
})

export const like = item => ({
    [CALL_API]: {
        types: [LIKE_REQUESTED, LIKE_RESOLVED, LIKE_REJECTED],
        endpoint: API,
        method: 'PUT',
        body: { athleteFeedId: item.athleteFeedId }
    }
})

export const updateFeed = () => (dispatch, getState) => {
    const state = getState();
    //Mark all unseen items as seen
    const unseenFeedItems = unseenFeedItemSelector(state);
    const seen = unseenFeedItems.map((item) => {
        return item.athleteFeedId
    });
    const body = {
        filter: state.athleteFeed.selectedGroupId,
        seen
    };
    return dispatch(postFeed(body));
}

export const updateFeedItem = (athleteFeedId) => (dispatch) => {
    //Mark a specific feed item as seen
    return dispatch(postFeed([athleteFeedId]));
}

export const handleFieldChange = (key, value) => {
    return {
        type: HANDLE_FIELD_CHANGE,
        key: key,
        value: value
    }
}

export const postRefresh = () => {
    return {
        type: FEED_POST_REFRESH
    }
}
