import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import { createHistory } from 'history';
import reduxMulti from 'redux-multi';
import reduxThunk from 'redux-thunk';
import reducers from '../common/reducers';
import api from '../common/middleware/api'
import { syncHistoryWithStore } from 'react-router-redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

//const history = syncHistoryWithStore(browserHistory, store)
const myHistory = createHistory();

const middlewares = applyMiddleware(
		routerMiddleware(myHistory),
		reduxThunk,
		api,
		reduxMulti);

export const store = createStore(reducers, composeWithDevTools(middlewares));
export const history = syncHistoryWithStore(myHistory, store);

if (module.hot) {
	// Enable Webpack hot module replacement for reducers
	module.hot.accept('../common/reducers', () => {
		const nextReducer = require('../common/reducers').default;
		store.replaceReducer(nextReducer);
	})
}
