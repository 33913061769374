import {
    FETCH_WORKOUTS_REQUESTED,
    FETCH_WORKOUTS_RESOLVED,
    FETCH_WORKOUTS_REJECTED,
    POST_WORKOUT_REQUESTED,
    POST_WORKOUT_RESOLVED,
    POST_WORKOUT_REJECTED,
    DELETE_WORKOUT_REQUESTED,
    DELETE_WORKOUT_RESOLVED,
    DELETE_WORKOUT_REJECTED,
    HANDLE_FIELD_CHANGE
} from './actions';
import { DASHBOARD_REFRESH } from '../Dashboard/actions';
import assign from 'lodash.assign';

const initialState = {
    items: [],
    userCardioActivityID: null, //The currently selected id
    hasError: false,
    error: null,
    isLoading: false,
    isSaving: false,
    editMode: false,
    showCompletion: false,
    confirmationMessage: null,
    refresh: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_WORKOUTS_RESOLVED:
            return assign({}, state, {
                items: action.response,
                hasError: false,
                isLoading: false,
                error: null
            });
        case FETCH_WORKOUTS_REQUESTED:
            return assign({}, state, {
                isLoading: true,
                refresh: false
            })
        case FETCH_WORKOUTS_REJECTED:
            return assign({}, state, {
                isLoading: false,
                hasError: true,
                error: action.error
            })
        case POST_WORKOUT_RESOLVED:
            var new_state = assign({}, state, {
                hasModalError: false,
                isSaving: false,
                error: null,
                editMode: false,
                userCardioActivityID: null,
                showCompletion: action.response.isChallengeComplete
            });
            if (state.userCardioActivityID === null) {
                //Insert the new one at the front of the array
                new_state.items = [action.response.workout, ...state.items];
            }
            else {
                //Update the existing array item
                new_state.items = state.items.map(
                    (item) => item.userCardioActivityID === action.response.workout.userCardioActivityID ? action.response.workout : item
                )
            }
            return new_state;
        case POST_WORKOUT_REQUESTED:
            return assign({}, state, {
                isSaving: true,
                hasModalError: false,
                showCompletion: false,
                error: null,
                refresh: false
            })
        case POST_WORKOUT_REJECTED:
            return assign({}, state, {
                isSaving: false,
                hasModalError: true,
                showCompletion: false,
                error: action.message
            })
        case DELETE_WORKOUT_RESOLVED:
            return assign({}, state, {
                items: state.items.filter(item => item.userCardioActivityID !== action.response.id),
                hasError: false,
                isSaving: false,
                error: null
            });
        case DELETE_WORKOUT_REQUESTED:
            return assign({}, state, {
                hasError: false,
                error: null,
                isSaving: true,
                refresh: false
            })
        case DELETE_WORKOUT_REJECTED:
            return assign({}, state, {
                isSaving: false,
                hasError: true,
                error: action.message
            })
        case DASHBOARD_REFRESH:
            return assign({}, state, {
                ...state,
                refresh: true
            })
        case HANDLE_FIELD_CHANGE:
            var new_state = assign({}, state);
            new_state[action.key] = action.value;
            return new_state;
        default:
            return state;
    }
}