'use strict';
import { CALL_API } from '../../../common/middleware/api';
import { showMessage } from '../../../common/notifications/actions';
import * as n from '../../../common/notifications/constants';
import { uuidv4 } from '../../../common/utils';
import { dirtyAthletesSelector } from './selectors';

export const FETCH_REQUESTED = 'yesfit/GroupAthletes/FETCH_REQUESTED';
export const FETCH_RESOLVED = 'yesfit/GroupAthletes/FETCH_RESOLVED';
export const FETCH_REJECTED = 'yesfit/GroupAthletes/FETCH_REJECTED';
export const POST_REQUESTED = 'yesfit/GroupAthletes/POST_REQUESTED';
export const POST_RESOLVED = 'yesfit/GroupAthletes/POST_RESOLVED';
export const POST_REJECTED = 'yesfit/GroupAthletes/POST_REJECTED';
export const UNSUB_REQUESTED = 'yesfit/GroupAthletes/UNSUB_REQUESTED';
export const UNSUB_RESOLVED = 'yesfit/GroupAthletes/UNSUB_RESOLVED';
export const UNSUB_REJECTED = 'yesfit/GroupAthletes/UNSUB_REJECTED';
export const HANDLE_FIELD_CHANGE = 'yesfit/GroupAthletes/HANDLE_FIELD_CHANGE';
export const HANDLE_ATHLETE_CHANGE = 'yesfit/GroupAthletes/HANDLE_ATHLETE_CHANGE';
export const HANDLE_ATHLETE_NEW = 'yesfit/GroupAthletes/HANDLE_ATHLETE_NEW';

export const GA_API = '/api/v1/groups/GroupAthlete';
const UT_API = '/api/v1/groups/UnsubscribeTopics';

export const fetchGroup = (groupId) => ({
    [CALL_API]: {
        types: [FETCH_REQUESTED, FETCH_RESOLVED, FETCH_REJECTED],
        endpoint: `${GA_API}/${groupId}`,
        method: 'GET'
    }
})

const postAthletes = (body) => ({
    [CALL_API]: {
        types: [POST_REQUESTED, POST_RESOLVED, POST_REJECTED],
        endpoint: `${GA_API}`,
        body: body,
        method: 'POST',
        onResolved: onSaveAthletesResolved
    }
})

export const saveAthletes = () => (dispatch, getState) => {
    var group = dirtyAthletesSelector(getState());
    return dispatch(postAthletes(group));
}

const onSaveAthletesResolved = (response) => dispatch => {
    dispatch(showMessage('Your changes have been saved', n.NOTIFICATIONS_INFO));
}

export const unsubscribeTopics = (groupId) => ({
    [CALL_API]: {
        types: [UNSUB_REQUESTED, UNSUB_RESOLVED, UNSUB_REJECTED],
        endpoint: `${UT_API}/${groupId}`,
        method: 'GET',
        onResolved: onUnsubscribeTopicsResolved
    }
})

const onUnsubscribeTopicsResolved = (response) => dispatch => {
    dispatch(showMessage(`Made ${response.count} subscription changes`, n.NOTIFICATIONS_INFO));
}

export const handleFieldChange = (key, value) => dispatch => {
    return dispatch({
        type: HANDLE_FIELD_CHANGE,
        key: key,
        value: value
    });
}

export const handleAthleteChange = (athlete) => dispatch => {
    return dispatch({
        type: HANDLE_ATHLETE_CHANGE,
        athlete: athlete
    });
}

const getNewAthlete = (email, groupId, groupAthleteType) => {
    const athlete = {
        key: uuidv4(),
        groupAthleteId: null,
        groupId: groupId,
        groupAthleteType: groupAthleteType,
        isDirty: true,
        athleteId: null,
        name: null,
        userName: null,
        email: email,
        inviteEmail: email,
        imgProfileUrl: '/athletes/0/Profile/profile-pic-placeholder.png',
        createTS: new Date()
    };
    return athlete;
}

export const handleAthleteNew = () => (dispatch, getState) => {
    const state = getState();
    const athlete = getNewAthlete(null, state.groupAthletes.data.group.groupId, 3);
    return dispatch({
        type: HANDLE_ATHLETE_NEW,
        athlete: athlete
    });
}

export const handleAddMultipleEmails = (emails, groupAthleteType) => (dispatch, getState) => {
    const emailList = emails.split(/,|\n/);
    const groupId = getState().groupAthletes.data.group.groupId;
    emailList.forEach(email => {
        //TODO: Do some email validation here
        if (email !== null && email.trim().length > 0) {
            const athlete = getNewAthlete(email, groupId, groupAthleteType);
            dispatch({
                type: HANDLE_ATHLETE_NEW,
                athlete: athlete
            });
        }
    });
}
