import {
    POST_REQUESTED,
    POST_RESOLVED,
    POST_REJECTED,
    RESET_RESET_PASSWORD
} from './actions';
import assign from 'lodash.assign';

const initialState = {
    newPassword: null,
    hasError: false,
    error: null,
    isSaving: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case POST_REQUESTED:
            return assign({}, state, {
                ...state,
                isSaving: true,
                error: null
            })
        case POST_RESOLVED:
            return assign({}, state, {
                ...state,
                newPassword: action.response.newPassword,
                isSaving: false,
                hasError: action.response.hasError,
                error: action.response.message
            })
        case POST_REJECTED:
            return assign({}, state, {
                ...state,
                isSaving: false,
                hasError: true,
                error: action.message
            })
        case RESET_RESET_PASSWORD:
            return assign({}, state, {
                ...initialState
            })
        default:
            return state;
    }
}