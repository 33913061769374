import {
    POST_REQUESTED,
    POST_RESOLVED,
    POST_REJECTED
} from './actions';
import assign from 'lodash.assign';

const initialState = {
    hasError: false,
    error: null,
    isSaving: false,
    isSaved: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case POST_REQUESTED:
            return assign({}, state, {
                isSaving: true,
                error: null
            })
        case POST_RESOLVED:
            return assign({}, state, {
                isSaving: false,
                hasError: action.response.hasError,
                error: action.response.message
            })
        case POST_REJECTED:
            return assign({}, state, {
                isSaving: false,
                hasError: true,
                error: action.message
            })
        default:
            return state;
    }
}