'use strict';
import { WORKOUTS_API } from './constants';
import { CALL_API } from '../../common/middleware/api'
import { challengeAthleteSelector } from '../Dashboard/selectors';

export const FETCH_WORKOUTS_REQUESTED = 'yesfit/Workouts/FETCH_WORKOUTS_REQUESTED';
export const FETCH_WORKOUTS_RESOLVED = 'yesfit/Workouts/FETCH_WORKOUTS_RESOLVED';
export const FETCH_WORKOUTS_REJECTED = 'yesfit/Workouts/FETCH_WORKOUTS_REJECTED';
export const POST_WORKOUT_REQUESTED = 'yesfit/Workouts/POST_WORKOUT_REQUESTED';
export const POST_WORKOUT_RESOLVED = 'yesfit/Workouts/POST_WORKOUT_RESOLVED';
export const POST_WORKOUT_REJECTED = 'yesfit/Workouts/POST_WORKOUT_REJECTED';
export const DELETE_WORKOUT_REQUESTED = 'yesfit/Workouts/DELETE_WORKOUT_REQUESTED';
export const DELETE_WORKOUT_RESOLVED = 'yesfit/Workouts/DELETE_WORKOUT_RESOLVED';
export const DELETE_WORKOUT_REJECTED = 'yesfit/Workouts/DELETE_WORKOUT_REJECTED';
export const HANDLE_FIELD_CHANGE = 'yesfit/Workouts/HANDLE_FIELD_CHANGE';
export const HANDLE_WORKOUT_FIELD_CHANGE = 'yesfit/Workouts/HANDLE_WORKOUT_FIELD_CHANGE';

const fetchWorkouts = endpoint => ({
    [CALL_API]: {
        types: [FETCH_WORKOUTS_REQUESTED, FETCH_WORKOUTS_RESOLVED, FETCH_WORKOUTS_REJECTED],
        endpoint: endpoint,
        method: 'GET'
    }
})

export const postWorkout = (body) => ({
    [CALL_API]: {
        types: [POST_WORKOUT_REQUESTED, POST_WORKOUT_RESOLVED, POST_WORKOUT_REJECTED],
        endpoint: WORKOUTS_API,
        method: 'POST',
        body: body
    }
})

const deleteWorkoutApi = endpoint => ({
    [CALL_API]: {
        types: [DELETE_WORKOUT_REQUESTED, DELETE_WORKOUT_RESOLVED, DELETE_WORKOUT_REJECTED],
        endpoint: endpoint,
        method: 'DELETE'
    }
})

export const loadWorkouts = (challengeAthleteId) => dispatch => {
    return dispatch(fetchWorkouts(WORKOUTS_API + '/' + challengeAthleteId));
}

export const saveWorkout = (workout) => (dispatch, getState) => {
    if (workout.userId === null || typeof workout.userId === 'undefined')
        workout.userId = getState().user.profile.userId;
    const state = getState();
    const cam = state.challengeAthleteMap;
    const totalDistance = cam.data.markers.filter((marker) => marker.isWorkout).reduce((acc, marker) => acc + marker.totalDistance, 0);
    const challenge = challengeAthleteSelector(state);
    if (state.workouts.confirmationMessage === null && (totalDistance + (workout.totalDistance*1)) >= challenge.totalDistance) {
        return dispatch(handleFieldChange('confirmationMessage', 'The workout you are entering is enough to complete the race, are you sure you want to add it? Press Save again to confirm.'));
    }
    else if (state.workouts.confirmationMessage === null && workout.totalDistance >= 10) {
        return dispatch(handleFieldChange('confirmationMessage', 'The workout you are entering is more than 10 miles, are you sure you want to add it? Press Save again to confirm.'));
    }
    else {
        dispatch(handleFieldChange('confirmationMessage', null));
        return dispatch(postWorkout(workout));
    }
}

export const deleteWorkout = userCardioActivityID => dispatch => {
    return dispatch(deleteWorkoutApi(WORKOUTS_API + '/' + userCardioActivityID));
}

export function handleFieldChange(key, value) {
    return {
        type: HANDLE_FIELD_CHANGE,
		key: key,
		value: value
    };
}